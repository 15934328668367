import React, { Component } from 'react';

import Hero from './hero';
import VendorCards from './vendorCards';
import Carousel from './carousel';

import '../../../css/Home.css';
// import VideoContainer from '../../layout/VideoContainer';

export default class Home extends Component {
  componentDidMount() {
    document.title = 'AvKARE | Home';
  }

  render() {
    const style = {
      'padding': '15px', 'font-size': '1em'
    }
    return (
      <div className="page" id="home">
        <Hero />
        <div className='row'>
          <a href='https://signup.avkare.com/' className='btn btn-primary' style={style}>Become A Customer!</a>
        </div>
        <VendorCards />
        <Carousel />
      </div>
    );
  }
}
