import axios from 'axios';
import q from 'q';

export const PharmaCatServices = {
  catalogGet: function() {
    var baseUri = '/api/pharmacatalog';

    var deferred = q.defer();

    var req = {
      method: 'GET',
      url: `${baseUri}`
    };

    axios(req).then(
      function(response) {
        //good
        deferred.resolve(response);
      },
      function(response) {
        //bad
        deferred.reject('PharmaCat: Could not get catalog.');
      }
    );

    return deferred.promise;
  },

  detailsGet: function(did) {
    var baseUri = '/api/pharma/';

    var deferred = q.defer();

    var req = {
      method: 'GET',
      url: `${baseUri}${did}`
    };

    axios(req).then(
      function(response) {
        //good
        deferred.resolve(response);
      },
      function(response) {
        //bad
        deferred.reject('PharmaCat: Could not get pharma details.');
      }
    );

    return deferred.promise;
  }
};

export default PharmaCatServices;
