import SignaturePad from 'signature_pad';

export class NewCustomerFormMethods {
    _newCustomerWebForm;
    _reviewTab;
    _tabs;
    _prevSectionButton;
    _nextSectionButton;
    _addFieldButtons;
    _addSectionButtons;
    _addAccordionButtons;
    _checkbox_groups;
    _requireOnes;
    _sig_canvases;

    initialize = () => {
        this.assignCheckboxGroups(this.checkbox_groups);
        this.runAttachmentMethods();
        this.setUpSignatureCanvases();
    }

    get reviewTab() {
        this.reviewTab = document.querySelector('#newCustomerWebForm-Tabs-tab-7');
        return this._reviewTab;
    }
    set reviewTab(el) {this._reviewTab = el;}

    get newCustomerWebForm() {
        this.newCustomerWebForm = document.getElementById('newCustomerWebForm');
        return this._newCustomerWebForm;
    }
    set newCustomerWebForm(el) {this._newCustomerWebForm = el;}

    get tabs() {
        this.tabs = this.newCustomerWebForm.querySelectorAll('.nav-link');
        return this._tabs
    }
    set tabs(el) {this._tabs = el;}

    get prevSectionButton() {
        this.prevSectionButton = document.querySelector('#prevSectionButton');
        return this._prevSectionButton;
    }
    set prevSectionButton(el) {this._prevSectionButton = el;}

    get nextSectionButton() {
        this.nextSectionButton = document.querySelector('#nextSectionButton');
        return this._nextSectionButton;
    }
    set nextSectionButton(el) {this._nextSectionButton = el;}

    get addFieldButtons() {
        this.addFieldButtons = this.newCustomerWebForm.querySelectorAll('[data-rs-add-field]');
        return this._addFieldButtons;
    }
    set addFieldButtons(el) {this._addFieldButtons = el;}

    get addSectionButtons() {
        this.addSectionButtons = this.newCustomerWebForm.querySelectorAll('[data-rs-add-section]');
        return this._addSectionButtons;
    }
    set addSectionButtons(el) {this._addSectionButtons = el;}

    get addAccordionButtons() {
        this.addAccordionButtons = this.newCustomerWebForm.querySelectorAll('[data-rs-add-accordion]');
        return this._addAccordionButtons;
    }
    set addAccordionButtons(el) {this._addAccordionButtons = el;}

    get checkbox_groups() {
        this.checkbox_groups = this.newCustomerWebForm.querySelectorAll('[data-rs-checkboxgroup]');
        return this._checkbox_groups;
    }
    set checkbox_groups(el) {this._checkbox_groups = el;}

    get requireOnes() {
        this.requireOnes = this.newCustomerWebForm.querySelectorAll('[data-rs-require-one]');
        return this._requireOnes;
    }
    set requireOnes(el) {this._requireOnes = el;}

    get sig_canvases() {
        this.sig_canvases = document.getElementsByClassName("sig-canvas");
        return this._sig_canvases;
    }

    set sig_canvases(el) {this._sig_canvases = el;}

    setUpSignatureCanvases = () => {
        for(var i = 0; i < this.sig_canvases.length; i++) {
            var canvas = this.sig_canvases[i];
            
            var signaturePad = new SignaturePad(canvas);
            signaturePad.minWidth = 2;
            signaturePad.maxWidth = 4;
            signaturePad.penColor = "#222222";
            signaturePad.onEnd = function(e) {
                var hiddenInputName = e.target.getAttribute('data-rs-sig-input');
                var hiddenInput = document.getElementsByName(hiddenInputName);
                if (hiddenInput) {
                    hiddenInput.value = signaturePad.toDataURL();
                    
                }
            }

        };
    }

    /**
     * Get containers that have dependencies.
     * This is a method and not a getter/setter because of refreshes and field show/hide
     * @returns HTMLNodeList
     */
    getDependencies = () => {
        return document.querySelectorAll('[data-rs-dependency]');
    }

    getActiveTabButton = () => {
        return this.newCustomerWebForm.querySelector('.nav-link.active');
    }

    /**
     * Enable/disable input fields under checkboxes depending on the checked value of the checkbox
     * 
     */
    assignCheckboxGroups = (checkboxgroups) => {
        checkboxgroups.forEach(el => {
            // data-rs-checkboxgroup is on the parent div container of the checkbox and input fields
            let dataRSValue = el.getAttribute('data-rs-checkboxgroup');
            let checkbox = el.querySelector('#'+dataRSValue+'-checkbox');
            let input = el.querySelectorAll('.'+dataRSValue+'-inputField');
            
            input.forEach(i => {
                this.toggleDisable(i, !i.value && !checkbox.checked);
                checkbox.addEventListener('change', (e) => this.toggleDisable(i, !checkbox.checked));
            });
        });
    }

    checkRequireOnes = () => {
        this.requireOnes.forEach(item => {
            let eventType = '';
            switch (item.getAttribute('type')) {
                case 'checkbox':
                    eventType = 'click';
                    break;
                case 'text':
                    eventType = 'input';
                    break;
                default: eventType = 'input';
            }
            
            item.addEventListener(eventType, () => {
                let sameGroup = [];
                this.requireOnes.forEach(o => {
                    if (item.getAttribute('data-rs-require-one') === o.getAttribute('data-rs-require-one')) {
                        sameGroup.push(o);
                    }
                });
                if (item.value === '' || (eventType === 'click' && !item.checked)) {
                    sameGroup.forEach(o => o.setAttribute('required', true));
                } else {
                    sameGroup.forEach(o => o.removeAttribute('required'));
                }
                
            });
        });
    }

    runAttachmentMethods = () => {
        /**
         * Upon valdation, be sure that one of the fields is filled in.
         * One or Both are required
         */
        this.checkRequireOnes();

        /**
         * Initial hiding of dependent elements
         */
        this.hideElements(this.getDependencies());
        // this.addSectionButtons = this.newCustomerWebForm.querySelectorAll('[data-rs-add-section]');

        this.addSectionButtons.forEach(button => {
            button.addEventListener('click', this.addSection);
        });
        this.addAccordionButtons.forEach(button => button.onclick = this.addAccordionSection);
        this.addFieldButtons.forEach(button => {button.onclick = this.addField;});

        // this.reviewTab.onclick =this.reviewSubmit;

        let addShipButton = document.getElementById('goToShipToButton');
        addShipButton.onclick = () => {
            this.goToTab(1);
        };

        /**
         * Initial checks for saved form values, dependencies, and required fields
         */
        this.checkForSaveForm();
        this.checkRequiredIfs();

    }

    /**
     * Takes a list of HTML elements and adds the 'hidden' class to them and disables child inputs.
     * @param {HTMLNode} elements 
     */
    hideElements = (elements) => {
        elements.forEach(element => {
            element.classList.add('hidden');
            element.setAttribute('disabled', 'disabled');
            if (element.tagName === 'DIV') {
                let subInputs = element.querySelectorAll('input:enabled, select:enabled, textarea:enabled');
               this.hideElements(subInputs);
            }
        });
    }
    /**
     * Takes a list of HTML elements and removes the 'hidden' class from them
     * @param {HTMLNode} elements 
     */
    showElements = (elements) => {
        elements.forEach(element => {

            if (element.hasAttribute('data-rs-checkbox-input') && !element.value) {
                element.classList.remove('hidden');
            } else {
                element.classList.remove('hidden');
                element.removeAttribute('disabled');
            }

            if (element.tagName === 'DIV') {
                if (!element.disabled) {
                    let subinputs = element.querySelectorAll('div:not([disabled]) input, div:not([disabled]) select, div:not([disabled]) textarea');
                   this.showElements(subinputs);

                    if (element.hasAttribute('data-rs-checkboxgroup')) {
                        this.assignCheckboxGroups([element]);
                    }
                    
                    this.getOtherSelectInput().forEach(el => {
                        let userInput = document.getElementById(el.getAttribute('name')+'other');
                        if (userInput) {
                            if (el.value === 'Other') {
                                this.showElements([userInput]);
                            } else {
                                this.hideElements([userInput]);
                            }
                        }
                    });
                }
            }
        });
    }

    /**
     * Set opposite disabled mode on a given element
     * @param {HTMLElement} element 
     * @param {boolean} isDisabled 
     */
    toggleDisable = (element, isDisabled) => {
        element.disabled = isDisabled;
    }

    /**
     * Convenience method for adding an element after a specific element
     * @param {*} referenceNode 
     * @param {*} newNode 
     */
    insertAfter = (referenceNode, newNode) => {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }

    handlePrevNext = (e) => {
        // use the value (index #) of the 'data-rs-next-section to:
        let nextIndex = Number(e.target.getAttribute('data-rs-next-section'));

        // get the 'tab' in the value
        this.goToTab(nextIndex);
        this.setPrevNextButtons(nextIndex);
    }

    validateTab = (tabButton, tabPane, onlyTabIcon) => {
        
        let tabInvalid = [];

        let incompleteIcon = tabButton.querySelector('.tab-incomplete');
        let completeIcon = tabButton.querySelector('.tab-complete');
        if (!tabPane) {
            tabPane = this.newCustomerWebForm.querySelector(tabButton.getAttribute('aria-controls'));
        }
        
        if (tabPane) {
            let tabInputs = tabPane.querySelectorAll('input:enabled, select:enabled, textarea:enabled');
            tabInputs.forEach(el => {
                if (!el.validity.valid) {
                    tabInvalid.push(el);
                }
            });
            if (!onlyTabIcon) {
                this.newCustomerWebForm.classList.add('was-validated');
            }
    
            //show/hide tab icons
            if (tabInvalid.length) {
                this.showElements([incompleteIcon]);
                this.hideElements([completeIcon]);
            } else {
                this.showElements([completeIcon]);
                this.hideElements([incompleteIcon]);
            }
        }
        
        
    }

    setPrevNextButtons = (tabIndex) => {
        this.prevSectionButton.setAttribute('data-rs-next-section', Number(tabIndex) - 1);
        this.nextSectionButton.setAttribute('data-rs-next-section', Number(tabIndex) + 1);

        this.prevSectionButton.disabled = this.prevSectionButton.getAttribute('data-rs-next-section') < 0 ? true : false;
        this.nextSectionButton.disabled = this.nextSectionButton.getAttribute('data-rs-next-section') === (this.tabs.length) ? true : false;
    }

    /**
     * Given a tab id name or number (as an index), trigger a click on that tab.
     * A string tabID will be converted to an index number
     * @param {string|number} tabIndex 
     */
    goToTab = (tabIndex) => {
        if (typeof tabIndex === 'string') {
            let tab = document.getElementById(tabIndex);
            if (tab) {
                tab.click();
            }
        }

        if (typeof tabIndex === 'number' && this.tabs[tabIndex] !== undefined) {
            this.tabs[tabIndex].click();
        }
    }

    /**
     * Get and loop over all the fields that have the attribute "data-rs-required-if",
     * find the field that is needed and check its value. If it matches, removed the required
     * attribute from the field and the 'required' asterix from the label
     */
    checkRequiredIfs = () => {
        var requiredIfs = this.newCustomerWebForm.querySelectorAll('[data-rs-required-if]');
        
        requiredIfs.forEach(el => {
            
            let needed = JSON.parse(el.dataset.rsRequiredIf);
            
            let requirementMet = [];
            for (var [key, value] of Object.entries(needed)) {
                
                let found = this.newCustomerWebForm.querySelector('[id="'+key+'"]');
                if (found) {
                    let isMatch = false;
                    let numberNeeded = Number(value);
                    let numberActual = Number(found.value);

                    if (found.getAttribute('type') === 'checkbox' ) {
                        isMatch = (!!value && found.checked) || (false === value && !found.checked);
                    } else if (numberNeeded && numberActual) {
                        if (numberActual < numberNeeded) {
                            isMatch = true;
                        } else {
                            isMatch = false;
                        }
                        
                    } else {
                        isMatch = found.value === value;
                    }
                    
                    if (isMatch) {
                        requirementMet.push(isMatch);
                    }
                }
            }

            let labelFor, reqSpan;
            // adjust for address group
            if (el.classList.contains('subfield')) {
                let newId = el.id.substring(0, el.id.lastIndexOf('['));
                labelFor = this.newCustomerWebForm.querySelector('[for="'+newId+'"]');
            } else {
                labelFor = this.newCustomerWebForm.querySelector('[for="'+el.id+'"]');
            }
            if (labelFor) {
                reqSpan = labelFor.querySelector('.required-ui');
            }
            
            if (requirementMet.length === Object.keys(needed).length) {
                el.required = false;
                if (reqSpan) {
                    reqSpan.classList.add('hidden');
                }
            } else {
                // address 2 is never required
                if (el.classList.contains('init-required')) {
                    el.required = true;
                    if (reqSpan) {
                        reqSpan.classList.remove('hidden');
                    }
                }
                
            }
        }); 
    }

    /**
     * Checks to see if there are anythis.tabs, sections, subsections, or fields that are dependent on
     * this target value
     * @param {InputEvent} e
     * @return void
     */
    checkDependencies = (e, customTarget) => {
        let thisTarget = customTarget || e.target;
        let fieldValue = thisTarget.value;
        // for bootstrap toggle inputs
        
        if (thisTarget.type === 'checkbox') {
            fieldValue =  thisTarget.checked ? 'on' : 'off';
        }
        
        let fieldName = thisTarget.name.substring(thisTarget.name.lastIndexOf('[') + 1, thisTarget.name.lastIndexOf(']'));
        
        let elementsToHide = [];

        // data-rs-dependencies-met is an array of the data-rs-dependency's that have been satisified
        // all of the dependecies must be satisfied before the field will show
        this.getDependencies().forEach(container => {
            container.dataset.rsDependenciesMet = [];
            let elementsToShow = [];
            let dependency = JSON.parse(container.dataset.rsDependency);
            for (const [key, value] of Object.entries(dependency)) {
                if (key === fieldName) {
                    let isNumber = Number(value);
                    let fieldIsNumber = Number(fieldValue);
                    if ((isNumber && fieldIsNumber) && fieldIsNumber >= isNumber) {
                        elementsToShow.push(container);
                    } else if(value === fieldValue) {
                        elementsToShow.push(container);
                    } else {
                        elementsToHide.push(container);
                    }
                    }
            }

            this.showElements(elementsToShow);
        
        });
        this.hideElements(elementsToHide);
    }

    /**
     * Find and return all dropdowns that are classed as having an 'Other' selector.
     * @returns HTMLNodeList
     */
    getOtherSelectInput = () => {
        return document.querySelectorAll('.other-select-input');
    }

    /**
     * If the value of a select (marked as selectUseInput) is 'Other', show the hidden 'other' input
     * @param {InputEvent} e 
     */
    selectUseInput = (e) => {
        let userInput = document.getElementById(e.target.getAttribute('name')+'other');
        if (userInput) {
            if (e.target.value === 'Other') {
                this.showElements([userInput]);
            } else {
                this.hideElements([userInput]);
            }
        }
    }

    /**
     * Given a iterable 'thing', find an object that contains a key=>value pair that matches. This method does
     * a deep search, going as deep into the interable as possible.
     * @param {*} iter 
     * @param {*} propertyToMatch 
     * @param {*} valueToFind 
     * @returns 
     */
    findObject = (iter, propertyToMatch, valueToFind) => {
        
        if (Array.isArray(iter)) {
            // this can be the master array, fields, sections, or subsections
            for (const subObject of iter) {
                if (subObject.hasOwnProperty(propertyToMatch) && subObject[propertyToMatch] === valueToFind) {
                    return subObject;
                } else {
                    // do it again with an object instead of an array
                    let found = this.findObject(subObject, propertyToMatch, valueToFind);
                    if (found) {
                        return found;
                    }
                }
            }
        } else {
            if (iter.hasOwnProperty(propertyToMatch) && iter[propertyToMatch] === valueToFind) {
                return iter;
            } else {
                for (const key in iter) {
                    if (iter.hasOwnProperty(key) && ['fields', 'sections', 'subsections'].includes(key) && iter[key].length) {    
                        let found = this.findObject(iter[key], propertyToMatch, valueToFind);
                        if (found) {
                            return found;
                        }
                    }
                }
            }
        }
    }

    /**
     * Populate Review tab Content with values entered
     */
    reviewSubmit = (e) => {
        return new Promise((resolve, reject) => {
            let formdata = new FormData(this.newCustomerWebForm);

            // clear checkbox, radio fields that are for toggling purposes
            // has_other_orgs, toggle_first
            formdata.delete('has_other_orgs');
            formdata.delete('toggle_first');
            
            // json structure & validate requirements
            // uat api = './avkarewebsite/reviewSubmit'
            // prop api = 'reviewSubmit'
            this.postData('./avkarewebsite/reviewSubmit', formdata)
                    .then((result) => {
                        resolve(result);
                    })
                    .catch(e => {reject(e); console.error(e)});
        });
        
    }

    /**
     * Convenience method for POSTing form data and parsing result.
     */
    postData = async (url = '', form) => {
        let response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            referrerPolicy: 'no-referrer',
            body: form 
        });
        return response.json();
    };

    /**
     * Add a field to an array of fields that have been marked as 'multiple'
     *
     * @return void
     */
    addField = (e) => {
        var fieldGroupName = e.currentTarget.getAttribute('data-rs-add-field');
        var nodeLength = document.querySelectorAll('[data-rs-field-group="'+fieldGroupName+'"]').length;
        var newNode = document.getElementById(fieldGroupName + '-' + (nodeLength - 1)).cloneNode(true);
        newNode.id = newNode.id.substring(0, newNode.id.lastIndexOf('-')) + '-' + nodeLength;

        var dupButton = newNode.querySelector('button.dup-button');
        dupButton.textContent = '-';
        
        dupButton.onclick = this.removeField;
        
        this.insertAfter(e.target.parentNode, newNode);
    }

    /**
     * Duplicate a bootstrap accordion item
     * @param {Event} e 
     * @returns 
     */
    addAccordionSection =(e) => {
        var fieldGroupName = e.currentTarget.getAttribute('data-rs-add-accordion');
        var allGroups = this.newCustomerWebForm.querySelectorAll('[data-rs-accordion-group="'+fieldGroupName+'"]');
        var nodeLength = allGroups.length;
        if (nodeLength < 1) {
            console.error('Could not find accordion group:', fieldGroupName);
            return;
        }
        var oldNode = allGroups[allGroups.length - 1]; // get last node
        var newNode = oldNode.cloneNode(true);
        newNode.id = fieldGroupName + '-' + nodeLength; // actual count, we already had a '0'

        let oldRemoveBtn = oldNode.querySelector('button[data-rs-remove-accordion]');
        oldRemoveBtn.classList.remove('hidden');
        oldRemoveBtn.onclick =this.removeAccordion;

        let oldAddBtn = oldNode.querySelector('button[data-rs-add-accordion]');
        oldAddBtn.classList.remove('hidden');

        // set bootstrap accordion collapse id's
        let newAccordionID = 'collapse-'+newNode.id;
        let accordionBtn = newNode.querySelector('.accordion-button');
        accordionBtn.setAttribute('data-bs-target', '#'+newAccordionID);
        accordionBtn.querySelector('.section-count').textContent = '('+(nodeLength + 1)+')'; // indexes start at zero, so the 2nd group will be '1'
        let accordionContent = newNode.querySelector('.accordion-collapse');
        accordionContent.id = newAccordionID;
        
        var addButton = newNode.querySelector('button[data-rs-add-accordion]');
        addButton.classList.remove('hidden');
        addButton.setAttribute('data-rs-add-accordion', fieldGroupName);
        addButton.onclick = this.addAccordionSection;

        var remButton = newNode.querySelector('button[data-rs-remove-accordion]');
        remButton.classList.remove('hidden');
        remButton.setAttribute('data-rs-remove-accordion', newNode.id);
        remButton.onclick =this.removeAccordion;

        // update multiple section/field keys\
        var newInputs = newNode.querySelectorAll('input, select, textarea');
        newInputs.forEach(el => {
            let currentID = el.id;
            let currentName = el.getAttribute('name');
            let dataRs = el.dataset;

            let multiMatchID = [...currentID.matchAll(/\[(\d)\]/g)];
            if (multiMatchID.length) {
                
                let currentIndex = multiMatchID[0][1];
                let newIndex = Number(currentIndex) +1;
                let newId = currentID.replaceAll('['+currentIndex+']', '['+newIndex+']');
                el.setAttribute('id', newId);
                let label = newNode.querySelector('[for="'+currentID+'"]');
                if (label) {
                    label.setAttribute('for', newId);
                }
            }

            let multiMatchName = [...currentName.matchAll(/\[(\d)\]/g)];
            if (multiMatchName.length) {
                let currentIndex = multiMatchName[0][1];
                let newIndex = Number(currentIndex) + 1;
                let newName = currentName.replaceAll('['+currentIndex+']', '['+newIndex+']');
                el.setAttribute('name', newName);
            }
            // check data-rs attributes
            for (var [key, value] of Object.entries(dataRs)) {
                let dataRSJSON = [...value.matchAll(/\[(\d)\]/g)];
                if (dataRSJSON.length) {
                    let currentIndex = dataRSJSON[0][1];
                    let newIndex = Number(currentIndex) + 1;
                    let newName = value.replaceAll('['+currentIndex+']', '['+newIndex+']');
                    el.dataset[key] = newName;
                }
            }

            // reset value
            let inputType = el.getAttribute('type');
            if (inputType === 'checkbox' || inputType === 'radio') {
                el.checked = false;
            } else {
                el.value = '';
            }

            el.addEventListener('change', (e) => {
                this.checkDependencies(e);
                this.checkRequiredIfs();
                this.checkRequireOnes();
                
                let closestTabContent = e.target.closest('.tab-pane');
                if (closestTabContent) {
                    let closestTabBtn = this.newCustomerWebForm.querySelector('#'+closestTabContent.getAttribute('aria-labelledby'));
                    this.validateTab(closestTabBtn, closestTabContent, true);
                }
            });
            
        });

       this.insertAfter(oldNode, newNode);
       this.checkRequireOnes();
       this.checkRequiredIfs();
    }

    /**
     * Add an entire section to a Tab (accordion item) or a subsection contained in an accordion item
     */
    addSection = (e) => {
        var fieldGroupName = e.currentTarget.getAttribute('data-rs-add-section');
        var nodeLength = document.querySelectorAll('[data-rs-section-group="'+fieldGroupName+'"]').length;
        var oldNode = document.getElementById(fieldGroupName + '-0');
        var newNode = oldNode.cloneNode(true);
        newNode.id = newNode.id.substring(0, newNode.id.lastIndexOf('-')) + '-' + nodeLength;
        
        var dupButton = newNode.querySelector('button[data-rs-add-section]');
        dupButton.remove();
        var remButton = newNode.querySelector('button[data-rs-remove-section]');
        remButton.style.display = 'block';
        remButton.setAttribute('data-rs-remove-section', newNode.id);
        remButton.onclick = this.removeSection;

        var newInputs = newNode.querySelectorAll('input, select, textarea');
        newInputs.forEach(el => {
            let currentID = el.id;
            let currentName = el.getAttribute('name');
            let dataRs = el.dataset;

            // if this doesn't exist, the multipleSectionKey or multipleSubsectionKey did not get set or passed down
            let multiMatchID = [...currentID.matchAll(/\[(\d)\]/g)];
            
            if (multiMatchID.length) {
                
                let currentIndex = multiMatchID[0][1];
                let newIndex = Number(currentIndex) +1;
                let newId = currentID.replaceAll('['+currentIndex+']', '['+newIndex+']');
                el.setAttribute('id', newId);
                let label = newNode.querySelector('[for="'+currentID+'"]');
                if (label) {
                    label.setAttribute('for', newId);
                }
            }

            let multiMatchName = [...currentName.matchAll(/\[(\d)\]/g)];
            if (multiMatchName.length) {
                let currentIndex = multiMatchName[0][1];
                let newIndex = Number(currentIndex) + 1;
                let newName = currentName.replaceAll('['+currentIndex+']', '['+newIndex+']');
                el.setAttribute('name', newName);
            }
            // check data-rs attributes
            for (var [key, value] of Object.entries(dataRs)) {
                let dataRSJSON = [...value.matchAll(/\[(\d)\]/g)];
                if (dataRSJSON.length) {
                    let currentIndex = dataRSJSON[0][1];
                    let newIndex = Number(currentIndex) + 1;
                    let newName = value.replaceAll('['+currentIndex+']', '['+newIndex+']');
                    el.dataset[key] = newName;
                }
            }

            // reset value
            let inputType = el.getAttribute('type');
            if (inputType === 'checkbox' || inputType === 'radio') {
                el.checked = false;
            } else {
                el.value = '';
            }
            
        });

       this.insertAfter(oldNode, newNode);
       this.checkRequiredIfs();
    }

    /**
     * Remove a single field container
     * @param {Event} e 
     */
    removeField = (e) => {
        var parentNode = this.newCustomerWebForm.querySelector('#'+e.target.getAttribute('data-rs-remove-field'));
        if (parentNode) {
            parentNode.remove();
        }
    }

    /**
     * Remove a block of fields
     * @param {Event} e 
     */
    removeSection = (e) => {
        // var parentNode = e.target.parentNode.parentNode;
        let toRemoveID = e.target.getAttribute('data-rs-remove-section');
        if (toRemoveID) {
            let parentNode = this.newCustomerWebForm.querySelector('#'+toRemoveID);
            if (parentNode) {
                parentNode.remove();
            }
        }
    }

    /**
     * Remove a target bootstrap accordion, re-assigning index values and such.
     * @param {Event} e 
     */
    removeAccordion = (e) => {
        let toRemoveID = e.target.getAttribute('data-rs-remove-accordion');
        if (toRemoveID) {
            let parentNode = this.newCustomerWebForm.querySelector('#'+toRemoveID);
            if (parentNode) {
                
                // reset count numbers
                var fieldGroupName = parentNode.getAttribute('data-rs-accordion-group');
                var oldGroups = this.newCustomerWebForm.querySelectorAll('[data-rs-accordion-group="'+fieldGroupName+'"]');
                
                if (oldGroups.length > 1) {
                    // just in case the Remove button somehow gets clicked on the last group
                    // it should be hidden, though
                    parentNode.remove();
                    var newGroups = this.newCustomerWebForm.querySelectorAll('[data-rs-accordion-group="'+fieldGroupName+'"]');

                    newGroups.forEach((g, i) => {
                        g.id = fieldGroupName + '-' + i;
                        // set bootstrap accordion collapse id's
                        let newAccordionID = 'collapse-'+g.id;
                        let accordionBtn = g.querySelector('.accordion-button');
                        accordionBtn.setAttribute('data-bs-target', '#'+newAccordionID);
                        accordionBtn.setAttribute('aria-controls', newAccordionID);
                        accordionBtn.querySelector('.section-count').textContent = '('+(i + 1)+')';
                        let accordionContent = g.querySelector('.accordion-collapse');
                        accordionContent.id = newAccordionID;

                        // set add/remove button id's
                        let addButton = accordionContent.querySelector('button[data-rs-add-accordion]');
                        addButton.setAttribute('data-rs-add-accordion', fieldGroupName);
                        addButton.onclick = this.addAccordionSection;
                        if (i < newGroups.length - 1) {
                            addButton.classList.remove('hidden');
                        }
                        let remButton = accordionContent.querySelector('button[data-rs-remove-accordion]');
                        remButton.setAttribute('data-rs-remove-accordion', g.id);
                        remButton.onclick =this.removeAccordion;
                        if (newGroups.length === 1) {
                            remButton.classList.add('hidden');
                        }
                        
                    });
                }
            } else {
                console.error('No accordion parent to remove:', parentNode.id);
            }
        } else {
            console.error('No ID found to remove parent accordion:', toRemoveID);
        }
    }

    /**
     * Save and Submit completed form
     * Checks if the form is valid, and if not, does not POST it.
     *
     * @return void
     */
    submitNewCustomer = (contactInfo, fieldNames) => {
        // Should have validated before we get here
        return new Promise((resolve, reject) => {
            let file_fields = this.newCustomerWebForm.querySelectorAll('input[type=file]');
            for (let el of file_fields) {
                if (!el.required && !el.value) {
                    el.disabled = true;
                }
            }

            let formdata = new FormData(this.newCustomerWebForm);
            
            formdata.append('submitName', contactInfo.get('submitName'));
            formdata.append('submitEmail', contactInfo.get('submitEmail'));
            formdata.append('submitPhone', contactInfo.get('submitPhone'));
            
            formdata.append('fields', JSON.stringify(fieldNames));
            
            var toastMessage = '';
            var toastTitle = '';

            // clear checkbox, radio fields that are for toggling purposes
            // has_other_orgs, toggle_first
            formdata.delete('has_other_orgs');
            formdata.delete('toggle_first');

            // uat api = './avkarewebsite/newcustomer'
            // prop api = 'newcustomer'
            this.postData('newcustomer', formdata)
                .then(result => {
                    if (result['error']) {
                        toastTitle = 'Error';
                        toastMessage = result['error'];
                        console.error(result['error']);
                    } else if (result['message']) {
                        toastTitle = 'Form Submission';
                        toastMessage = result['message'];
                    } else {
                        toastMessage = 'Form submission successful';
                    }
                    
                    resolve([toastTitle, toastMessage]);
                })
                .catch(e => {
                    toastMessage = 'There was a problem submitting your application';
                    console.error(e);
                    reject([toastTitle, toastMessage]);
                });
        });
       
    }
    

    /**
     * Show the toast message with given TITLE and MESSAGE
     * @param {string} title The title of the message
     * @param {string} message The actual message to display to the User
     */
    displayToast = (title = 'New Customer Form', message = '') => {
        var liveToastDiv = document.getElementById('liveToast');
        // var myToast = bootstrap.Toast.getOrCreateInstance(liveToastDiv);
        let toastTitle = liveToastDiv.querySelector('.toast-header-text');
        let toastContent = liveToastDiv.querySelector('.toast-body');
        toastTitle.textContent = title;
        toastContent.textContent = message ;
        // myToast.show();
    }

    /**
     * Append key:value from data into formData
     * @param {*} formData 
     * @param {*} data 
     * @param {*} parentKey 
     */
    buildFormData = (formData, data, parentKey) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
               this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data === null ? '': data;
            formData.append(parentKey, value);
        }

        return formData;
    }

    /**
     * Create a FormData object from a html form (optional) and a data Object
     * @param {Object} data 
     * @param {HTMLFormElement} form
     * @returns 
     */
    jsonToFormData = (data, form) => {
        let formData = new FormData();
        if (form) {
            formData = new FormData(form);
        }
        
        
        return this.buildFormData(formData, data);;
    }

    /**
     * Check storage for a previously saved form values and enable the 'restore' button
     */
    checkForSaveForm = () => {
        let hasSavedForm = Object.keys(window.localStorage).some(item => item.match(/^CustomerForm-/g));
        var restoreFormButton = document.querySelector('#restoreFormButton');
        if (hasSavedForm) {
            restoreFormButton.style.display = 'block';
        } else {
            restoreFormButton.style.display = 'none';
        }
    }

}

export default NewCustomerFormMethods;
