import React, { Component } from 'react';

import eeoImage from '../../assets/images/banners/eeo.jpg';
import careersImage from '../../assets/images/banners/callCenter.jpg';

import '../../css/Careers.css';

export default class Careers extends Component {
  componentDidMount() {
    document.title = 'AvKARE | Careers';
  }

  render() {
    return (
      <div className="page" id="careers">
        <div className="row">
          <div className="col-12 mt-2 p-5">
            <h1 className="text-center font-italic georgiaFont">
              Join the Trusted AvKARE Team!
            </h1>
          </div>
        </div>
        <div className="row colorBg" id="opps">
          <div className="col-12 col-md-6 p-0">
            <img className="w-100 h-100" src={careersImage} alt="Careers" />
          </div>
          <div className="col-12 col-md-6 text-white my-auto">
            <div className="box mx-auto">
              <p>
                <span className="h4 text-uppercase semiBold d-block">
                  Careers
                </span>
                Across the nation, AvKARE is a Valued Partner in the
                pharmaceutical and medical supply industry. We believe that
                people are the heart of our business. Choose a career with
                AvKARE and you will find yourself on the forefront of a growing
                organization with exciting opportunities.
                <a
                  href="https://secure6.saashr.com/ta/6198242.careers?CareersSearch&InFrameset=1&HostedBy=www.rsnortheast.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn jobs-apply-button text-uppercase"
                >
                  Apply Now
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="row" id="introduction">
          <div className="col-12">
            <p>
              <span className="h3 textRed semiBold d-block">Why AvKARE</span>
              We are committed to establishing a performance driven environment
              with employees dedicated to delivering optimal results. If you
              desire a rewarding and professional occupation, we encourage you
              to explore career opportunities with AvKARE!
            </p>
          </div>
        </div>
        <div className="row mb-5" id="info">
          <div className="col-md-6 d-flex align-items-stretch floatRight mb-3">
            <div className="card width" id="missionStatement">
              <div className="card-body">
                {/* <h5 className="card-title textRed bold">Mission Statement</h5> */}
                <p className="card-text">
                  {/* AvKARE supports the health and medical needs our valued
                  customers, including military, families of yesterday, today,
                  and tomorrow by providing the highest quality, competively
                  priced generic Pharmaceuticals, Disposable Medical Surgical
                  Supplies and Capital Medical Equipment available. */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-stretch mb-3">
            <div className="card width" id="benefits">
              <div className="card-body">
                <h5 className="card-title textRed bold">Benefits</h5>
                <p className="card-text">
                  AvKARE's commitment to its greatest assets, our employees,
                  encompasses more than just a salary - AvKARE team members
                  receive an offering of comprehensive and competitive benefits
                  including:
                </p>
                <ul className="bold">
                  <li>Medical</li>
                  <li>Life Insurance</li>
                  <li>401k</li>
                  <li>Vision</li>
                  <li>Disability Insurance</li>
                  <li>HSA/FSA Accounts</li>
                  <li>Dental</li>
                  <li>Paid Time Off (PTO)</li>
                  <li>And Much More...</li>
                </ul>
                <a
                  href="https://secure6.saashr.com/ta/6198242.careers?CareersSearch&InFrameset=1&HostedBy=www.rsnortheast.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn jobs-apply-button text-uppercase"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row colorBg" id="equalOpportunity">
          <div className="col-12 col-md-6 text-white my-auto">
            <div className="box mx-auto">
              <p>
                <span className="h4 text-uppercase semiBold d-block">
                  Equal Opportunity Employer
                </span>
                AvKARE is an E-Verify and Equal Opportunity Employer. All
                qualified applicants will receive considerations for employment
                without regard to race, color, religion, sex, age, disability,
                marital status, sexual orientation, genetic information, gender
                identity, national origin, citizenship status, disabled veteran
                or veteran of Vietnam era, or any legally recognized status
                entitled to protection under applicable federal and state local
                laws.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 p-0">
            <img
              className="w-100 h-100"
              src={eeoImage}
              alt="Equal Employment Opportunity"
            />
          </div>
        </div>
      </div>
    );
  }
}
