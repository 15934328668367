import React, { Component } from 'react';

// import formularyCatalog from '../../../assets/pdfs/formularyCatalog_07-26-18.pdf';

import AirForceLogo from '../../../assets/images/logos/AirForce.jpg';
import ArmyLogo from '../../../assets/images/logos/Army.jpg';
import ArmyNationalGuardLogo from '../../../assets/images/logos/ArmyNationalGuard.png';
import DepartmentOfDefenseLogo from '../../../assets/images/logos/DepartmentOfDefense.jpg';
import DepartmentOfHealthAndHumanServicesLogo from '../../../assets/images/logos/DepartmentOfHealthAndHumanServices.png';
import DepartmentOfVeteransAffairsLogo from '../../../assets/images/logos/DepartmentOfVeteransAffairs2.jpg';
import FederalBureauOfPrisonsLogo from '../../../assets/images/logos/FederalBureauOfPrisons.jpg';
import HomelandSecurityLogo from '../../../assets/images/logos/HomelandSecurity.jpg';
import IndianHealthLogo from '../../../assets/images/logos/IndianHealth.jpg';
import MarineCorpsLogo from '../../../assets/images/logos/MarineCorps.jpg';
import NavyLogo from '../../../assets/images/logos/Navy.jpg';
import NIHLogo from '../../../assets/images/logos/NIH2.jpg';

export default class CustomersGovernment extends Component {
  render() {
    return (
      <div className="row" id="government">
        <div className="col-12 bg padding text-white text-center my-auto">
          <div className="w-75 mx-auto">
            <h2 className="georgiaFont font-italic sectionTitle">Government</h2>
            <p className="lead">
              AvKARE is honored to be a trusted supplier for these United States Governmental
              entities and have an expansive portfolio of products available on a variety of
              contracts.
            </p>
          </div>
        </div>

        <div className="col-12 mt-4">
          <div className="sectionWidth mx-auto">
            <div className="row">
              <div className="col-12 col-xl-8">
                <div className="row">
                  <div className="col-6 col-md-3 my-auto">
                    <img
                      className="logo"
                      src={DepartmentOfDefenseLogo}
                      alt="Department of Defense"
                    />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img
                      className="logo"
                      src={FederalBureauOfPrisonsLogo}
                      alt="Federal Bureau of Prisons"
                    />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img className="logo" src={IndianHealthLogo} alt="Indian Health Services" />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img
                      className="logo"
                      src={DepartmentOfHealthAndHumanServicesLogo}
                      alt="Department of Health & Human Services"
                    />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img className="logo" src={ArmyLogo} alt="U.S. Army" />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img className="logo" src={NavyLogo} alt="U.S. Navy" />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img className="logo" src={MarineCorpsLogo} alt="U.S. Marine Corps" />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img className="logo" src={AirForceLogo} alt="U.S. Air Force" />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img className="logo" src={ArmyNationalGuardLogo} alt="National Guard" />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img
                      className="logo"
                      src={HomelandSecurityLogo}
                      alt="U.S. Department of Homeland Security"
                    />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img
                      className="logo"
                      src={DepartmentOfVeteransAffairsLogo}
                      alt="U.S. Department of Veteran Affairs"
                    />
                  </div>
                  <div className="col-6 col-md-3 my-auto">
                    <img className="logo" src={NIHLogo} alt="National Institutes of Health" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-4 bg-light mb-3 contracts">
                {/* <div className="text-center">
                  <a
                    href={formularyCatalog}
                    className="btn btn-primary mx-auto"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AvKARE Formulary Catalog
                  </a>
                </div> */}
                <div className="pt-3">
                  <strong>Federal Supply Schedule (FSS):</strong>
                  <ul>
                    <li>FSS Pharma-36F79723D0144</li>
                    {/* <li>FSS MED/SURG-36F79718D0350</li>
                    <li>FSS LAB-V797D-30052</li> */}
                  </ul>
                </div>
                <div>
                  <strong className="text-uppercase">Dapa:</strong>
                  <ul>
                    <li>PHARMA-SP0200-08-H-0016</li>
                    {/* <li>MED/SURG-SP0200-08-H-0007</li> */}
                  </ul>
                </div>
                {/* <div>
                  <strong className="text-uppercase">Ecat Capital Equipment:</strong>
                  <ul>
                    <li>SPM2DH-13-8219</li>
                  </ul>
                </div> */}
                {/* <div>
                  <strong className="text-uppercase">Ecat Lab:</strong>
                  <ul>
                    <li>SPE2DE-16-D0009</li>
                  </ul>
                </div> */}
                {/* <div>
                  <strong>
                    <a
                      href="https://www.gsaadvantage.gov/advantage/main/start_page.do"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      General Services Administration (GSA)
                    </a>:
                  </strong>
                  <ul>
                    <li>GSA MED/SURG 36F79718D0350</li>
                    <li>GSA LAB-V797D-30052</li>
                  </ul>
                </div> */}
                <div>
                  <strong>National Acquisition Center (NAC):</strong>
                  <ul>
                    <li>
                      <a
                        href="https://www.va.gov/nac/Pharma/List"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pharmaceutical
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.va.gov/nac/MedSurg/List"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Medical/Surgical
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <p className="text-center p-3">
              To support our governmental customers, AvKARE's innovative and professional Sales
              Representatives provide services in territories across the United States. Contact
              AvKARE today to connect with a sales representative in your area.
            </p>
            <div className="text-center">
              <a className="btn btn-primary mb-5 mx-auto" href="/contact">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
