import React, { Component } from 'react';

export default class Thanks extends Component {
  render() {
    return (
      <div className="p5 d-flex" id="#thanks">
        <div className="m-auto">
          <span className="h2 d-block text-center">Thank you for contacting us!</span>
          <span className="h5 d-block text-center">
            An AvKARE representative will be in contact with you very soon.
          </span>
        </div>
      </div>
    );
  }
}
