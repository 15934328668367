import React, { Component } from 'react';
import '../../../css/Vendors.css';

import Government from './government.js';
import Commercial from './commercial.js';
// import Partners from './partners.js';

export default class Vendors extends Component {
  render() {
    return (
      <div className="page" id="vendors">
        <Government />
        <Commercial />
        {/* <Partners /> */}
      </div>
    );
  }
}
