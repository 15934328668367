import React, { Component } from 'react';

import BadgeIcon from '../../../assets/images/icons/medal.svg';
import ClockIcon from '../../../assets/images/icons/clock.svg';
import EquipmentIcon from '../../../assets/images/icons/suitcase.svg';

export default class VendorsCommercial extends Component {
  render() {
    return (
      <div className="row bg-light padding" id="commercial">
        <div className="col-12 my-auto">
          <div className="sectionWidth mx-auto">
            <h2 className="text-center georgiaFont font-italic sectionTitle">Commercial</h2>
            <p className="text-center lead p-3">
              AvKARE provides the most sought-after products to retail chains, independent
              establishments, hospitals, health systems and networks, long-term care agencies, &
              educational institutions. Whether you are a large or small commercial business; AvKARE
              values our commercial customers by committing to provide the most efficient and
              effective products.
            </p>
            <div className="row text-center textRed p-5">
              <div className="col-12 col-md-4 mb-3">
                <img className="icon" src={ClockIcon} alt="" />
                <strong className="d-block text-uppercase w-75 mx-auto">
                  On Time Product Delivery
                </strong>
              </div>
              <div className="col-12 col-md-4 mb-3">
                <img className="icon" src={BadgeIcon} alt="" />
                <strong className="d-block text-uppercase w-75 mx-auto">
                  Superior Customer Service
                </strong>
              </div>
              <div className="col-12 col-md-4">
                <img className="icon" src={EquipmentIcon} alt="" />
                <strong className="d-block text-uppercase w-75 mx-auto">
                  High Quality Products and Equipment
                </strong>
              </div>
            </div>
            <p className="text-center p-3">
              Let AvKARE source your products through our open market channels! Contact your AvKARE
              sales representative regarding commercial sourcing for your pharmaceutical, laboratory
              supply, and medical supply and equipment needs.
            </p>
            <div className="text-center">
              <a className="btn btn-primary mb-5 mx-auto" href="/contact">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
