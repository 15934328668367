/**
 * Class ValueTypes
 * Enumeraction class for the types of values a User Input interaction type will provide
 * @package NewCustomerForm
 */
export class ValueTypes {
    STR = 'string';
    DATE = 'date';
    BOOL = 'bool';
    EMAIL = 'email';
    FILE = 'file';
    OBJECT = 'object';
};

/**
 * Class UI_TYPES
 * Enumeration class for constant values of UI User Input interaction types
 * @package NewCustomerForm
 */
export const UI_TYPES = {
    // NON Input Types
    PARAGRAPH : 'p',
    SPAN : 'span',
    H1 : 'h1',
    H2 : 'h2',
    H3 : 'h3',
    H4 : 'h4',
    H5 : 'h5',
    H6 : 'h6',
    READ_ONLY : 'label',
    SEPARATOR : '<hr/>',
    ORDERED_LIST : 'ol',
    UNORDERED_LIST : 'ul',

    // Basic input types
    // <input type:"text" />
    TEXT : 'text',
    // <input type:"datetime" /> OR Bootstrap DatePicker
    DATE : 'date',
    // Bootstrap Number field
    NUMBER : 'number',
    // Bootstrap currency formatted number field
    CURRENCY : 'currency',

    EMAIL : 'email',

    PHONE : 'tel',

    TEXTAREA : 'textarea',

    // Bootstrap SELECT
    DROPDOWN : 'select',
    COMBOBOX : 'combobox',
    RADIO : 'radio',
    RADIO_STACKED: 'radiostacked',
    CHECKBOX : 'checkbox',
    CHECKBOX_GROUP : 'checkbox-group',
    CHECKBOX_STACKED : 'stacked-checkboxes',
    CHECKBOX_GROUP_STACKED :'grouped-stack-checkoxes',
    CHECKBOX_GROUP_INLINE : 'checkbox-group-inline',
    CHECKBOX_INLINE : 'inline-checkbox',
    TOGGLE : 'slider',
    
    FILE : 'file',

    SIGNATURE_CANVAS: 'canvas',
    SIGNATURE1: 'signature1',
    SIGNATURE2: 'signature2',

    // Complex UI types
    // these generate a group of fields or a predefined field

    /**
     * Generates a group of Contact input fields
     * name, tel, email
     */
    FULL_CONTACT : 'contact',

    /**
     * Generates a group of Contact input fields
     * name, tel
     */
    MIN_CONTACT : 'min_contact',

    /**
     * Generates a group of input fields
     * address1, address2, city, state, postal
     */
    ADDRESS : 'address',
    ADDRESS_1_LINE : 'address_group',
    /**
     * Generates a button that will add a duplicate field for which it was clicked
     */
    ADD_BUTTON : 'add_button',

    HIDDEN : 'hidden'
};

/**
 * class tab
 * @package tab
 */
 export class tab {
    /**
     * @var string $name
     */
    name;
    /**
     * displayName
     *
     * @var string displayName
     */
    displayName;
    /**
     * List of visually separated areas of User input with a Heading
     *
     * @var array sections
     */
    sections;
    /**
     * List of values that need to be true for this tab to appear
     *
     * @var array dependency
     */
    dependency = {};

    help;

    /**
     * Consruct function for new tab objects
     *
     * @param string $name
     * @param string $displayName
     * @param array $sections
     * @param object $dependency
     */
     constructor(options = {})
    {
        this.name = options['name'] ? options['name'] : new Error('Tab name must be provided');
        this.displayName = options['displayName'] && options['displayName'].length ? options['displayName'] : new Error('Display Name is Required');
        this.sections = options['sections'] ? options['sections'] : [];
        this.dependency = options['dependency'] ? options['dependency'] : {};
        this.help = options['help'] ? options['help']: '';
    }
};

/**
 * Class tabSection
 * 
 * A visually separated area of input fields
 */
export class tabSection {
    /**
     * code name of the section
     *
     * @var string $name
     */
    name;

    /**
     * User visible name of the section
     *
     * @var string $displayName
     */
    displayName;

    /**
     * List of values that are required for this section to show up. 
     * If empty, show it.
     *
     * @var array
     */
    dependency = {};

    /**
     * Help text that appears on user selection of help icon.
     * If no value is present the icon should not appear
     *
     * @var string $help
     */
    help;
    /**
     * Whether the section is collapsed or not
     *
     * @var boolean $collapsed
     */
    collapsed = false;
    
    /**
     * List of User inputs available for this section
     *
     * @var array $fields
     */
    fields = [];

    /**
     * List of visually separated field groups
     *
     * @var array $subsections
     */
    subsections = [];

    /**
     * Whether a group of input fields can be duplicated via an 'Add' button
     *
     * @var boolean
     */
    multiple = false;

    hideFirst = false;

    sectionStyle;

    constructor(options = {})
    {
        this.name = options['name'] && options['name'].length > 0 ? options['name'] : new Error(`Tab Section Name is required`);
        this.displayName = options['displayName'] && options['displayName'].length > 0 ? options['displayName'] : new Error(`Tab Section Name is required`);
        
        if (!options['fields'] || options['fields'].length < 1 ) {
            new Error(`A list of section fields is required`);
        } else {
            this.fields = options['fields'];
        }

        this.help = options['help'] ? options['help']: '';
        this.collapsed = options['collapsed'] ? options['collapsed'] : false;
        
        this.subsections = options['subsections'] ? options['subsections'] : [];
        this.multiple = options['multiple'] ? options['multiple'] : false;
        this.hideFirst = options['hideFirst'] ? options['hideFirst'] : false;
        this.dependency = options['dependency'] ? options['dependency'] : {};

        this.sectionStyle = options['sectionStyle'] ? options['sectionStyle'] : new SectionStyle();
    }
};

export class fieldGroup {
    group = [];

    constructor(group = [])
    {
        this.group = group;
    }
}

/**
 * UI input field model
 * 
 * @package NewCustomerForm
 */
export class inputField{
    /**
     * The field in the database this value is assigned to
     *
     * @var string $field
     */
    field = '';

    /**
     * The field in the database this value is assigned to.
     * Used for COMBOBOX and CHECKBOX_GROUP and RADIO_GROUP
     *
     * @var string $field
     */
    extraField = '';

    /**
     * UI value to show the user. LIkely different than the $field
     *
     * @var string $displayName
     */
    displayName = '';

    /**
     * Boolean value for whether this field requires a value to be saved.
     * Default value is true.
     *
     * @var boolean $required
     */
    required = true;

    /**
     * This field is 'required if' a field:value pair is true
     *
     * @var array
     */
    requiredIf = null;

    requireOne = null;

    /**
     * UI input type
     * Default value is TEXT
     * @var string $inputType
     */
    inputType = UI_TYPES.TEXT;

    /**
     * List of values this field requires to show up
     * Default value is an empty array.
     *
     * @var object $dependency
     */
    dependency = {};

    /**
     * Options to provide for the User to select from.
     * Default value is an empty array.
     *
     * @var array options
     */
    options = {};

    /**
     * A default value for the field
     * Default value is 'null'
     *
     * @var [type]
     */
    defaultValue = null;

    /**
     * Minimum value this input field may have, otherwise invalid.
     * Applies to date and number fields
     *
     * @var mixed
     */
    min = null;
    /**
     * Maximum value this input field may have, otherwise invalid.
     * Applies to DATE and NUMBER fields
     *
     * @var mixed
     */
    max = null;

    /**
     * Whether this field is duplicatable via an 'ADD' button.
     *
     * @var boolean $multiple
     */
    multiple = false;

    placeholder = '';

    subsections = [];

    /**
     * Method to run when the field is filled out to make sure the value is valid
     * Return true or false
     *
     * @return bool
     */
    validation = '';

    help = '';

    icon = '';

    fieldStyle = null;

    constructor(options = {})
    {
        // Absolutely Required fields
        this.field = options['field'] && options['field'].length ? options['field'] : new Error(`Field name is required`);
        this.displayName = options['displayName'] ? options['displayName'] : '';
        this.inputType = options['inputType'] ? options['inputType'] : UI_TYPES.TEXT;
        this.subsections = options['subsections'] ? options['subsections'] : [];

        // Boolean fields
        this.required = Object.keys(options).includes('required') ? options['required']: true;
        this.multiple = Object.keys(options).includes('multiple') ? options['multiple']: false;
        this.requiredIf = Object.keys(options).includes('requiredIf') ? options['requiredIf']: null;

        // Nullable string fields
        this.defaultValue = Object.keys(options).includes('defaultValue') ? options['defaultValue'] : '';
        this.help = options['help'] ? options['help']: '';
        this.icon = options['icon']? options['icon'] : '';
        this.placeholder = options['placeholder'] ? options['placeholder'] : (options['displayName'] ? options.displayName: this.displayName);
        
        this.min = Object.keys(options).includes('min') ? options['min']: null;
        this.max = Object.keys(options).includes('max') ? options['max']: null;
        this.dependency = Object.keys(options).includes('dependency') ? options['dependency'] : {};
        this.requireOne = Object.keys(options).includes('requireOne') ? options['requireOne'] : null;
        
        this.options = options['options'] ? options['options'] : {};

        this.validation = Object.keys(options).includes('validation') ? options['validation'] : false;

        this.extraField = options['extraField'] ? options['extraField']: '';

        this.fieldStyle = options['fieldStyle'] ? options['fieldStyle'] : new FieldStyle();
    }
};

/**
 * Read Only fields.
 */
export class outputField{
    field ='';
    inputType = UI_TYPES.READ_ONLY;
    label = '';
    value = '';
    subsections = [];
    multiple = false;
    dependency = {};
    constructor(options = {})
    {
        this.field = options ? options['field'] : '';
        this.label = options['label'] ? options['label'] : '';
        this.value = options['value'] ? options['value'] : null;
        this.multiple = !options['nultiple'] ? false : options['nultiple'];
        this.inputType = !options['inputType'] ? UI_TYPES.READ_ONLY: options['inputType'];
        this.fieldStyle = options['fieldStyle'] ? options['fieldStyle'] : new FieldStyle();
        this.subsections = options['subsections'] ? options['subsections'] : [];
        this.dependency = options['dependency'] ? options['dependency'] : {};
    }
}

/**
 * Defines Bootstap style values for a Section
 */
export class SectionStyle {
    gutter = 3;

    constructor(gutter = 3)
    {
        this.gutter = 'g-' + gutter;
    }

    rowStyle = () => {
        return 'row '+ this.gutter;
    }

}

/**
 * Defines the styles of the fields using Bootstrap 5 
 * row and column values.
 * Injected to the class value as 'col-%breakpoint%-%width%' where
 * 
 */
export class FieldStyle {
    width = 6; // # of 12 divisions
    breakpoint = 'md';
    extra = '';

    constructor(options = {})
    {
        this.width = !options['width'] ? 6 : options['width'];
        this.breakpoint = !options['breakpoint'] ? 'md' : options['breakpoint'];
        this.extra = options['extra'] ? options['extra']: '';
    }

    colStyle = () => {
        return 'col-'+ this.breakpoint + '-' + this.width + ' ' + this.extra;
    }
};

/**
 * Convenience array for the basic html 'input' types
 */
export const simpleInputTypes = [UI_TYPES.TEXT, UI_TYPES.EMAIL, UI_TYPES.PHONE, UI_TYPES.NUMBER, UI_TYPES.DATE, UI_TYPES.SIGNATURE1, UI_TYPES.SIGNATURE2];
export const simpleOutputTypes = [UI_TYPES.ORDERED_LIST,UI_TYPES.PARAGRAPH, UI_TYPES.SEPARATOR, UI_TYPES.H1, UI_TYPES.H2, UI_TYPES.H3, UI_TYPES.H4, UI_TYPES.H5, UI_TYPES.H6, UI_TYPES.READ_ONLY];
export const iconFields = [UI_TYPES.EMAIL, UI_TYPES.PHONE];

export const stateList = {
    'Alabama' : 'Alabama','Alaska' : 'Alaska','American Samoa' : 'American Samoa','Arizona' : 'Arizona','Arkansas' : 'Arkansas',
    'California' : 'California','Colorado' : 'Colorado','Connecticut' : 'Connecticut','Delaware' : 'Delaware','District of Columbia' : 'District of Columbia',
    'Federated States of Micronesia' : 'Federated States of Micronesia','Florida' : 'Florida','Georgia' : 'Georgia','Guam' : 'Guam','Hawaii' : 'Hawaii',
    'Idaho' : 'Idaho','Illinois' : 'Illinois','Indiana' : 'Indiana','Iowa' : 'Iowa','Kansas' : 'Kansas','Kentucky' : 'Kentucky','Louisiana' : 'Louisiana',
    'Maine' : 'Maine','Marshall Islands' : 'Marshall Islands','Maryland' : 'Maryland','Massachusetts' : 'Massachusetts','Michigan' : 'Michigan','Minnesota' : 'Minnesota',
    'Mississippi' : 'Mississippi','Missouri' : 'Missouri','Montana': 'Montana','Nebraska':'Nebraska','Nevada':'Nevada','New Hampshire':'New Hampshire',
    'New Jersey':'New Jersey','New Mexico':'New Mexico','New York':'New York','North Carolina':'North Carolina','North Dakota':'North Dakota',
    'Northern Mariana Islands':'Northern Mariana Islands','Ohio':'Ohio','Oklahoma':'Oklahoma','Oregon':'Oregon','Palau':'Palau','Pennsylvania':'Pennsylvania',
    'Puerto Rico':'Puerto Rico','Rhode Island':'Rhode Island','South Carolina':'South Carolina','South Dakota':'South Dakota','Tennessee':'Tennessee','Texas':'Texas',
    'Utah':'Utah','Vermont':'Vermont','Virgin Island':'Virgin Island','Virginia':'Virginia','Washington':'Washington','West Virginia':'West Virginia',
    'Wisconsin':'Wisconsin','Wyoming':'Wyoming'};

// Field Groups
export const addressFields = [
    {'name':'address1', 'displayName' : 'Address', 'placeholder':'Street Address', 'inputType':UI_TYPES.TEXT, 'required':true, 'defaultValue':''}, 
    {'name':'address2', 'displayName' : 'Address 2', 'placeholder':'Address 2', 'inputType':UI_TYPES.TEXT, 'required':false, 'defaultValue':''}, 
    {'name':'city', 'displayName' : 'City', 'placeholder':'City', 'inputType':UI_TYPES.TEXT, 'required':true, 'defaultValue':''}, 
    {'name':'state', 'displayName' : 'State', 'placeholder':'State', 'inputType':UI_TYPES.DROPDOWN, 'options':stateList, 'required':true, 'defaultValue':''}, 
    {'name':'postal', 'displayName' : 'Postal Code', 'placeholder':'Postal Code', 'validation': '^[0-9]{5}(?:-[0-9]{4})?$', 'inputType':UI_TYPES.TEXT, 'required':true, 'defaultValue':''}
];

export const contactFields = [
    {'name':'person_name', 'displayName':'Name', 'placeholder':'Name', 'inputType':UI_TYPES.TEXT, 'required':true, 'defaultValue':''}, 
    {'name':'phone_num', 'displayName':'Phone #', 'placeholder':'Phone #', 'inputType':UI_TYPES.PHONE, 'validation':"[+0-9]?[(]?[0-9]{3}[)]?-?[0-9]{3}-?[0-9]{4}", 'required':true, 'defaultValue':''}, 
    {'name':'email_addr', 'displayName':'Email', 'placeholder':'Email Address', 'inputType':UI_TYPES.EMAIL, 'required':true, 'defaultValue':''}
];

export const minContactFields = [
    {'name':'person_name', 'displayName':'Name', 'placeholder':'Name', 'inputType':UI_TYPES.TEXT, 'required':true, 'defaultValue':''}, 
    {'name':'phone_num', 'displayName':'Phone #', 'placeholder':'Phone #', 'inputType':UI_TYPES.PHONE, 'validation':'[+0-9]?[(]?[0-9]{3}[)]?-?[0-9]{3}-?[0-9]{4}', 'required':true, 'defaultValue':''}
];

export const todaysDate = new Date('%F');

export const tabs = [
    // Business Info Tab
    new tab(
        {
        'name' : 'business_info',
        'displayName' : 'Business Information',
        'help' : 'Business Information',
        'sections' : [
            new tabSection(
                {'name': 'general_info',
                'displayName': 'General Info',
                'help': 'Enter your general information',
                'fields': [
                    new inputField(
                        {
                            'field': 'entity_name',
                            'displayName': 'Entity Name'
                        }
                    ),
                    new inputField(
                        {
                            'field': 'est_date',
                            'displayName': 'Date Business Established',
                            'inputType': UI_TYPES.DATE,
                            'max' : todaysDate
                        }
                    ),
                    new inputField(
                        {
                            'field': 'business_category',
                            'displayName': 'Business Category',
                            'inputType': UI_TYPES.COMBOBOX,
                            'options': {'Hospital':'Hospital',
                                'Clinic':'Clinic',
                                'Surgery-Center':'Surgery Center',
                                'Retail-Pharmacy':'Retail Pharmacy',
                                'Urgent-Care':'Urgent Care',
                                'LTC':'LTC',
                                'Vet-Clinic' : 'Vet Clinic',
                                'Vet-Hospital':'Vet Hospital',
                                'Other':'Other'}
                        }
                    ),
                    new inputField(
                        {
                            'field': 'number_of_beds',
                            'displayName': '# of Beds',
                            'dependency': {'business_category' : 'Hospital'},
                            'inputType': UI_TYPES.NUMBER,
                            'min': 1,
                            'max': 10000
                        }
                    ),
                    new inputField(
                        {'field': 'business_type',
                        'displayName': 'Type of Business',
                        'help': 'What is your type of Business?',
                        'inputType': UI_TYPES.DROPDOWN,
                        'options': {'Corporation':'Corporation', 'Contract-Pharmacy':'Contract Pharmacy', 'Non-Profit':'Non Profit', 'Partnership':'Partnership', 'Sole-Proprietor':'Sole Proprietor', 'LLC':'LLC'}}
                    ),
                    new inputField(
                        {'field': 'non_profit_file',
                        'displayName': 'Upload Non-Profit documents',
                        'inputType': UI_TYPES.FILE,
                        'help': 'Upload your tax-exemption forms',
                        'dependency': {'business_type': 'Non-Profit'}}
                    ),
                    new inputField(
                        {'field': 'online_portal',
                        'defaultValue': 'Yes',
                        'displayName': 'Do you want to be registered for the online shoppping portal?',
                        'inputType': UI_TYPES.TOGGLE,
                        'required': false}
                    ),
                    new inputField(
                        {'field': 'sales_rep',
                        'displayName': 'Sales Rep',
                        'help': 'Who is your Sales Representative with us?',
                        'required': false}
                    )
                ]}
            ),
            // Primary Addresses
            new tabSection(
                {'name' : 'addresses',
                'displayName' : 'Addresses',
                'help' : 'Enter your primary Business address and Shipping Address.',
                'fields' : [
                    new inputField(
                        {'field' : 'bill_to_address',
                        'displayName' : 'Entity Billing Address',
                        'inputType' : UI_TYPES.ADDRESS}
                    ),
                    new inputField(
                        {'field' : 'ship_to_address',
                        'displayName' : 'Entity Shipping Address',
                        'inputType' : UI_TYPES.ADDRESS}
                    ),
                    new inputField(
                        {'field' : 'tax_number',
                        'validation' : "[0-9]{9}|[0-9]{2}-[0-9]{7}",
                        'displayName' : 'Tax ID or FEIN'}
                    ),
                    new inputField(
                        {'field' : 'purch_contr_subst',
                        'displayName' : 'Purchasing Controlled Substances',
                        'inputType' : UI_TYPES.CHECKBOX_GROUP,
                        'help': 'Include a copy of your DEA License & request a DEA Questionnaire at customerservice@rsnortheast.com',
                        'placeholder' : 'Provide DEA #',
                        'extraField' : 'dea_num',
                        'validation' : '^[a-zA-Z]{2}[0-9]{6}[0-9]$',
                        'required' : false,
                        'subsections' : []}
                    ),
                    new inputField(
                        {
                            'field':'dea_license_file',
                            'displayName':'DEA License File',
                            'dependency' : {'purch_contr_subst':'on'},
                            'inputType': UI_TYPES.FILE
                        }
                    ),
                    new inputField(
                        {
                            'field':'dea_questionnaire_file',
                            'displayName':'DEA Questionnaire File',
                            'help': 'Request a copy of the DEA Questionnaire from customerservice@rsnortheast.com and upload the completed form here',
                            'dependency' : {'purch_contr_subst':'on'},
                            'inputType': UI_TYPES.FILE
                        }
                    )
                ]}
            ),
            // Corporation Info
            new tabSection(
                {'name' : 'corporation_info',
                'displayName' : 'Corporation Info',
                'help' : 'Some help text for the Corporation Info Section',
                'dependency' : {'business_type' : "Corporation"},
                'fields' : [
                    new inputField(
                        {'field' : 'corp_name',
                        'displayName' : 'Corporation Name',
                        'help' : 'Parent Corporation Name'}
                    ),
                    new inputField(
                        {'field' : 'corp_officer',
                        'displayName' : 'Corporation Officer'}
                    ),
                    new inputField(
                        {'field' : 'corp_officer_title',
                        'displayName' : 'Job Title'}
                    )
                    ]}
            ),
            // Partner's info
            new tabSection(
                {'name' : 'partner_info',
                'displayName' : 'Partner Info',
                'help' : '',
                'dependency' : {'business_type' : "Partnership"},
                'fields' : [
                    new inputField(
                        {'field' : 'partner_name_1',
                        'displayName' : 'Partner\'s Name'}
                    ),
                    new inputField(
                        {'field' : 'partner_name_2',
                        'displayName' : 'Partner\'s Name'}
                    )
                ]}
            ),
            //LLC info
            new tabSection(
                {'name' : 'llc_info',
                'displayName' : 'LLC Info',
                'help' : '',
                'dependency' : {'business_type' : "LLC"},
                'multiple' : true,
                'fields' : [
                    new inputField(
                        {'field' : 'corp_officer_name',
                        'displayName' : 'Corporate Officer\'s Name'}
                    ),
                    new inputField(
                        {'field' : 'corp_officer_title',
                        'displayName' : 'Corporate Officer\'s Title'}
                    )
                ]}
            ),
            new tabSection(
                {'name' : 'sole_proprietor_info',
                'displayName' : 'Sole Proprietor Info',
                'help' : '',
                'dependency' : {'business_type' : 'Sole-Proprietor'},
                'fields' : [
                    new inputField(
                        {'field' : 'proprietor_name',
                        'displayName' : 'Sole Proprietor Name'}
                    )
                ]}
            ),
            // primary license
            new tabSection(
                {'name':'primary_license_info',
                'displayName' : 'License Info',
                'fields' : [
                    new inputField(
                        {'field' : 'license_assignment',
                        'displayName' : 'License Assignment',
                        'fieldStyle' : new FieldStyle({'width' : 12}),
                        'inputType' : UI_TYPES.PARAGRAPH,
                        'defaultValue' : `In addition to other requirements, R&S Northeast LLC (the “company”) requires that a valid license, 
                        issued by the state in which the facility is domiciled, be on file before prescription drugs can be distributed to 
                        the facility(s) by the company. If the facility itself is state licensed, the address of the facility & the license 
                        must match exactly. In the event a state licensed healthcare provider is the responsible person for a facility or 
                        facilities, rather than the facility itself, the form below must be completed and returned to the company. The purpose 
                        of this form is to document that the facility(s) is operating within its domiciled state under the licensed supervision 
                        of the healthcare provider listed below and in accordance with applicable state law.`}
                    ),
                    new inputField(
                        {'field' : 'separator',
                        'displayName' : 'Separator',
                        'defaultValue' : 'Separator',
                        'fieldStyle' : new FieldStyle({'width' : 12}),
                        'inputType' : UI_TYPES.SEPARATOR}
                    ),
                    new inputField(
                        {
                            'field' : 'section_header',
                            'displayName' : 'Location Purchasing Requirements',
                            'defaultValue' : 'Location Purchasing Requirements',
                            'inputType' : UI_TYPES.H6
                        }
                    ),
                    new inputField(
                        {'field' : 'over_the_counter',
                        'displayName': 'Over The Counter',
                        'required': false,
                        'fieldStyle' : new FieldStyle({'extra': 'form-check-inline'}),
                        'help': `Over-the-counter medicine is also known as OTC or nonprescription medicine. All these terms refer to medicine that you can buy without a prescription. They are safe and effective when you follow the directions on the label and as directed by your health care professional.`,
                        'inputType': UI_TYPES.CHECKBOX}
                    ),
                    new inputField(
                        {'field' : 'legend_drugs',
                        'displayName': 'Legend Drugs',
                        'required': false,
                        'fieldStyle' : new FieldStyle({'extra': 'form-check-inline'}),
                        'help': `Legend Drugs are a drug approved by the U.S. Food and Drug Administration that can be dispensed to the public only with a prescription from a medical doctor or other licensed practitioner.`,
                        'inputType': UI_TYPES.CHECKBOX}
                    ),
                    new inputField(
                        {'field' : 'controlled_substances',
                        'displayName': 'Controlled Substances',
                        'required': false,
                        'fieldStyle' : new FieldStyle({'extra': 'form-check-inline'}),
                        'help': 'Controlled substances are medications that can cause physical and mental dependence, and have restrictions on how they can be filled and refilled. They are regulated and classified by the DEA (Drug Enforcement Administration) based on how likely they are to cause dependence.',
                        'inputType': UI_TYPES.CHECKBOX}
                    ),
                    new inputField(
                        {'field' : 'separator',
                        'displayName' : 'Separator',
                        'defaultValue' : 'Separator',
                        'fieldStyle' : new FieldStyle({'width' : 12}),
                        'inputType' : UI_TYPES.SEPARATOR}
                    ),
                    new inputField(
                        {'field' : 'license_holder_business_name',
                        'requiredIf': {
                            'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false
                        },
                        'displayName' : 'Business Name'}
                    ),
                    new inputField(
                        {'field' : 'license_holder_person',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'displayName' : 'Person on License'}
                    ),
                    new inputField(
                        {'field' : 'license_holder_business_type',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'displayName' : 'License Holder',
                        'inputType' : UI_TYPES.COMBOBOX,
                        'placeholder' : 'Provide License Type',
                        'options' : {
                            'Pharmacy-License':'Pharmacy License',
                            'Physician-or-Dentist-License':'Physician or Dentist License',
                            'Nurse-Practitioner-License':'Nurse Practitioner License',
                            'Pharmacist-License' : 'Pharmacist License',
                            'Other' : 'Other'
                        }}
                    ),
                    new inputField(
                        {
                            'field' : 'license_number',
                            'requiredIf': {
                                'business_info[primary_license_info][over_the_counter]': 'checked',
                                'business_info[primary_license_info][legend_drugs]':false,
                                'business_info[primary_license_info][controlled_substances]': false
                            },
                            'displayName' : 'License #'
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'license_exp_date',
                            'displayName' : 'License Expiration Date',
                            'requiredIf': {
                                'business_info[primary_license_info][over_the_counter]': 'checked',
                                'business_info[primary_license_info][legend_drugs]':false,
                                'business_info[primary_license_info][controlled_substances]': false
                            },
                            'inputType' : UI_TYPES.DATE,
                            'min' : todaysDate
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'license_address',
                            'displayName' : 'License Address',
                            'requiredIf': {
                                'business_info[primary_license_info][over_the_counter]': 'checked',
                                'business_info[primary_license_info][legend_drugs]':false,
                                'business_info[primary_license_info][controlled_substances]': false
                            },
                            'inputType' : UI_TYPES.ADDRESS
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'separator',
                            'displayName' : 'Separator',
                            'defaultValue' : 'Separator',
                            'fieldStyle' : new FieldStyle({'width' : 12}),
                            'inputType' : UI_TYPES.SEPARATOR
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'primary_gpo',
                            'displayName' : 'Primary GPO',
                            'help' : 'A group purchasing organization is an entity that is created to leverage the purchasing power of a group of businesses to obtain discounts from vendors based on the collective buying power of the GPO members.  (ex. Vizient, Premier, Afaxys)',
                            'inputType' : UI_TYPES.COMBOBOX,
                            'required': false,
                            'options' : {
                                'na': 'N/A', 
                                'Afaxys-Pharma,-LLC' : 'Afaxys Pharma, LLC',
                                'Apexus-LLC':'Apexus, LLC',
                                'Premier-Healthcare-Alliance-LP':'Premier Healthcare Alliance, LP',
                                'Vizient-Supply-LLC':'Vizient Supply, LLC',
                                '340B':'340B',
                                'ASEMBIA':'ASEMBIA',
                                'COMMONWEALTH-PURCHASING':'COMMONWEALTH PURCHASING',
                                'GERIMED':'GERIMED',
                                'NAF':'NAF',
                                'W2W':'W2W',
                                'Other':'Other'
                            }
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_Afaxys_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'Afaxys-Pharma,-LLC'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_apexus_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'Apexus-LLC'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_phal_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'Premier-Healthcare-Alliance-LP'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_vizient_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'Vizient-Supply-LLC'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_asembia_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'ASEMBIA'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_commonwealth_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'COMMONWEALTH-PURCHASING'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_gerimed_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'GERIMED'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_naf_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'NAF'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_w2w_member',
                            'displayName' : 'Provide Membership #',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo': 'W2W'}
                        }
                    ),
                    new inputField(
                        {
                            'field' : 'gpo_extra_340B',
                            'help' : 'The HIN (Health Industry Number) is a unique and standardized identifier that enumerates hospitals, providers, suppliers, and all other partners doing business in the supply chain. It is the method used by your trading partners for identifying locations of services and activities at your facilities. In the 340B channel, HINs are used by most wholesalers to efficiently validate both the billing (covered) entity and the shipping location of a PHS order, whether that is an in-house or contract pharmacy.',
                            'displayName' : 'Provide OPA 340B/HRSA ID# :  & HIN#',
                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                'business_info[primary_license_info][legend_drugs]':false,
                                'business_info[primary_license_info][controlled_substances]': false},
                            'dependency' : {'primary_gpo' : '340B'}
                        }
                    ),
                    new inputField(
                        {'field' : 'gpo_extra_other_member_num',
                        'displayName' : 'Provide Member #',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'dependency' : {'primary_gpo' : 'Other'}}
                    ),
                    new inputField(
                        {'field' : 'separator',
                        'displayName' : 'Separator',
                        'defaultValue' : 'Separator',
                        'fieldStyle' : new FieldStyle({'width' : 12}),
                        'inputType' : UI_TYPES.SEPARATOR}
                    ),
                    // new inputField(
                    //     {'field' : 'apexus_eligible',
                    //     'displayName' : 'Apexus Eligible',
                    //     'help' : 'The 340B Prime Vendor Program (PVP), is a contract awarded by the Health Resources and Services Administration (HRSA), which is responsible for administering the 340B Drug Pricing Program. The Prime Vendor negotiates pricing discounts with participating manufacturers.',
                    //     'placeholder' : 'Provide Apexus Membership #',
                    //     'inputType' : UI_TYPES.CHECKBOX_GROUP,
                    //     'extraField' : 'apexus_member_number',
                    //     'required' : false}
                    // ),
                    // new inputField(
                    //     {'field' : 'phs_eligible',
                    //     'displayName' : 'PHS Eligible',
                    //     'help' : 'The 340B Program enables covered entities to stretch scarce federal resources as far as possible, reaching more eligible patients and providing more comprehensive services. Manufacturers participating in Medicaid agree to provide outpatient drugs to covered entities at significantly reduced prices.',
                    //     'placeholder' : 'Provide PHS Membership #',
                    //     'inputType' : UI_TYPES.CHECKBOX_GROUP,
                    //     'extraField' : 'phs_member_number',
                    //     'required' : false}
                    // ),
                    new inputField(
                        {'field' : 'dscsa_doc_email',
                        'displayName' : 'DSCSA Documentation Email',
                        'help': 'The Drug Supply Chain Security Act (DSCSA), outlines steps to build an electronic, interoperable system to identify and trace certain prescription drugs as they are distributed in the United States.  It is also known as a T3 document. ',
                        'inputType' : UI_TYPES.EMAIL,
                        'required' : false}
                    ),
                    new inputField(
                        {'field' : 'license_file',
                        'displayName' : 'License Upload',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'inputType' : UI_TYPES.FILE}
                    )
                ]}
            ),
            // license holder
            new tabSection(
                // does the signature need to be a "hand written" 
                {'name' : 'license_holder',
                'displayName' : 'License Holder',
                'help' : '',
                'fields' : [
                    new inputField(
                        {'field' : 'license_holder_name',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'displayName' : 'License Holder Name'}
                    ),
                    new inputField(
                        {'field' : 'license_holder_signature',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'inputType' : UI_TYPES.SIGNATURE1,
                        'displayName' : 'License Holder Signature'}
                    ),
                    new inputField(
                        {'field' : 'license_holder_date',
                        'displayName' : 'Date',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'inputType' : UI_TYPES.DATE}
                    ),
                    new inputField(
                        {'field' : 'separator',
                        'displayName' : 'Separator',
                        'defaultValue' : 'Separator',
                        'fieldStyle' : new FieldStyle({'width' : 12}),
                        'inputType' : UI_TYPES.SEPARATOR}
                    ),
                    new inputField(
                        {'field' : 'facility_admin_name',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'displayName' : 'Facility Administrator Name'}
                    ),
                    new inputField(
                        {'field' : 'facility_admin_signature',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'inputType' : UI_TYPES.SIGNATURE1,
                        'displayName' : 'Facility Administrator Signature'}
                    ),
                    new inputField(
                        {'field' : 'facility_admin_date',
                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                            'business_info[primary_license_info][legend_drugs]':false,
                            'business_info[primary_license_info][controlled_substances]': false},
                        'displayName' : 'Date',
                        'inputType' : UI_TYPES.DATE}
                    )
                ]}
            ),
            // other orgs
            new tabSection(
                {'name' : 'other_businesses',
                'displayName' : 'Other Organizations Covered by License',
                'help' : '',
                'fields' : [
                    new inputField(
                        {'field' : 'has_other_orgs',
                        'displayName' : 'Are any other organizations covered by this license?',
                        'inputType' : UI_TYPES.TOGGLE,
                        'required' : false,
                        'subsections' : [
                            new tabSection(
                                {
                                    'name': 'other_orgs',
                                    'displayName' : 'Organization',
                                    'multiple' : true,
                                    'dependency' : {'has_other_orgs' : 'on'},
                                    'fields' : [
                                        new inputField(
                                            {'field' : 'org_name',
                                            'displayName' : 'Business Name'}
                                        ),
                                        new inputField(
                                            {'field' : 'org_address',
                                            'displayName' : 'Business Address',
                                            'inputType' : UI_TYPES.ADDRESS}
                                        )
                                    ]
                                }
                            )
                        ]}
                    )
                ]}
            )
        ]}
    ),
    // Ship To Sites
    new tab(
        {'name' : 'additional_sites',
        'displayName' : 'Additional Sites',
        'help': 'If you have more than one Shipping Address, please enter them here. You will need to provide the license associated with each Shipping Address.',
        'sections' : [
            // accordion level
            new tabSection(
                {'name':'ship_to_site',
                'displayName' : 'Ship-To Site',
                'help' : '',
                'hideFirst' : true,
                'multiple' : true,
                'dependency' : {'toggle_first' : 'on'},
                'fields' : [
                    new inputField(
                        {
                            'field': 'entity_name',
                            'displayName': 'Ship-To Entity Name'
                        }
                    ),
                    new inputField(
                        {'field' : 'ship_to_address',
                        'displayName' : 'Ship To Address',
                        'inputType' : UI_TYPES.ADDRESS,
                        'subsections' : [
                            new tabSection(
                                {'name':'license_assignment',
                                'displayName' : 'License Assignment',
                                'fields' : [
                                    new inputField(
                                        {'field' : 'license_assignment',
                                        'displayName' : 'License Assignment',
                                        'fieldStyle' : new FieldStyle({'width' : 12}),
                                        'inputType' : UI_TYPES.PARAGRAPH,
                                        'defaultValue' : `In addition to other requirements, R&S Northeast LLC (the “company”) requires that a valid license, 
                                        issued by the state in which the facility is domiciled, be on file before prescription drugs can be distributed to 
                                        the facility(s) by the company. If the facility itself is state licensed, the address of the facility & the license 
                                        must match exactly. In the event a state licensed healthcare provider is the responsible person for a facility or 
                                        facilities, rather than the facility itself, the form below must be completed and returned to the company. The purpose 
                                        of this form is to document that the facility(s) is operating within its domiciled state under the licensed supervision 
                                        of the healthcare provider listed below and in accordance with applicable state law.`}
                                    )
                                ]}
                            ),
                            new tabSection(
                                {'name': 'location_purch_requirements',
                                'displayName': 'Location Purchasing Requirements',
                                'fields': [
                                    new inputField(
                                        {'field' : 'over_the_counter',
                                        'displayName': 'Over The Counter',
                                        'required': false,
                                        'fieldStyle' : new FieldStyle({'extra': 'form-check-inline'}),
                                        'help': 'Over-the-counter medicine is also known as OTC or nonprescription medicine. All these terms refer to medicine that you can buy without a prescription. They are safe and effective when you follow the directions on the label and as directed by your health care professional.',
                                        'inputType': UI_TYPES.CHECKBOX}
                                    ),
                                    new inputField(
                                        {'field' : 'legend_drugs',
                                        'displayName': 'Legend Drugs',
                                        'required': false,
                                        'fieldStyle' : new FieldStyle({'extra': 'form-check-inline'}),
                                        'help': 'Legend Drugs are a drug approved by the U.S. Food and Drug Administration that can be dispensed to the public only with a prescription from a medical doctor or other licensed practitioner.',
                                        'inputType': UI_TYPES.CHECKBOX}
                                    ),
                                    new inputField(
                                        {'field' : 'controlled_substances',
                                        'displayName': 'Controlled Substances',
                                        'required': false,
                                        'fieldStyle' : new FieldStyle({'extra': 'form-check-inline'}),
                                        'help': 'Controlled substances are medications that can cause physical and mental dependence, and have restrictions on how they can be filled and refilled. They are regulated and classified by the DEA (Drug Enforcement Administration) based on how likely they are to cause dependence.',
                                        'inputType': UI_TYPES.CHECKBOX}
                                    )
                                ]}
                            ),
                            new tabSection(
                                {'name':'license_info',
                                'displayName': 'License Information',
                                'fields': [
                                    new inputField(
                                        {'field' : 'license_holder_business_name',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                                        'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                                        'additional_sites[location_purch_requirements][0][controlled_substances]': false},
                                        'displayName' : 'Business Name'}
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'license_holder_person',
                                            'requiredIf': {
                                                'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                                'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                                'additional_sites[location_purch_requirements][0][controlled_substances]': false
                                            },
                                            'displayName' : 'Person on License'
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'license_holder_business_type',
                                            'requiredIf': {
                                                'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                                'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                                'additional_sites[location_purch_requirements][0][controlled_substances]': false
                                            },
                                            'displayName' : 'License Holder',
                                            'inputType' : UI_TYPES.COMBOBOX,
                                            'placeholder' : 'Provide License Type',
                                            'options' : {
                                                'Pharmacy-License':'Pharmacy License',
                                                'Physician-or-Dentist-License':'Physician or Dentist License',
                                                'Nurse-Practitioner-License':'Nurse Practitioner License',
                                                'Pharmacist-License' : 'Pharmacist License',
                                                'Other' : 'Other'
                                            }
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'separator',
                                            'displayName' : 'Separator',
                                            'defaultValue' : 'Separator',
                                            'fieldStyle' : new FieldStyle({'width' : 12}),
                                            'inputType' : UI_TYPES.SEPARATOR
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'license_number',
                                            'requiredIf': {
                                                'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                                'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                                'additional_sites[location_purch_requirements][0][controlled_substances]': false
                                            },
                                            'displayName' : 'License #'
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'license_exp_date',
                                            'displayName' : 'License Expiration Date',
                                            'requiredIf': {
                                                'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                                'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                                'additional_sites[location_purch_requirements][0][controlled_substances]': false
                                            },
                                            'inputType' : UI_TYPES.DATE,
                                            'min' : todaysDate
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'license_address',
                                            'displayName' : 'License Address',
                                            'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                                'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                                'additional_sites[location_purch_requirements][0][controlled_substances]': false
                                            },
                                            'inputType' : UI_TYPES.ADDRESS
                                        }
                                    ),
                                    
                                    new inputField(
                                        {
                                            'field' : 'separator',
                                            'displayName' : 'Separator',
                                            'defaultValue' : 'Separator',
                                            'fieldStyle' : new FieldStyle({'width' : 12}),
                                            'inputType' : UI_TYPES.SEPARATOR
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'primary_gpo',
                                            'displayName' : 'Primary GPO',
                                            'help' : 'A group purchasing organization is an entity that is created to leverage the purchasing power of a group of businesses to obtain discounts from vendors based on the collective buying power of the GPO members.  (ex. Vizient, Premier, Afaxys)',
                                            'inputType' : UI_TYPES.COMBOBOX,
                                            'required': false,
                                            'options' : {
                                                'na': 'N/A', 
                                                'Afaxys-Pharma,-LLC' : 'Afaxys Pharma, LLC',
                                                'Apexus-LLC':'Apexus, LLC',
                                                'Premier-Healthcare-Alliance-LP':'Premier Healthcare Alliance, LP',
                                                'Vizient-Supply-LLC':'Vizient Supply, LLC',
                                                '340B':'340B',
                                                'ASEMBIA':'ASEMBIA',
                                                'COMMONWEALTH-PURCHASING':'COMMONWEALTH PURCHASING',
                                                'GERIMED':'GERIMED',
                                                'NAF':'NAF',
                                                'W2W':'W2W',
                                                'Other':'Other'
                                            }
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_Afaxys_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'Afaxys-Pharma,-LLC'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_apexus_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'Apexus-LLC'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_phal_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'Premier-Healthcare-Alliance-LP'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_vizient_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'Vizient-Supply-LLC'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_asembia_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'ASEMBIA'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_commonwealth_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'COMMONWEALTH-PURCHASING'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_gerimed_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'GERIMED'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_naf_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'NAF'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_w2w_member',
                                            'displayName' : 'Provide Membership #',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo': 'W2W'}
                                        }
                                    ),
                                    new inputField(
                                        {
                                            'field' : 'gpo_extra_340B',
                                            'help' : 'The HIN (Health Industry Number) is a unique and standardized identifier that enumerates hospitals, providers, suppliers, and all other partners doing business in the supply chain. It is the method used by your trading partners for identifying locations of services and activities at your facilities. In the 340B channel, HINs are used by most wholesalers to efficiently validate both the billing (covered) entity and the shipping location of a PHS order, whether that is an in-house or contract pharmacy.',
                                            'displayName' : 'Provide OPA 340B/HRSA ID# :  & HIN#',
                                            'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                                'business_info[primary_license_info][legend_drugs]':false,
                                                'business_info[primary_license_info][controlled_substances]': false},
                                            'dependency' : {'primary_gpo' : '340B'}
                                        }
                                    ),
                                    new inputField(
                                        {'field' : 'gpo_extra_other_member_num',
                                        'displayName' : 'Provide Member #',
                                        'requiredIf': {'business_info[primary_license_info][over_the_counter]': 'checked',
                                            'business_info[primary_license_info][legend_drugs]':false,
                                            'business_info[primary_license_info][controlled_substances]': false},
                                        'dependency' : {'primary_gpo' : 'Other'}}
                                    ),
                                    // new inputField(
                                    //     {'field' : 'apexus_eligible',
                                    //     'displayName' : 'Apexus Eligible',
                                    //     'help' : 'The 340B Prime Vendor Program (PVP), is a contract awarded by the Health Resources and Services Administration (HRSA), which is responsible for administering the 340B Drug Pricing Program. The Prime Vendor negotiates pricing discounts with participating manufacturers.',
                                    //     'placeholder' : 'Provide Apexus Membership #',
                                    //     'inputType' : UI_TYPES.CHECKBOX_GROUP,
                                    //     'extraField' : 'apexus_member_number',
                                    //     'required' : false}
                                    // ),
                                    // new inputField(
                                    //     {'field' : 'phs_eligible',
                                    //     'displayName' : 'PHS Eligible',
                                    //     'help' : 'The 340B Program enables covered entities to stretch scarce federal resources as far as possible, reaching more eligible patients and providing more comprehensive services. Manufacturers participating in Medicaid agree to provide outpatient drugs to covered entities at significantly reduced prices.',
                                    //     'placeholder' : 'Provide PHS Membership #',
                                    //     'inputType' : UI_TYPES.CHECKBOX_GROUP,
                                    //     'extraField' : 'phs_member_number',
                                    //     'required' : false}
                                    // ),
                                    new inputField(
                                        {'field' : 'dscsa_doc_email',
                                        'displayName' : 'DSCSA Documentation Email',
                                        'help': 'The Drug Supply Chain Security Act (DSCSA), outlines steps to build an electronic, interoperable system to identify and trace certain prescription drugs as they are distributed in the United States.  It is also known as a T3 document. ',
                                        'inputType' : UI_TYPES.EMAIL,
                                        'required' : false}
                                    ),
                                    new inputField(
                                        {'field' : 'license_file',
                                        'displayName' : 'License Upload',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                            'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                            'additional_sites[location_purch_requirements][0][controlled_substances]': false},
                                        'inputType' : UI_TYPES.FILE}
                                    )
                                ]}
                            ),
                            new tabSection(
                                {'name' : 'license_holder',
                                'displayName' : 'License Holder',
                                'help' : '',
                                'fields' : [
                                    new inputField(
                                        {'field' : 'license_holder_name',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                            'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                            'additional_sites[location_purch_requirements][0][controlled_substances]': false},
                                        'displayName' : 'License Holder Name'}
                                    ),
                                    new inputField(
                                        {'field' : 'license_holder_signature',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                            'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                            'additional_sites[location_purch_requirements][0][controlled_substances]': false},
                                        'displayName' : 'License Holder Signature',
                                        'inputType' : UI_TYPES.SIGNATURE1
                                    }
                                    ),
                                    new inputField(
                                        {'field' : 'license_holder_date',
                                        'displayName' : 'Date',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                            'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                            'additional_sites[location_purch_requirements][0][controlled_substances]': false},
                                        'inputType' : UI_TYPES.DATE}
                                    ),
                                    new inputField(
                                        {'field' : 'separator',
                                        'displayName' : 'Separator',
                                        'defaultValue' : 'Separator',
                                        'fieldStyle' : new FieldStyle({'width' : 12}),
                                        'inputType' : UI_TYPES.SEPARATOR}
                                    ),
                                    new inputField(
                                        {'field' : 'facility_admin_name',
                                        'displayName' : 'Facility Administrator Name',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                            'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                            'additional_sites[location_purch_requirements][0][controlled_substances]': false}
                                        }
                                    ),
                                    new inputField(
                                        {'field' : 'facility_admin_signature',
                                        'displayName' : 'Facility Administrator Signature',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                            'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                            'additional_sites[location_purch_requirements][0][controlled_substances]': false},
                                            'inputType' : UI_TYPES.SIGNATURE1
                                        }
                                    ),
                                    new inputField(
                                        {'field' : 'facility_admin_date',
                                        'displayName' : 'Date',
                                        'requiredIf': {'additional_sites[location_purch_requirements][0][over_the_counter]': 'checked',
                                            'additional_sites[location_purch_requirements][0][legend_drugs]':false,
                                            'additional_sites[location_purch_requirements][0][controlled_substances]': false},
                                        'inputType' : UI_TYPES.DATE}
                                    )
                                    ]}
                            ),
                            new tabSection(
                                {'name' : 'shipto_other_businesses',
                                'displayName' : 'Other Organizations Covered by License',
                                'help' : '',
                                'fields' : [
                                    new inputField(
                                        {'field' : 'shipto_has_other_orgs',
                                        'displayName' : 'Are any other organizations covered by this license?',
                                        'inputType' : UI_TYPES.TOGGLE,
                                        'required' : false,
                                        'subsections' : [
                                            new tabSection(
                                                {
                                                    'name': 'shipto_other_orgs',
                                                    'displayName' : 'Organization',
                                                    'multiple' : true,
                                                    'dependency' : {'shipto_has_other_orgs' : 'on'},
                                                    'fields' : [
                                                        new inputField(
                                                            {'field' : 'shipto_org_name',
                                                            'displayName' : 'Business Name'}
                                                        ),
                                                        new inputField(
                                                            {'field' : 'shipto_org_address',
                                                            'displayName' : 'Business Address',
                                                            'inputType' : UI_TYPES.ADDRESS}
                                                        )
                                                    ]
                                                }
                                            )
                                        ]}
                                    )
                                ]}
                            )
                        ]
                    }
                    )
                ]}
                
            )
        ]}
    ),
    // Payment Info Tab
    new tab(
        {
            'name' : 'payment_info',
            'displayName' : 'Payment Information',
            'sections' : [
                new tabSection(
                    {'name' : 'accounts-contacts',
                    'displayName' : 'Accounts Payable/Receivable',
                    'help' : '',
                    'fields' : [
                        new inputField(
                            {'field' : 'messages',
                            'displayName' : 'Messages will be sent from noreply@rsnortheast.com - Please add to your address book',
                            'defaultValue' : '<p><em>Messages will be sent from noreply@rsnortheast.com - Please add to your address book</em></p>',
                            'inputType' : UI_TYPES.PARAGRAPH}
                        ),
                        new inputField(
                            {'field' : 'purchase_agent',
                            'displayName' : 'Purchasing Agent',
                            'inputType' : UI_TYPES.FULL_CONTACT}
                        ),
                        new inputField(
                            {'field' : 'payable_contact',
                            'displayName' : 'Accounts Payable Contact',
                            'inputType' : UI_TYPES.FULL_CONTACT}
                        ),
                        new inputField(
                            {'field' : 'receivable_contact',
                            'displayName' : 'Accounts Receivable Contact',
                            'required' : false,
                            'inputType' : UI_TYPES.FULL_CONTACT}
                        )
                    ]}
                ),
                new tabSection(
                    {'name' : 'supplier-info',
                    'displayName' : 'Supplier Information',
                    'help' : '',
                    'fields' : [
                        new inputField(
                            {'field' : 'primary_supplier',
                            'displayName' : 'Primary Supplier',
                            'inputType' : UI_TYPES.MIN_CONTACT,
                            'required' : false}
                        ),
                        new inputField(
                            {'field' : 'secondary_supplier',
                            'displayName' : 'Secondary Supplier',
                            'inputType' : UI_TYPES.MIN_CONTACT,
                            'required' : false}
                        ),
                        new inputField(
                            {'field' : 'trade_reference',
                            'displayName' : 'Trade Reference',
                            'required' : false,
                            'inputType' : UI_TYPES.MIN_CONTACT}
                        ),
                        new inputField(
                            {
                                'field':'supplier_customer_statement',
                                'displayName':'Supplier Customer Statement',
                                'required':false,
                                'inputType':UI_TYPES.FILE
                            }
                        )
                    ]}
                ),
                new tabSection(
                    {
                        'name' : 'financial-info' ,
                        'displayName' : 'Financial Information',
                        'help' : '',
                        'fields' : [
                            new inputField(
                                {
                                    'field':'tax_expempt_form',
                                    'displayName':'Tax Exempt Form',
                                    'required':false,
                                    'inputType':UI_TYPES.FILE
                                }
                            ),
                            new inputField(
                                {'field':'cash_or_credit',
                                'displayName':'Payment Option',
                                'help':'Credit: Fill out the required fields to help us determine your eligibility. Cash: Our team will reach out to your Accounts Payable contact to get more information.',
                                'inputType':UI_TYPES.RADIO_STACKED,
                                'options':{
                                    'credit':'Establish Line of Credit',
                                    'cash':'Pay by "Cash in Advance"'
                                }}
                            )
                        ],
                        'subsections' : [
                            new tabSection(
                                {
                                    'name':'credit_request',
                                    'displayName': 'Credit Request',
                                    'dependency' : {"cash_or_credit": "credit"},
                                    'help' : '',
                                    'fields' : [
                                        new inputField(
                                            {'field' : 'requested_credit_amount',
                                            'displayName' : 'Credit Amount Requested',
                                            'help': 'The credit amount requested is needed per our Finance department in order to validate credit amount needed for the new account. Value over $99,999 requires you to provide your latest Financial Statement. ',
                                            'min' : '0',
                                            'defaultValue': '0',
                                            'inputType' : UI_TYPES.CURRENCY
                                            }
                                        ),
                                        new inputField(
                                            {'field' : 'tax_return_file',
                                            'displayName' : 'Upload Most Recent Tax Return or Financial Statement',
                                            'inputType' : UI_TYPES.FILE,
                                            'required' : true,
                                            'dependency' : {'requested_credit_amount' : "100000"}
                                            }
                                        ),
                                        new inputField(
                                            {'field' : 'est_annual_amount',
                                            'displayName' : 'Estimated Annual Dollar Amount',
                                            'help' : 'Estimated dollar volume will give our Finance department a good idea of what amount of dollar volume we can expect once the new account has been established. ',
                                            'inputType' : UI_TYPES.CURRENCY
                                            }
                                        ),
                                        new inputField(
                                            {'field' : 'payment_method',
                                            'displayName' : 'Payment Method',
                                            'inputType' : UI_TYPES.DROPDOWN,
                                            'options' : {'ACH' : 'ACH', 'Check' : 'Check', 'Wire': 'Wire'}
                                            }
                                        )
                                    ]
                                }
                            ),
                            new tabSection(
                                {'name' : 'bank-info',
                                'displayName' : 'Bank Information',
                                'dependency' : {"cash_or_credit": "credit"},
                                'help' : '',
                                'fields' : [
                                    new inputField(
                                        {'field' : 'bank_name',
                                        'displayName' : 'Bank Name'}
                                    ),
                                    new inputField(
                                        {'field' : 'bank_officer',
                                        'displayName' : 'Bank Officer'}
                                    ),
                                    new inputField(
                                        {'field' : 'bank_address',
                                        'displayName' : 'Bank Address',
                                        'inputType' : UI_TYPES.ADDRESS}
                                    ),
                                    new inputField(
                                        {'field' : 'bank_telephone',
                                        'displayName' : 'Bank Telephone',
                                        'validation':"[+0-9]?[(]?[0-9]{3}[)]?-?[0-9]{3}-?[0-9]{4}",
                                        'inputType' : UI_TYPES.PHONE}
                                    ),
                                    new inputField(
                                        {'field' : 'bank_account_type',
                                        'displayName' : 'Account Type',
                                        'inputType' : UI_TYPES.DROPDOWN,
                                        'options' : {'Checking': 'Checking', 'Savings' : 'Savings'}}
                                    ),
                                    new inputField(
                                        {'field' : 'bank_routing_number',
                                        'displayName' : 'Routing Number'}
                                    ),
                                    new inputField(
                                        {'field' : 'bank_account_number',
                                        'displayName' : 'Account Number'}
                                    )
                                ]}
                            )
                        ]
                    }
                )
            ]
        }
    ),
    // Policies
    new tab(
        {'name' : 'sales_policy',
        'displayName' : 'Sales Policy',
        'sections' : [
            new tabSection(
                {'name':'general_sales_policy',
                'displayName' : 'General Sales Policy',
                'help' : '',
                'fields' : [
                    new inputField(
                        {'field' : 'general_policy_text',
                        'displayName' : 'ENTER GENERAL SALES POLICY VERBIAGE',
                        'defaultValue' : `<h2>TERMS AND CONDITIONS</h2>
                        <p>Purchase agreements shall be construed in accordance with the laws of the Commonwealth of Kentucky. R&S Northeast LLC (the “company”) and the Customer agree that any and all claims arising under purchase agreements shall be subject to the jurisdiction of the courts of the Commonwealth of Kentucky. A properly executed credit application is required before sales on credit are provided.</p>
                        <p>Payment for sales and services is to be made from the date of the invoice and within the terms agreed to and as stated on the invoice. Unless otherwise approved by the Company, full payment is due 30 Days after invoice date. Statements are mailed as a courtesy during the first week of each month. Failure to pay invoices for merchandise and finance charges, if any, in full and within agreed terms will result in curtailment of shipments and may result in revocation of credit terms or termination of the business relationship with the Customer.</p>
                        <p>Failure to pay invoices by due date will result in the assessment of a finance charge of 1.5% per 30-day billing cycle on the outstanding balance. In the event the Company incurs collection costs or institutes suit to collect amounts past due under this agreement or any portion thereof, Customer promises to pay such additional collection costs, charges, and expenses including reasonable attorney’s fees.</p>
                        <p>Customer agrees not to make any deduction from payment unless credit memorandum has been issued to the Customer. Credits are void 12 months after the date of issue and are valid only against future purchases and may not be redeemed for cash.</p>
                        <p>Payment must be made in U.S. currency only and may be in the form of check, money order, wire transfer, or credit card. Cash is not accepted. Payments in the form of checks or money order must be addressed and forwarded to the Company’s lockbox at Dixon Shane LLC d/b/a R&S Northeast LLC, P.O. Box 935485, Atlanta, GA 31193-5485. Payments must be received in the lockbox on or before the due date in order to avoid disruptions in shipments and additional finance charges.</p>
                        <p>The purchase of prescription drugs requires proof of appropriate license(s). Product will not be shipped without proper documentation. R&S Northeast reserves the right to limit purchase quantities. Reports of suspicious order quantities of controlled substances will be reported to the DEA by the Company in accordance with the law.</p>
                        <p>For sales into states where the Company is not required to collect sales tax, the Customer agrees to report, collect and remit all applicable sales and use taxes to the appropriate taxing authority. If required, the Customer is responsible for providing the Company with documentation of proof of sales tax-exempt status.</p>
                        <p>Every attempt will be made to inform the Customer of policy changes. However, the Company reserves the right to amend prices and terms and conditions of sale without notification.</p>
                        
                        <h3>CREDIT</h3>
                        <p>Credit may be extended to new customers upon completion of a properly executed R&S Northeast LLC credit application. The Customer will go on credit hold 46 days after the date of any unpaid invoice. The Customer may also go on credit hold for exceeding their credit limit, a history of slow payment, for a license discrepancy, for violations of Federal or State Law, or reasons not listed here.</p>
                        
                        <h3>PRICING</h3>
                        <p>Customer orders and credits whether based on submitted quotations or not, are subject to acceptance and approval by The Company.</p>
                        
                        <h3>DAMAGED SHIPMENTS AND DISCREPANCIES</h3>
                        <p>If damage is visible at the time of delivery, a notation of damages should be made on the carrier’s delivery or freight bill or order can be refused. Notification of delivery problems, shipping errors, discrepancies and other claims against sales must be made to the Company within 48 hours of receipt of shipment to ensure consideration for credit, processing of replacement order and to file a freight claim with the carrier.</p>
                        <p>To avoid processing fees, return of product for damages and discrepancies must be received by the Company within 30 Days of the invoice date. Return policy is outlined in the company Return Goods Policy.</p>
                        
                        <h3>DELAY IN TRANSIT</h3>
                        <p>The Company is not responsible for delays in transit due to weather conditions, carrier strikes and other acts of nature which may impede shipment of product. If an expected delivery has not been received within 10 business days after product was expected to be received, call customer service for status of the order.</p>
                        
                        <h3>SHIPPING</h3>
                        <p>Terms are F.O.B. R&S Northeast LLC Philadelphia, PA 19116. A charge of $14.95 per order will be charged on orders under $1,000.00. Large orders and special handling requests are billed at additional cost.</p>
                        <p>Refrigerated shipments will be charged based on weight including required packaging, dimension of package, and destination.</p>
                        <p>The Company uses UPS and FedEx as prime carriers. Requests for next day, second day, third day, and Saturday delivery are available at an additional cost. To guarantee next day or second day delivery, orders must be placed before 3:00 p.m. Eastern Standard Time (EST).</p>
                        
                        <h3>FORCE MAJURE</h3>
                        <p>R&S Northeast LLC shall not be liable for failure to supply products or to perform on any contract due to strike, acts of nature, acts of Government, and interruptions in transportation, inability to obtain supplies of raw materials, product recalls or other causes beyond the Company’s control.</p>`,
                        'inputType' : UI_TYPES.PARAGRAPH,
                        'fieldStyle' : new FieldStyle({'width':12})}
                    ),
                    new inputField(
                        {'field' : 'general_policy_agree',
                        'displayName' : 'Agree',
                        'inputType' : UI_TYPES.TOGGLE}
                    )
                    ]}
            ),
            
        ]}
    ),
    // Return Goods Policy Tab
    new tab(
        {'name' : 'return_goods_policy',
        'displayName' : 'Return Goods Policy',
        'sections' : [
            new tabSection(
                {'name':'return_goods_policy',
                'displayName' : 'Return Goods Policy',
                'help' : '',
                'fields' : [
                    new inputField(
                        {
                            'field' : 'return_policy_text',
                            'displayName' : 'ENTER RETURN GOODS POLICY VERBIAGE',
                            'defaultValue' : `<h3>AUTHORIZATION</h3>
                            <p>R&S Northeast LLC (the Company) requires that the Return Goods Policy be signed by an authorized representative of customer and be on file as a precondition to return product. Authorization is required prior to each return shipment of merchandise. All products returned without proper authorization will be destroyed by the Company at the customer’s expense.</p>
                            
                            <h3>TERMS OF RETURN POLICY</h3>
                            <p>The Company will accept returned merchandise in accordance with the guidelines required by the Prescription Drug Marketing Act (PDMA) of 1987, as amended and only from the Company’s direct customers in good credit standing.</p>
                            <p>Products returned are subject to final review and acceptance by the Company and will be processed in accordance with the return policy in effect when the product is received. Products received become the property of the Company who reserves the right to dispose of the merchandise and declare no credit value. Product received by the Company outside of policy guidelines will not be returned to customers and credit will not be issued. The Company is not responsible for return shipments lost or damaged in transit.</p>
                            <p>The Company reserves the right to determine whether items qualify for return, credit or refund; and the right to modify this policy without advance notice. The Company will not honor any Processing/Handling, Documentation, Administrative or Destruction Fee’s assessed for the return, handling, processing or incineration of product.</p>
                            
                            <h3>RETURNABLE ITEMS</h3>
                            <ul>
                            <li>Product purchased directly from the Company</li>
                            <li>Product shipped as a result of the Company’s shipping error if reported within 10 days of receipt</li>
                            <li>Product must be received by the Company within 30 days of the RMA being issued</li>
                            <li>Product that is unopened, undamaged, with intact labels and in original manufacturer’s original packaging and condition</li>
                            <li>Product may be returned prior to expiration.</li>
                            <li>* Expired products may be returned at the sole discretion of the Company</li>
                            </ul>
                            <h3>NON-RETURNABLE ITEMS</h3>
                            <ul>
                            <li>Refrigerated products
                            <li>Product that has been opened, damaged, defaced, written on, without intact labels and not in manufacturer’s original packaging & condition</li>
                            <li>Products purchased or otherwise obtained in violation of any Federal, State or Local law or regulation</li>
                            <li>Product discontinued by the Company for more than 1 year</li>
                            <li>Product not received by the Company within 30 days of the Return Material Authorization form being issued</li>
                            <li>Third party returns</li>
                            <li>Repackaged and/or private label product</li>
                            <li>Product sold as non-returnable</li>
                            </ul>
                            <h3>CREDIT</h3>
                            <p>Credits are calculated based on the customer’s cost of acquisition less a 25% processing fee. Credits will be allowed for future purchases only and are valid for twelve months from date of issue. Expired merchandise will receive credit based on individual manufacturer’s credit policies, but the product must be received by the Company in sufficient time to comply with the return policies of the manufacturer. Credit will not be issued for products destroyed by customers or third parties.</p>
                            
                            <h3>TRANSPORTATION CHARGES</h3>
                            <p>Transportation charges for returns are the responsibility of the customer.</p>
                            
                            <h3>CUSTOMER PROCEDURE</h3>
                            <ol>
                            <li>Customer must contact the Company’s Customer Service Representative and request a Return Material Authorization form.</li>
                            <li>Complete the Return Material Authorization and return it to the Company as directed.</li>
                            <li>Upon receiving authorization and directions from the Company, return the product to:
                                <br/><b>R&S Northeast LLC, PA.</b></li>
                            <li>The Customer will attest that proper quality control measures were followed during the storage, handling, and shipping of the merchandise being returned.</li>
                            </ol>`,
                            'inputType' : UI_TYPES.PARAGRAPH,
                            'fieldStyle' : new FieldStyle({'width':12})
                        }
                    ),
                    new inputField(
                        {
                        'field' : 'return_policy_agree',
                        'displayName' : 'Agree',
                        'inputType' : UI_TYPES.TOGGLE
                        }
                    )
                ]}
            )
        ]}
    ),
    /* // Review and Submit
    // include final signatures */
    new tab(
        {
            'name' : 'review_submit',
            'displayName' : "Review & Submit",
            'sections' : [
                new tabSection(
                    {
                        'name' : 'final_acceptance',
                        'displayName' : 'Name & Signature',
                        'fields' : [
                            new inputField(
                                {
                                    'field' : 'true_and_accurate',
                                    'displayName' : 'I hereby confirm that all the information in this form is true and accurate to the best of my knowledge.',
                                    'defaultValue' : 'I hereby confirm that all the information in this form is true and accurate to the best of my knowledge.',
                                    'inputType' : UI_TYPES.PARAGRAPH,
                                    'fieldStyle' : new FieldStyle({'width':12})
                                }
                            ),
                            new inputField(
                                {
                                    'field': 'accept_name',
                                    'displayName' : 'Name'
                                }
                            ),
                            new inputField(
                                {
                                    'field' : 'accept_signature',
                                    'displayName' : 'Signature',
                                    'inputType' : UI_TYPES.SIGNATURE1
                                }
                            ),
                            new inputField(
                                {
                                    'field' : 'accept_date',
                                    'displayName' : 'Date',
                                    'inputType' : UI_TYPES.DATE
                                }
                            )
                        ]
                    }
                ),
                new tabSection(
                    {
                        'name' : 'review',
                        'displayName' : 'Review Application',
                        'help' : 'Review your form entry',
                        'fields' : [
                            new outputField(
                                {
                                    'field' : 'output_field',
                                    'label' : 'Label',
                                    'value' : 'value',
                                    'dependency' : {},
                                    'subsections' : []
                                }
                            )
                        ]
                    }
                )
            ]
        }
    )
];


const Constants = {tabs, todaysDate, minContactFields, contactFields, addressFields, stateList,iconFields, simpleOutputTypes, simpleInputTypes,
    FieldStyle, SectionStyle, tab, tabSection, fieldGroup, outputField, inputField, UI_TYPES, ValueTypes};

export default Constants;
