import React, { Component } from 'react';

import NewCustomerForm from '../../new-customer-form/new-customer-form';
import SocialMedia from '../../layout/SocialMedia';
import { Anchor } from 'react-bootstrap';

export default class SideBar extends Component {
  render() {
    return (
      <div className="col-12 col-lg-3 order-12 order-lg-1 colorBg p-5" id="sideBar">
        <p className="warning">
          <strong className="d-block mb-2">
            To report Adverse Events, share concerns, or ask general product questions, please call{' '}
            <a href="tel:855-361-3993">(855) 361-3993</a> Toll Free or email us at{' '}
            <a href="mailto:DrugSafety@avkare.com">drugsafety@avkare.com</a>{' '}
            or report online to FDA - MedWatch or by phone <a href='tel:800-332-1088' title='1-800-332-1088'>1-800-FDA-1088</a>
          </strong>
        </p>
        <p className="pt-4">
          <strong className="d-block mb-2">Contact Us:</strong>
          Feel free to call us or complete the inquiry form. An AvKARE representative
          will contact you as soon as possible.
        </p>
        <p className='pt-4'>
          <strong className="d-block mb-2">Become a Customer:</strong>
          <p>Fill out our online Customer Application.</p>
          {/* <NewCustomerForm /> */}
          <a href='https://signup.avkare.com/' className='btn btn-primary'>New Customer Form</a>
        </p>
        <p className="pt-4">
          <strong className="d-block mb-2">Phone:</strong>
          <a href="tel:931-292-6222" className="d-block phone">
            (931) 292-6222
          </a>
          <a href="tel:855-628-5273" className="d-block phone">
            (855) 6AvKARE <small className="text-nowrap">Toll Free</small>
          </a>
        </p>
        <div className="pt-4 pb-4">
          <strong className="d-block pb-2">Follow:</strong>
          <SocialMedia />
        </div>
      </div>
    );
  }
}
