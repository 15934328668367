import React, {Component} from 'react';
import { InputGroup, Form} from 'react-bootstrap';
import Constants, { UI_TYPES } from './new-customer-form-constants';

export class HelpIcon extends Component {
    
    render() {
        return (
            <span className="help-cursor" data-bs-toggle="tooltip" data-bs-placement="top" title={this.props.helpText}>
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlinkHref="#info-fill"/></svg>
            </span>
        )
    }
}

export class FieldIconWrapper extends Component {
    render() {
        return (
            <InputGroup>
                {this.props.children}
            </InputGroup>
        )
    }
}

export class InputFieldIcon extends Component {
    switchIconOnFieldType(type) {
        switch (type) {
            case UI_TYPES.EMAIL:
                return <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Email:"><use xlinkHref="#bi-envelop"/></svg>;
            case UI_TYPES.TEXT:
                return <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Name:"><use xlinkHref="#bi-person"/></svg>
            case UI_TYPES.PHONE:
                return <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Phone:"><use xlinkHref="#bi-telephone"/></svg>;
            default:
                return;
        }
    }

    render () {
        return (
            <FieldIconWrapper>
                <InputGroup.Text>
                    {this.switchIconOnFieldType(this.props.fieldType)}
                </InputGroup.Text>
                {this.props.children}
            </FieldIconWrapper>
        )
    }
}

export class Paragraph extends Component {
    
    render() {
        return (
            <p className={this.props.passedProps.fieldData.fieldStyle.colStyle()}
                dangerouslySetInnerHTML={{__html: this.props.passedProps.fieldData.defaultValue}}>
                
            </p>
        )
    }
}

export class OrderedList extends Component {
    
    render() {
        return (
            <ol>
                {Object.entries(this.props.passedProps.fieldData.options).map((option, key) => {
                    // console.log(key, option);
                    return <li key={key.toString()}>
                        <h5>{option[0]}</h5>
                        <p>{option[1]}</p>
                    </li>
                })}
            </ol>
        )
    }
}

export class Heading extends Component {
    constructor(props) {
        super();
        this.field = props.passedProps.fieldData;
    }
    
    headingSize() {
        switch (this.field.inputType) {
            case Constants.UI_TYPES.H1:
                return <h1 className={this.field.fieldStyle.colStyle()}>
                    {this.field.displayName}
                </h1>
            case Constants.UI_TYPES.H2:
                return <h2 className={this.field.fieldStyle.colStyle()}>
                    {this.field.displayName}
                </h2>
            case Constants.UI_TYPES.H3:
                return <h3 className={this.field.fieldStyle.colStyle()}>
                    {this.field.displayName}
                </h3>
            case Constants.UI_TYPES.H4:
                return <h4 className={this.field.fieldStyle.colStyle()}>
                    {this.field.displayName}
                </h4>
            case Constants.UI_TYPES.H5:
                return <h5 className={this.field.fieldStyle.colStyle()}>
                    {this.field.displayName}
                </h5>
            case Constants.UI_TYPES.H6:
                return <h6 className={this.field.fieldStyle.colStyle()}>
                    {this.field.displayName}
                </h6>
            default:
                return <h4 className={this.field.fieldStyle.colStyle()}>
                    {this.field.displayName}
                </h4>;
        }
    }

    render () {
        return (
            this.headingSize()
        )
    }
}

export class Separator extends Component {
    
    render() {
        return (
            <hr className={this.props.passedProps.fieldData.fieldStyle.colStyle()}/>
        )
    }
}

export class HiddenInput extends Component {
    field = this.props.passedProps.fieldData;
    render() {
        return (
            <input type="hidden" name={this.field.field} id={this.field.field} defaultValue={this.field.defaultValue} disabled/>
        )
    }
}

class ParentFieldComponent extends Component {
    constructor(props) {
        super();
        let passedProps = props.passedProps;
        let classList = 'form-control';
        this.tabName = passedProps.tabName;
        this.sectionName = passedProps.sectionName || '';
        this.multipleFieldKey = passedProps.multipleFieldKey || '';
        this.multipleSectionKey = passedProps.multipleSectionKey || '';
        this.multipleSubsectionKey = passedProps.multipleSubsectionKey || '';
        this.fieldKey = passedProps.fieldKey;
        this.field = passedProps.fieldData;

        this.changeHandlers = passedProps.changeHandlers;

        this.inputName = this.tabName + '[' + this.sectionName + ']' + this.multipleSectionKey + this.multipleSubsectionKey + '[' + this.field.field + ']' + this.multipleFieldKey;
        
        if (this.field.inputType == UI_TYPES.SIGNATURE1) {classList += ' signature1 '; }
        if (this.field.inputType == UI_TYPES.SIGNATURE2) {classList += ' signature2 '; }

        this.inputProps = {
            'required': this.field.required,
            'id': `${this.tabName}[${this.sectionName}]${this.multipleSectionKey}${this.multipleSubsectionKey}[${this.field.field}]${this.field.defaultValue}`,
            'title': this.field.help,
            'data-bs-toggle': "tooltip",
            'name': `${this.tabName}[${this.sectionName}]${this.multipleSectionKey}${this.multipleSubsectionKey}[${this.field.field}]${this.multipleFieldKey}`,
            'className': classList,
            'placeholder': (this.field.placeholder ? this.field.placeholder : (this.field.inputType === Constants.UI_TYPES.DROPDOWN ? 'Select ': '') + this.field.displayName),
            'aria-label':this.field.displayName,
            'defaultValue': this.field.defaultValue ? this.field.defaultValue : '',
            'type': 'text'
        };

        this.requiredIf();
        this.isRequired();
        this.hasMaximum();
        this.hasMaximum();
        this.hasValidation();
    }

    getLabel() {
        return <label htmlFor={this.inputName}>
            
            {this.field.help && <HelpIcon helpText={this.field.help}/>}
            
            {this.field.displayName}
            &nbsp;
            {this.field.required && <span className='text-danger required-ui'>*</span>}
        </label>
    }

    requiredIf() {
        if (this.field.requiredIf) this.inputProps['data-rs-required-if'] = JSON.stringify(this.field.requiredIf);
    }

    isRequired() {
        if (this.field.required) {
            this.inputProps['required'] = this.field.required;
            this.inputProps['className'] += ' init-required';
        }
    }

    hasMinimum() {
        if(Number(this.field.min)) this.inputProps['min'] = this.field.min;
    }
    hasMaximum() {
        if(Number(this.field.max)) this.inputProps['max'] = this.field.max;
    }
    hasValidation() {
        if (this.field.validation) this.inputProps['pattern'] = this.field.validation;
    }

    getFieldOutput() {}
    render(){return false;}
}

export class BasicInput extends ParentFieldComponent {
    
    constructor(props) {
        super(props);
        
        this.inputProps['type'] = this.field.inputType;
        
        this.handleChange = this.handleChange.bind(this);

        this.input = React.createRef();
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    getFieldOutput() {
        if (this.field.inputType == UI_TYPES.DATE) {
            return <Form.Control {...this.inputProps} ref={this.input}/>
        } else {
            return <input {...this.inputProps} ref={this.input} onChange={this.handleChange}/>
        }
        
    }

    getInputGroup() {
        if (Constants.iconFields.includes(this.field.inputType)) {
            return <InputGroup>
                        <InputFieldIcon fieldType={this.field.inputType}>
                        {this.getFieldOutput()}
                        </InputFieldIcon>
                        
                    </InputGroup>
        } else {
            return this.getFieldOutput();
        }
    }

    render() {
        return <>
            {this.getLabel()}
            {this.getInputGroup()}
            </>
        
    }
}

export class BasicSelect extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.select = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <>
            {this.getLabel()}
            <select {...this.inputProps} className='form-select' defaultValue='' ref={this.select} onChange={this.handleChange}>
                <option value="">Select</option>
                {Object.entries(this.field.options).map((option, key) => {
                    return <option key={key.toString()} value={option[0]}>{option[1]}</option>
                })}
                
            </select>
            </>
        );
    }
}

export class BasicCheckbox extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);

        this.inputProps['className'] = ' form-check-input';
        this.handleChange = this.handleChange.bind(this);

        this.input = React.createRef();
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }
    
    render() {
        return (
            <Form.Check inline>
            <Form.Check.Input {...this.inputProps} defaultValue={this.inputProps.defaultValue || 'Yes'} defaultChecked={false} type={'checkbox'} ref={this.input} onChange={this.handleChange} />
            <Form.Check.Label htmlFor={this.inputProps.id}>{this.field.displayName} {this.field.required ? <span className='text-danger required-ui'>*</span> : ''}</Form.Check.Label>
            
            </Form.Check>
        );
    }
}

export class BasicRadio extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.inputProps['className'] = ' form-check-input';
        this.handleChange = this.handleChange.bind(this);

        this.input = React.createRef();
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <Form.Check inline>
                <Form.Check.Input {...this.inputProps} type={'radio'} ref={this.input} onChange={this.handleChange}/>
                <Form.Check.Label htmlFor={this.inputProps.id}>{this.field.displayName} {this.field.required ? <span className='text-danger required-ui'>*</span> : ''}</Form.Check.Label>
            </Form.Check>
        );
    }
}

export class RadioStacked extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        if (this.field.requireOne) {
            this.inputProps['data-rs-require-one'] = this.field.requireOne;
        }

        this.handleChange = this.handleChange.bind(this);

        this.checkbox = React.createRef();
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <>
                <p>{this.field.displayName}{this.field.required && <span className='text-danger required-ui'>*</span>}</p>
                {Object.entries(this.field.options).map((option, key) => {
                    return <div key={key.toString()} className="form-check">
                                <input {...this.inputProps}
                                        name={this.inputProps.name}
                                        ref={this.checkbox}
                                        defaultChecked={false}
                                        onChange={this.handleChange}
                                        className={`form-check-input ${this.field.required ? " init-required": '' }`}
                                        type="radio" 
                                        defaultValue={option[0]}
                                        id={`${this.field.field}-${option[0]}`}
                                    />
                                <label className="form-check-label" htmlFor={`${this.field.field}-${option[0]}`} data-bs-toggle="tooltip" title={this.field.help}>
                                    {option[1]}
                                </label>
                            </div>
                })}
            </>
        )
    }
}

export class CheckboxGroup extends ParentFieldComponent {
    constructor(props) {
        super(props);
        this.inputProps = Object.assign(this.inputProps, {
            'className':`form-control ${this.tabName}-${this.sectionName}-${this.field.field}-inputField`,
            'aria-label':`Value Input for ${this.field.displayName}`,
            'data-rs-checkbox-input':"true",
            'name':`${this.tabName}[${this.sectionName}][${this.field.field}][${this.field.extraField}]`,
            'id': `${this.tabName}-${this.sectionName}-${this.field.field}-inputField`,
        });

        this.handleChange = this.handleChange.bind(this);

        this.enableField = React.createRef();
        this.input = React.createRef();
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <>
            {this.getLabel()}
            <div className="input-group" data-rs-checkboxgroup={`${this.tabName}-${this.sectionName}-${this.field.field}`}>
                <div className="input-group-text">
                    <input className="form-check-input mt-0" 
                            type="checkbox" 
                            defaultChecked={false}
                            name={`${this.tabName}[${this.sectionName}][${this.field.field}]`} 
                            id={`${this.tabName}-${this.sectionName}-${this.field.field}-checkbox`}
                            defaultValue="Yes" 
                            ref={this.enableField}
                            aria-label="Checkbox for the following text input"/>
                </div>
                <input {...this.inputProps} ref={this.input}
                    disabled required />
                    
            </div>
            </>
        )
    }
}
export class CheckboxStacked extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        if (this.field.requireOne) {
            this.inputProps['data-rs-require-one'] = this.field.requireOne;
        }

        this.handleChange = this.handleChange.bind(this);

        this.checkbox = React.createRef();
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <>
                <p>{this.field.displayName}{this.field.required && <span className='text-danger required-ui'>*</span>}</p>
                {Object.entries(this.field.options).map((option, key) => {
                    return <div key={key.toString()} className="form-check">
                                <input {...this.inputProps}
                                        name={this.inputProps.name + option[0]}
                                        ref={this.checkbox}
                                        defaultChecked={false}
                                        onChange={this.handleChange}
                                        className={`form-check-input ${this.field.required ? " init-required": '' }`}
                                        type="checkbox" 
                                        defaultValue={option[0]}
                                        id={`${this.field.field}-${option[0]}`}
                                    />
                                <label className="form-check-label" htmlFor={`${this.field.field}-${option[0]}`} data-bs-toggle="tooltip" title={this.field.help}>
                                    {option[1]}
                                </label>
                            </div>
                })}
            </>
        )
    }
}
export class CheckboxGroupStacked extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {super(props);}

    switchFieldType = (key, lineOption, linkedField) => {
        let outputType;
        let inputAttrs = {
            key: key,
            'name': `${this.tabName}[${this.sectionName}]${this.multipleSectionKey}${this.multipleSubsectionKey}[${lineOption['field']}]`,
            'className': ` ${linkedField}-inputField ${this.field.required? " init-required": ''} `,
            'placeholder': lineOption['placeholder'] || '',
            'aria-label': lineOption['displayName'],
            'disabled': true,
            'required': lineOption['required']
        };

        if (lineOption['or']) {
            inputAttrs['data-rs-require-one'] = lineOption['or'];
        }

        switch (lineOption.inputType) {
            case UI_TYPES.TEXT:
                inputAttrs['className'] += 'form-control';
                outputType = <input {...inputAttrs} type="text"/>;
                break;
            case UI_TYPES.TEXTAREA:
                inputAttrs['className'] += 'form-control';
                outputType = <textarea {...inputAttrs}></textarea>;
                break;
            case UI_TYPES.DROPDOWN:
                inputAttrs['placeholder'] = inputAttrs['placeholder'] || 'Choose one';
                inputAttrs['className'] += 'form-select';

                outputType = <select {...inputAttrs} defaultValue={''}>
                                <option value="">Select</option>
                                {!!lineOption.options && Object.entries(lineOption.options).map((l_option, l_key) => {
                                    return <option key={l_key.toString()} value={l_option[0]}>{l_option[1]}</option>
                                })}
                            </select>
                break;
            case UI_TYPES.SPAN:
                outputType = <span key={key} className="input-group-text">{lineOption['content']}</span>;
                break;
            default:
                break;
        }

        return outputType;
    }

    render() {
        return (
            <>
                {
                this.field.displayName && this.field.displayName !== '' && 
                    <label>{this.field.displayName}
                    {this.field.required && <span className='text-danger required-ui'>*</span>}
                    </label>
                }
                
                <ol>
                    
                    {this.field.options.map((option, key) => {
                        
                        return <li key={key.toString()}>
                            <div className="input-group input-group-sm mb-3" data-rs-checkboxgroup={option['field']} title={option['help']} data-bs-toggle="tooltip">
                                <div className="input-group-text">
                                    <input className={`form-check-input mt-0 ${option['required'] ? 'init-required' : '' }`} 
                                        id={`${option['field']}-checkbox`}
                                        name={`${this.tabName}[${this.sectionName}]${this.multipleSectionKey}${this.multipleSubsectionKey}[${option['field']}]`}
                                        type="checkbox" 
                                        defaultValue={'Yes'}
                                        aria-label="Checkbox for following text input"/>
                                </div>
                                
                                {Object.entries(option['lineOptions']).map((lineOption, option_key) => {
                                    
                                    return this.switchFieldType(option_key, lineOption[1], option['field']);
                                })}
                            </div>
                        </li>
                    })}
                </ol>
            </>
        )
    }
}
export class TextArea extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
            {this.getLabel()}
            <textarea {...this.inputProps}></textarea>
            </>
        )
    }
}
export class Toggle extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.inputProps.className = `form-check-input ${this.field.required ? "init-required": ''}`;
        this.inputProps.id = this.inputName;
        this.inputProps.type = 'checkbox'; 

        this.checkRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <div className="form-check form-switch">
                <input {...this.inputProps} defaultValue={this.inputProps.defaultValue || 'Yes'} ref={this.checkRef} onChange={this.handleChange}/>
                    
                {this.getLabel()}
            </div>
        )
    }
}
export class Currency extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.inputProps.type = Constants.UI_TYPES.NUMBER;
        this.inputProps.id = this.inputName;
        this.inputProps['aria-label'] = "Amount (to the nearest dollar)"; 
        this.inputProps['min'] = this.field.min;
        this.inputProps['max'] = this.field.max;

        this.money = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <>
              {this.getLabel()}
                <div className="input-group">
                    <span className="input-group-text">$</span>
                    <input {...this.inputProps} step="100" ref={this.money} onChange={this.handleChange}/>
                    <span className="input-group-text">.00</span>
                </div>  
            </>
        )
    }
}
export class Dropdown extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);
        this.inputProps.id = this.inputName;
        delete this.inputProps['type'];
        this.dropdown = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.basicInputChange(e);
    }

    render() {
        return (
            <>
               {this.getLabel()}
                <select {...this.inputProps} className='form-select' ref={this.dropdown} onChange={this.handleChange}>
                    <option value=''>Select</option>
                    {Object.entries(this.field.options).map((option, o_key) => {
                        return <option key={o_key.toString()} value={option[0]}>{option[1]}</option>
                    })}
                </select> 
            </>
        )
    }
}
export class Combobox extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.selectInput = React.createRef()
        this.otherInput = React.createRef();

    }

    handleChange = (e) => {
        this.props.passedProps.changeHandlers.otherSelectChange(e);
    }

    render() {
        return (
            <>
                {this.getLabel()}
                {delete this.inputProps.type}
                <div className="mb-3">
                    <select {...this.inputProps} defaultValue='' ref={this.selectInput} onChange={this.handleChange}
                            className={ 'form-select other-select-input'}>
                        <option>Select</option>
                        {Object.entries(this.field.options).map((option, key) => {
                            return <option key={key.toString()} value={option[0]}>{option[1]}</option>
                        })}
                    </select>
                    
                    <input {...this.inputProps}
                            name={this.inputProps.name + 'other'} 
                            type="text"
                            defaultValue=''
                            ref={this.otherInput}
                            id={this.inputProps.id + 'other'} 
                            className="form-control hidden" disabled />
                </div>
            </>
        )
    }
}
export class Address extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);
    }

    switchOnInputType(inputField, needsKey) {
        let outputType;
        if (needsKey) {
            this.inputProps['key'] = needsKey;
        } else {
            delete this.inputProps['key'];
        }
        if (inputField['validation']) {
            this.inputProps['pattern'] = inputField['validation'];
        } else {
            delete this.inputProps['pattern'];
        }
        if (this.field.required && inputField['required']) {
            this.inputProps['required'] = true;
            this.inputProps['className'] = 'subfield init-required';
        } else {
            this.inputProps['required'] = false;
            this.inputProps['className'] = 'subfield';
        }
        switch (inputField.inputType) {
            case Constants.UI_TYPES.TEXT:
                outputType = <input {...this.inputProps} 
                                
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={this.inputProps.className +' form-control'}
                                />;
                break;
            case Constants.UI_TYPES.DROPDOWN:
                outputType = <select {...this.inputProps} 
                                required={this.field.required && inputField['required']}
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={this.inputProps.className + ' form-select'}>
                                
                                <option value="">Select {inputField.displayName}</option>
                                {Object.entries(inputField.options).map((option, key) => {
                                    return <option key={key.toString()} value={option[0]}>{option[1]}</option>
                                })}

                            </select>;
                break;
            default:
                break;
        }
        return outputType;
    }

    render() {
        return (
            <>
                {this.getLabel()}
                {Constants.addressFields.map((inputField, key) => {
                    return this.switchOnInputType(inputField, key.toString());
                })}
                {
                    this.tabName === 'business_info' && this.field.field === 'ship_to_address' && 
                // <!-- Hard coded 'goto' button :( -->
                    <button type="button"
                        id="goToShipToButton"
                        data-rs-tab="1" 
                        className="btn btn-secondary rounded-0" 
                        aria-label="Click to add more shipping addresses">Add More Ship-To's</button>
                }
            </>
        )
    }
}
export class AddressOneLine extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);
    }

    switchOnInputType(inputField, needsKey) {
        let outputType;
        if (needsKey) {
            this.inputProps['key'] = needsKey;
        } else {
            delete this.inputProps['key'];
        }
        if (inputField['validation']) {
            this.inputProps['pattern'] = inputField['validation'];
        } else {
            delete this.inputProps['pattern'];
        }
        switch (inputField.inputType) {
            case Constants.UI_TYPES.TEXT:
                outputType = <input {...this.inputProps} 
                                required={this.field.required && inputField['required']}
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={this.inputProps.className + ' subfield'}
                                />;
                break;
            case Constants.UI_TYPES.DROPDOWN:
                outputType = <select {...this.inputProps} 
                                required={this.field.required && inputField['required']}
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={ this.inputProps.className + ' form-select subfield'}>
                                
                                <option value="">Select {inputField.displayName}</option>
                                {Object.entries(inputField.options).map((option, key) => {
                                    return <option key={key.toString()} value={option[0]}>{option[1]}</option>
                                })}

                            </select>;
                break;
            default:
                break;
        }
        return outputType;
    }

    render() {
        return (
            <>
                <label htmlFor="">{this.field.displayName}{this.field.required && <span className='text-danger required-ui'>*</span>}</label>
                <div className="input-group">
                    {Constants.addressFields.map((field, key) => {
                        let rKey = key.toString();
                        return this.switchOnInputType(field, rKey);
                    })}
                </div>
            </>
        )
    }
}
export class FullContact extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);
    }

    switchOnInputType(inputField, needsKey) {
        let outputType;
        if (needsKey) {
            this.inputProps['key'] = needsKey;
        } else {
            delete this.inputProps['key'];
        }
        if (inputField['validation']) {
            this.inputProps['pattern'] = inputField['validation'];
        } else {
            delete this.inputProps['pattern'];
        }
        switch (inputField.inputType) {
            case Constants.UI_TYPES.EMAIL:
            case Constants.UI_TYPES.PHONE:
            case Constants.UI_TYPES.TEXT:
                outputType = <input {...this.inputProps}
                                type={inputField.inputType}
                                required={this.field.required && inputField['required']}
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={this.inputProps.className + ' subfield'}
                                />;
                break;
            case Constants.UI_TYPES.DROPDOWN:
                outputType = <select {...this.inputProps} 
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                required={this.field.required && inputField['required']}
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={ 'form-select subfield'}>
                                
                                <option value="">Select</option>
                                {Object.entries(inputField.options).map((option, key) => {
                                    return <option key={key.toString()} value={option[0]}>{option[1]}</option>
                                })}

                            </select>;
                break;
            default:
                break;
        }
        return outputType;
    }

    attachIconOrNot(field, key) {
        if (Constants.iconFields.includes(field.inputType)) {
            return <InputFieldIcon key={key} fieldType={field.inputType}>
                        {this.switchOnInputType(field, false)}
                    </InputFieldIcon>
        } else {
            return this.switchOnInputType(field, key);
        }
    }

    render() {
        return (
            <>
                {this.getLabel()}
                {Constants.contactFields.map((field, key) => {
                    let rKey = key.toString();
                    return this.attachIconOrNot(field, rKey);
                })}
            </>
        )
    }
}
export class MinContact extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);
    }

    switchOnInputType(inputField, needsKey) {
        let outputType;
        if (needsKey) {
            this.inputProps['key'] = needsKey;
        } else {
            delete this.inputProps['key'];
        }
        if (inputField['validation']) {
            this.inputProps['pattern'] = inputField['validation'];
        } else {
            delete this.inputProps['pattern'];
        }
        switch (inputField.inputType) {
            case Constants.UI_TYPES.EMAIL:
            case Constants.UI_TYPES.PHONE:
            case Constants.UI_TYPES.TEXT:
                outputType = <input {...this.inputProps}
                                type={inputField.inputType}
                                required={this.field.required && inputField['required']}
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={this.inputProps.className + ' subfield'}
                                />;
                break;
            case Constants.UI_TYPES.DROPDOWN:
                outputType = <select {...this.inputProps} 
                                id={`${this.inputProps.id}[${inputField['name']}]`} 
                                placeholder={inputField['placeholder'] ? inputField['placeholder'] : inputField['displayName']}
                                name={`${this.inputProps.name}[${inputField['name']}]`} 
                                className={ 'form-select subfield'}>
                                
                                <option value="">Select</option>
                                {Object.entries(inputField.options).map((option, key) => {
                                    return <option key={key.toString()} value={option[0]}>{option[1]}</option>
                                })}

                            </select>;
                break;
            default:
                break;
        }
        return outputType;
    }

    attachIconOrNot(field, key) {
        if (Constants.iconFields.includes(field.inputType)) {
            return <InputFieldIcon key={key}v fieldType={field.inputType}>
                        {this.switchOnInputType(field, false)}
                    </InputFieldIcon>
        } else {
            return this.switchOnInputType(field, key);
        }
    }

    render() {
        return (
            <>
                {this.getLabel()}
                {Constants.minContactFields.map((field, key) => {
                    let rkey=key.toString();
                    return this.attachIconOrNot(field, rkey);
                })}
            </>
        )
    }
}
export class FileField extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.getLabel()}
                <input {...this.inputProps} type="file" 
                    name={this.field.field} multiple/>
            </>
        )
    }
}

export class SignatureCanvas extends ParentFieldComponent {
    // eslint-disable-next-line
    constructor (props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.getLabel()}
                <input {...this.inputProps} type="hidden" 
                    name={this.inputProps.id}/>
                <canvas className='sig-canvas' data-rs-sig-input={this.inputProps.id}></canvas>
            </>
        )
    }
}

export function getFieldComponent(type, props) {
    
    if (Constants.simpleInputTypes.includes(type)) {
        return <BasicInput passedProps={props}/>;
    } else if (Constants.simpleOutputTypes.includes(type)) {
        switch (type) {
            case Constants.UI_TYPES.PARAGRAPH:
                return <Paragraph passedProps={props}/>;
            case Constants.UI_TYPES.SEPARATOR:
                return <Separator passedProps={props}/>;
            case Constants.UI_TYPES.ORDERED_LIST:
                return <OrderedList passedProps={props}/>;
            default:
                break;
        }
        if (['h1', 'h2', 'h3', 'h4','h5', 'h6'].includes(type)) {
            return <Heading passedProps={props}/>;
        }
    } else if (type === Constants.UI_TYPES.CHECKBOX) {
        return  <BasicCheckbox passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.SIGNATURE_CANVAS) {
        return  <SignatureCanvas passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.RADIO) {
        return  <BasicRadio passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.CHECKBOX_GROUP) {
        return  <CheckboxGroup passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.CHECKBOX_STACKED) {
        return  <CheckboxStacked passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.RADIO_STACKED) {
        return  <RadioStacked passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.CHECKBOX_GROUP_STACKED) {
        return  <CheckboxGroupStacked passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.TEXTAREA) {
        return  <TextArea passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.TOGGLE) {
        return  <Toggle passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.CURRENCY) {
        return  <Currency passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.DROPDOWN) {
        return  <Dropdown passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.COMBOBOX) {
        return  <Combobox passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.ADDRESS) {
        return  <Address passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.ADDRESS_1_LINE) {
        return  <AddressOneLine passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.FULL_CONTACT) {
        return <FullContact passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.MIN_CONTACT) {
        return <MinContact passedProps={props}/>;
    } else if (type === Constants.UI_TYPES.FILE) {
        return <FileField passedProps={props}/>;
    } else {
        return <BasicInput passedProps={props}/>;
    }
}

let FieldTypes = {mapFieldType: getFieldComponent,HelpIcon, InputFieldIcon, Paragraph, OrderedList, Heading, Separator, HiddenInput, BasicInput, BasicSelect, BasicCheckbox, BasicRadio};
export default FieldTypes;