import React, {Component} from 'react';
import { Modal, Button, ButtonGroup, Toast } from 'react-bootstrap';
import {CustomTabContainer} from './new-customer-form-components';
import {tabs, addressFields, contactFields} from './new-customer-form-constants';
import {NewCustomerFormMethods} from './new-customer-form-interactive';
import RestoreFormModal from './restore-form-modal';
import SaveTempModal from './save-temp-form-modal';
import AdditionalContactModal from './contact-form';

export default class NewCustomerFormModal extends Component {
    methods;
    customerForm;

    constructor() {
        super();
        this.methods = new NewCustomerFormMethods();
        this.state = {
            showCustomerForm: false,
            showRestoreForm: false,
            showSaveForm: false,
            showContactForm: false,
            newChanges: false,
            isSubmitting: false,
            isSaving: false,
            isRestoring: false,
            toastHeader: '', toastMessage: '', showToast: false
        };

        this.closeNewCustomerModal = this.closeNewCustomerModal.bind(this);
        this.attachBasicInputChange = this.attachBasicInputChange.bind(this);
        
        this.attachToggleFirstChange = this.attachToggleFirstChange.bind(this);
        this.attachOtherSelectChange = this.attachOtherSelectChange.bind(this);
        this.attachOtherSelectInputChange = this.attachOtherSelectInputChange.bind(this);

        this.removeSectionHandler = this.removeSectionHandler.bind(this);
        this.addSectionHandler = this.addAccordionHandler.bind(this);
        this.removeAccordionHandler = this.removeAccordionHandler.bind(this);
        this.addAccordionHandler = this.addAccordionHandler.bind(this);
        this.removeFieldHandler = this.removeFieldHandler.bind(this);
        this.addFieldHandler = this.addFieldHandler.bind(this);
    }

    componentDidUpdate = () => {
        this.customerForm = document.getElementById('newCustomerWebForm');
    }

    onSaveTempData = () => {
        let customerForm = document.getElementById('newCustomerWebForm');
        this.openSaveTempModal(customerForm);
    }

    onSubmit = () => {
        if (!this.customerForm.checkValidity()) {
            this.customerForm.classList.add('was-validated');
        } else {
            this.setState({isSubmitting: true, showContactForm: true});
        }
    }

    onEntered = () => {
        this.methods.initialize();
        this.customerForm = document.getElementById('newCustomerWebForm');
    }

    /**
     * Run checkValidity on given form and display bootstrap styling
     * @param {HTML} form 
     * @returns 
     */
     validateForm = (form) => {
        let isValid = false;
        
        if (form.checkValidity()) {
            isValid = true;
        }
        // for Bootstrap visual validation
        form.classList.add('was-validated');
        return isValid;
    }

    attachBasicInputChange = (e) => { 
        e.target.focus();
        this.methods.checkDependencies(e);
        this.methods.checkRequiredIfs();
        this.methods.checkRequireOnes();
        
        let closestTabContent = e.target.closest('.tab-pane');
        if (closestTabContent) {
            let closestTabBtn = this.customerForm.querySelector('#'+closestTabContent.getAttribute('aria-labelledby'));
            this.methods.validateTab(closestTabBtn, closestTabContent, true);
        }

    }

    attachToggleFirstChange = (e) => {
        this.methods.checkDependencies(e);
        let checkbox_groups = document.querySelectorAll('[data-rs-checkboxgroup]');
        this.methods.assignCheckboxGroups(checkbox_groups);
    }

    attachOtherSelectChange = (e) => {
        this.methods.checkDependencies(e); this.methods.selectUseInput(e);
    }

    removeAccordionHandler = (e) => {this.methods.removeAccordion(e);}

    addAccordionHandler = (e) => {this.methods.addAccordionSection(e);}

    removeSectionHandler = (e) => {this.methods.removeSection(e);}

    addSectionHandler = (e) => {this.methods.addSection(e);}

    removeFieldHandler = (e) => {this.methods.removeField(e);}

    addFieldHandler = (e) => {this.methods.addField(e);}

    attachOtherSelectInputChange = (e) => {this.methods.checkDependencies(e); this.methods.selectUseInput(e);}

    /**
     * Populate a form with local values
     * @param {*} event 
     * @returns 
     */
    restoreFormData = (storedInfo) => {
        return new Promise((resolve, reject) => {
            if (storedInfo) {
                try {
                    this.populateForm(this.customerForm, storedInfo);
                    setTimeout(() => {
                        this.displayToast('Success', 'Successfully retrieved your saved information.');
                    }, 500);
                    resolve();
                } catch (e) {
                    this.displayToast('Fetch Saved Info Error', 'There was a problem retrieving the saved form information');
                    console.error(e);
                    reject();
                }
            } else {
                this.displayToast('Info','Could not find any saved form information with the information you supplied');
                resolve();
            }
        });
    }

    /**
     * Fill in the form with FormData, compared against the form structure
     * @param {HTMLFormElement} form 
     * @param {Object} data 
     */
    populateForm = (form, data) => {
        const entries = Object.entries(data);
        
        for(const [key, val] of entries) {
            let input = form.elements[key];
            
            if (input) {
                try {
                    if (input.tagName == 'INPUT') {
                        switch(input.type) {
                            case 'file':
                                break;
                            case 'tel':
                            case 'email':
                            case 'text':
                                input.value = val;
                                input.dispatchEvent(new Event('change'));
                                break;
                            case 'checkbox':
                            case 'radio':
                                input.checked = !!val;
                                input.dispatchEvent(new Event('click'));
                                break;
                            default :
                                input.value = val;
                                input.dispatchEvent(new Event('change'));
                                break;
                        }
                    } else if (input.tagName ==='TEXTAREA'){
                        input.value = val;
                        input.dispatchEvent(new Event('input'));
                    } else if (input.tagName == 'SELECT') {
                        input.value = val;
                        input.dispatchEvent(new Event('change'));
                    }
                    
                } catch (e) {
                    console.error(e);
                    console.error('INPUT target:', input);
                }
                
            }
            
        }
    }

    /**
     * Handle output from Restore Form Modal
     * Takes a json object and passed it to the New Customer Form method 
     * Closes the RestoreFormModal on success.
     * @param {JSON} formData 
     */
    restoreNewCustomerData = (formData) => {
        this.restoreFormData(formData)
            .then(() => this.closeRestoreModal())
            .catch(e => console.error(e));
    }

    /**
     * Open the modal to save formData
     * @param {JSON} formData 
     */
    openSaveTempModal = (formData) => {
        if (formData) {
            this.newCustomerWebForm = this.serializeForm(formData);
            this.setState({showSaveForm: true});
        }
    }

    openRestoreModal = (e) => {this.setState({showRestoreForm: true});}

    /**
     * Convenience method for converting the form to a parsable array of values.
     */
    serializeForm = (form) => {
        let obj = {};
        let formData = new FormData(form);
        for (var key of formData.keys()) {
            obj[key] = formData.get(key);
        }
        return obj;
    };

    /**
     * When the contact form 'submits', close that modal and call newCustomerForm method 'submitNewCustomer' with
     * the data from the contact form.
     * @param {JSON} contactInfo 
     * @returns 
     */
    handleContactFormSubmit = (contactInfo) => {
        var fieldNames = this.getFieldNames();

        return this.methods.submitNewCustomer(contactInfo, fieldNames)
            .then((result) => {
                this.setState({isSubmitting: false});
                this.displayToast(result[0], result[1]); 
                this.closeNewCustomerModal();
                this.closeContactForm();
                return true;
            })
            .catch(e => {
                console.error(e);
                this.displayToast(e[0], e[1]);
                return false;
            });
    }

    getFieldNames = () => {
        var fieldNames = [];
        tabs.forEach(t => {
            fieldNames.push({
                name: t.name,
                displayName: t.displayName
            });
    
            if (t.sections && t.sections.length > 0) {
                fieldNames = this.getSections(t.sections, fieldNames);
            }
            
        });
        addressFields.forEach(t => {
            fieldNames.push({
                name: t.name,
                displayName: t.displayName
            });
        });
        contactFields.forEach(t => {
            fieldNames.push({
                name: t.name,
                displayName: t.displayName
            });
        });
        return fieldNames;
    }
    
    getSections = (sections, fieldNames = []) => {
        sections.forEach(s => {
            fieldNames.push({
                name: s.name,
                displayName: s.displayName
            });
    
            if (s.fields && s.fields.length > 0) {
                fieldNames = this.getFields(s.fields, fieldNames);
            }
        });
    
        return fieldNames;
    }
    
    getFields = (fields, fieldNames = []) => {
        fields.forEach(f => {
            fieldNames.push({
                name: f.field,
                displayName: f.displayName
            });
    
            if (f.subsections && f.subsections.length > 0) {
                fieldNames = this.getSections(f.subsections, fieldNames);
            }
        });
        return fieldNames
    }

    closeNewCustomerModal = () => {this.props.onClose();}

    closeContactForm = () => {this.setState({showContactForm: false});}

    closeTempSaveForm = () => {this.setState({showSaveForm: false});}

    closeRestoreModal = () => {this.setState({showRestoreForm: false});}

    displayToast = (title, message) => {
        console.log(title, message);

        this.setState({toastHeader: title, toastMessage: message, showToast: true});
        setTimeout(()=>{
            this.setState({showToast: false, toastHeader:'', toastMessage: ''});
        },5000);
    }

    changeHandlers = {
        basicInputChange: this.attachBasicInputChange,
        toggleFirstChange: this.attachToggleFirstChange,
        otherSelectChange: this.attachOtherSelectChange,
        otherSelectInput: this.attachOtherSelectInputChange,
        removeSectionHandler: this.removeSectionHandler,
        addSectionHandler: this.addSectionHandler,
        removeAccordionHandler: this.removeAccordionHandler,
        addAccordionHandler: this.addAccordionHandler,
        removeFieldHandler: this.removeFieldHandler,
        addFieldHandler: this.addFieldHandler
    }

    render() {
        return (
            <>
                <Modal
                    centered
                    size='xl'
                    show={this.props.show}
                    onHide={this.closeNewCustomerModal}
                    onEntered={this.onEntered}
                    backdrop="static"
                    keyboard={false}
                    scrollable={true}
                    className="rounded-0"
                    id="newCustomerApplicationModal">
                    <Modal.Header closeButton>
                        <Modal.Title title="If you're having trouble completing the application, please email customerservice@rsnortheast.com and we will assist you as best we can.">
                            New Customer Application&nbsp;
                            <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Help:"><use xlinkHref="#question-circle"/></svg>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form id="newCustomerWebForm" name="newCustomerWebForm" className="needs-validation" method="post" encType="multipart/form-data" noValidate>
                            <CustomTabContainer 
                                tabs={tabs} 
                                methods={this.methods} 
                                changeHandlers={this.changeHandlers}/>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeNewCustomerModal} disabled={this.state.isSubmitting}>
                            Cancel
                        </Button>

                        <ButtonGroup aria-label='PreviousNext Section'>
                            <Button id="prevSectionButton" variant='secondary' onClick={this.methods.handlePrevNext} data-rs-next-section="0" aria-label="Previous Section">Prev</Button>
                            <Button id="nextSectionButton" variant='secondary' onClick={this.methods.handlePrevNext} data-rs-next-section="1" aria-label="Next Section">Next</Button>
                        </ButtonGroup>

                        <Button variant='success' onClick={this.onSaveTempData} disabled={this.state.isSubmitting} title='Save & Continue Later' aria-label='Save & Continue Later'><i className="far fa-save"></i></Button>
                        <Button variant='info' 
                            onClick={this.openRestoreModal} 
                            id="restoreFormButton" 
                            className='hidden'
                            disabled={this.state.isSubmitting} 
                            title='Restore saved form' 
                            aria-label='Restore saved form'>
                                <i className="fas fa-clipboard-list"></i>
                        </Button>

                        <Button
                            variant="primary"
                            disabled={this.state.isSubmitting}
                            onClick={!this.state.isSubmitting ? this.onSubmit : null}
                            >
                            {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                        
                    </Modal.Footer>
                </Modal>

                <AdditionalContactModal 
                show={this.state.showContactForm} 
                onSubmit={this.handleContactFormSubmit} 
                onClose={this.closeContactForm} />

                <SaveTempModal 
                isSaving={this.state.isSaving}
                show={this.state.showSaveForm} 
                displayToast={this.displayToast}
                onSuccess={this.closeTempSaveForm}
                newCustomerWebForm={this.newCustomerWebForm}
                onClose={this.closeTempSaveForm} />

                <RestoreFormModal 
                isRestoring={this.isRestoring}
                show={this.state.showRestoreForm} 
                onSuccess={this.restoreNewCustomerData} 
                displayToast={this.displayToast}
                onClose={this.closeRestoreModal}/>

                <Toast show={this.state.showToast} position='bottom-end'>
                    <Toast.Header>
                    {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
                    <strong className="me-auto">{this.state.toastHeader}</strong>
                    <small className="text-muted"></small>
                    </Toast.Header>
                    <Toast.Body>{this.state.toastMessage}</Toast.Body>
                </Toast>
            </>
        )
    }
}