import React, { Component } from 'react';

import facebookLogo from '../../assets/images/icons/facebookCircle.svg';
import twitterLogo from '../../assets/images/icons/twitterCircle.svg';
import linkedinLogo from '../../assets/images/icons/linkedInCircle.svg';
import instagramLogo from '../../assets/images/icons/instagramCircle.svg';
import { Col, Row } from 'react-bootstrap';

export default class SocialMedia extends Component {
  render() {
    return (
      <Row>
        <Col className="text-white">
          <div className="socialMediaIcons text-white">
            <a
              href="https://www.facebook.com/AvKARE/"
              target="blank"
              rel="noopener nofollow"
            >
              <img src={facebookLogo} alt="Facebook" />
            </a>
            <a
              href="https://twitter.com/avkare_llc?lang=en"
              target="blank"
              rel="noopener nofollow"
            >
              <img src={twitterLogo} alt="Twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/avkare-llc/"
              target="blank"
              rel="noopener nofollow"
            >
              <img src={linkedinLogo} alt="LinkedIn" />
            </a>
            <a
              href="https://www.instagram.com/AvKARE_LLC/"
              target="blank"
              rel="noopener nofollow"
            >
              <img src={instagramLogo} alt="Instagram" />
            </a>
          </div>
        </Col>
      </Row>
    );
  }
}
