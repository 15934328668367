import React, { Component } from 'react';

import factoryImage from '../../assets/images/banners/factory.jpg';
import warehouseImage from '../../assets/images/banners/warehouse.jpg';

import LogisticsPDF from '../../assets/pdfs/YourLogisticsPartner-flyer.pdf';

import '../../css/WhoWeAre.css';

export default class WhoWeAre extends Component {
  componentDidMount() {
    document.title = 'AvKARE | Who We Are';
  }

  render() {
    return (
      <div className="page" id="whoWeAre">
        <div className="row" id="introduction">
          <div className="col-12  mb-5 mt-5">
            <div className="width mx-auto">
              <p>
                <span className="h3 textRed semiBold d-block">Who We Are</span>
                AvKARE is your complete and trusted healthcare distributor of
                Pharmaceuticals and Infection Control products. We are a family
                oriented organization and believe wholeheartedly that our people
                drive the success of our company. With these beliefs in mind,
                and with eyes focused always on our goal of supporting our
                customers, AvKARE continues to grow into the thriving company it
                is today.
              </p>
            </div>
          </div>
        </div>
        <div className="row colorBg" id="products">
          <div className="col-12 col-md-6 p-0">
            <img className="w-100 h-100" src={factoryImage} alt="Factory" />
          </div>
          <div className="col-12 col-md-6 text-white my-auto">
            <div className="box mx-auto">
              <p>
                <span className="h4 text-uppercase semiBold d-block">
                  Products
                </span>
                AvKARE's sizeable portfolio of products, available on contract
                and open market, are designed to supply our customers with the
                most diverse and cost-effective options. Contact AvKARE to
                source your patient-care and pharmaceutical items!
              </p>
              <a
                href="/contact"
                rel="noopener noreferrer"
                className="btn btnCustom text-uppercase"
              >
                Contact a Sales Rep
              </a>
            </div>
          </div>
        </div>
        <div className="row colorBg" id="valuedCustomers">
          <div className="col-12  mb-5 mt-5">
            <div className="width mx-auto">
              <p>
                <span className="h3 textRed semiBold d-block">
                  Our Valued Customers
                </span>
                AvKARE is proud and honored to be a trusted supplier to United
                States governmental entities including:
              </p>
              <ul className="font-weight-bold">
                <li>Department of Defense (DoD)</li>
                <li>Food & Drug Administration (FDA)</li>
                <li>Veterans Health Affairs (VHA)</li>
                <li>Indian Health Services (IHS)</li>
                <li>Health & Human Services (HHS)</li>
                <li>State and Local Departments</li>
              </ul>
              <p>
                In addition to supporting our governmental agencies, AvKARE's
                clientele includes commerical partners and vendors across the
                globe. AvKARE provides infection control products and
                pharmaceuticals to many commercial facilities. AvKARE strives to
                provide our respected commercial customers with high quality and
                competitively priced products.
              </p>
            </div>
          </div>
        </div>
        <div className="row colorBg" id="logistics">
          <div className="col-12 col-md-6 order-12 order-md-1 text-white my-auto">
            <div className="box mx-auto">
              <p>
                <span className="h4 text-uppercase semiBold d-block">
                  Logistics
                </span>
                AvKARE recognizes that to our customers, time is money! With
                warehouses strategicially located across the continental United
                States, AvKARE offers a comprehensive streamline approach to our
                distributation network. Providing timely delivery services to
                our valued customers is our promise to you.
              </p>
              <a
                href={LogisticsPDF}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btnCustom text-uppercase"
              >
                Logistics PDF
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-12 p-0">
            <img className="w-100 h-100" src={warehouseImage} alt="Warehouse" />
          </div>
        </div>
        <div className="row" id="commitment">
          <div className="col-12 mt-5 mb-5">
            <div className="width mx-auto">
              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="h3 textRed semiBold breakWord d-block">
                      Special Manufacturer/Wholesaler/Distributor Licenses
                    </span>
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <ul>
                    <li>Tennessee Board of Pharmacy #0000002633</li>
                    <li>
                      FDA-Registration/Labeler Codes #42291, #50268, #73190
                    </li>
                    <li>
                      DEA-Controlled Substance (2, 3, 3M, 4, 5) Distributor
                      #RA0397908
                    </li>
                    <li>Cage Code: 4QGW8</li>
                    <li>TINS: 20-8622803</li>
                    <li>DUNS: 796560394 (AvKARE)</li>
                    <li>DUNS: 832926666 (AvPAK)</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <ul>
                    <li>FSS Ph 36F79723D0144</li>
                    <li>DAPA Ph SP0200-08-H-0016</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
