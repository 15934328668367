import React, { Component } from 'react';

export default class Header extends Component {
  render() {
    return (
      <div className="row" id="hero">
        <div className="card square header text-white h-100">
          <div className="image card-img">
            <div className="card-img-overlay overlay">
              <div className="my-auto">
                <h1 className="georgiaFont animation">
                  Your Trusted Industry Source for Pharmaceuticals and Infection
                  Control Products.
                </h1>
                <p className="callToAction card-text text-uppercase animation">
                  <span>
                    Let us help you find solutions to enhance your business.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
