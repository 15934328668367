import React, { Component } from 'react';

import film from '../../assets/video/vid3.mp4';

export default class Film extends Component {
  componentDidMount() {
    document.title = 'AvKARE | Film';
  }

  render() {
    return (
      <div className="page">
        <div className="row">
          <div className="col-12">
            {/* I have been informed that we do not have a MURICAN version of this video. - Cassie*/}
            <video width="100%" controls>
              <source src={film} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  }
}
