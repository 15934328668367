import React, { Component } from 'react';
import PharmaCatServices from '../../../services/pharmacat-services';

import '../../../../css/Pharma.css';

export default class PharmaCatalog extends Component {
  constructor() {
    super();
    this.state = {
      pharmaCatalog: [],
      query: '',
      show: 'ndc',
      asc: {
        ndc: true,
        name: true,
        strength: true,
        unitsize: true,
        dosageform: true
      }
    };
  }

  showItem = did => e => {
    window.location.href = `/products/pharma/${did}`;
  };

  setQuery = e => {
    this.setState({ query: e.target.value });
  };

  filterItems = item => {
    //Search NDC, Generic Name, or Brand Name
    var found = false;

    if (item.ndc && item.name) {
      var search = this.state.query.toLowerCase();
      var ndc = item.ndc.toLowerCase();
      var name = item.name.toLowerCase();

      if (ndc.includes(search)) {
        found = true;
      } else if (name.includes(search)) {
        found = true;
      } else if (item.brandref != null) {
        var brandref = item.brandref.toLowerCase();
        if (brandref.includes(search)) {
          found = true;
        }
      }
    }

    return found;
  };

  sortItems = sortType => {
    var asc = { ...this.state.asc };
    this.setState({ show: sortType });

    //grab data
    const data = this.state.pharmaCatalog;

    //descend or ascend
    if (asc[sortType] === false) {
      data.sort((a, b) =>
        b[sortType]
          .toString()
          .localeCompare(a[sortType].toString(), undefined, {
            numeric: true,
            ignorePunctuation: true
          })
      );
    } else {
      data.sort((a, b) =>
        a[sortType]
          .toString()
          .localeCompare(b[sortType].toString(), undefined, {
            numeric: true,
            ignorePunctuation: true
          })
      );
    }

    //update table
    this.setState({ data });

    //change descending or ascending carets for respective table header
    asc[sortType] = !asc[sortType];
    this.setState({ asc });
  };

  componentDidMount() {
    document.title = 'AvKARE | Pharma Catalog';
    PharmaCatServices.catalogGet().then(response => {
      this.setState({ pharmaCatalog: response.data });
      this.sortItems('ndc');
    });
  }

  render() {
    return (
      <div className="page" id="pharmaCatalog">
        <div className="row">
          <div className="col-12">
            <h1 className="georgiaFont font-italic text-center p-5">
              Pharma Catalog
            </h1>
            <div className="width mx-auto">
              <div className="input-group mb-5">
                <span className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-search" />
                  </span>
                </span>
                <input
                  id="search"
                  type="text"
                  className="form-control"
                  placeholder="Search NDC, Generic Name, or Brand Name"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={this.state.query || ''}
                  onChange={e => this.setQuery(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <table className="table table-striped table-hover mx-auto">
              <thead>
                <tr>
                  <th onClick={sortType => this.sortItems('ndc')}>
                    NDC{' '}
                    <span
                      className={
                        (this.state.asc.ndc
                          ? 'fas fa-caret-down'
                          : 'fas fa-caret-up') +
                        (this.state.show === 'ndc' ? '' : 'd-none')
                      }
                    />
                  </th>
                  <th onClick={sortType => this.sortItems('name')}>
                    Name{' '}
                    <span
                      className={
                        (this.state.asc.name
                          ? 'fas fa-caret-down'
                          : 'fas fa-caret-up') +
                        (this.state.show === 'name' ? '' : 'd-none')
                      }
                    />
                  </th>
                  <th onClick={sortType => this.sortItems('strength')}>
                    Strength{' '}
                    <span
                      className={
                        (this.state.asc.strength
                          ? 'fas fa-caret-down'
                          : 'fas fa-caret-up') +
                        (this.state.show === 'strength' ? '' : 'd-none')
                      }
                    />
                  </th>
                  <th onClick={sortType => this.sortItems('unitsize')}>
                    Count{' '}
                    <span
                      className={
                        (this.state.asc.unitsize
                          ? 'fas fa-caret-down'
                          : 'fas fa-caret-up') +
                        (this.state.show === 'unitsize' ? '' : 'd-none')
                      }
                    />
                  </th>
                  <th onClick={sortType => this.sortItems('dosageform')}>
                    Form{' '}
                    <span
                      className={
                        (this.state.asc.dosageform
                          ? 'fas fa-caret-down'
                          : 'fas fa-caret-up') +
                        (this.state.show === 'dosageform' ? '' : 'd-none')
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.pharmaCatalog &&
                  this.state.pharmaCatalog
                    .filter(item => this.filterItems(item))
                    .map((item, i) => {
                      return (
                        <tr key={i} onClick={this.showItem(item.did)}>
                          <td>{item.ndc}</td>
                          <td>{item.name}</td>
                          <td>{item.strength}</td>
                          <td>{item.unitsize}</td>
                          <td>{item.dosageform}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
