import React, {Component} from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

export default class AdditionalContactModal extends Component {
    _contactForm;

    constructor() {
        super();
        this.closeContactForm = this.closeContactForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.displayToast = this.displayToast.bind(this);
        this.state = {isSubmitting: false};
    }

    closeContactForm = () => {
        this.props.onClose();
        this.setState({isSubmitting: false});
    }

    displayToast = (title, message) => {
        this.props.displayToast(title, message);
    }

    get contactForm () {
        this.contactForm = document.getElementById('additionalContactForm');
        return this._contactForm;
    }
    set contactForm(el) {
        this._contactForm = el;
    }

    onSubmit = ()=> {
        this.setState({isSubmitting: true});
        if (this.validateForm(this.contactForm)) {
            this.props.onSubmit(new FormData(this.contactForm))
                .then((result) => this.setState({isSubmitting: false}));
        } else {
            this.displayToast('Incomplete Form', 'Please check for missing or invalid values');
            this.setState({isSubmitting: false});
        }
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit();
        }
    }

    onEntered = () => {
        let nameField = this.contactForm.querySelector('#submitName');
        if (nameField) {
            nameField.focus();
        }
    }

    validateForm = (form) => {
        let isValid = false;
        
        // validate customerform
        if (form.checkValidity()) {
            isValid = true;
        }
        // for Bootstrap visual validation
        form.classList.add('was-validated');
        return isValid;
    }

    render() {

        return (
            <Modal 
                centered
                size='lg'
                show={this.props.show}
                onEntered={this.onEntered}
                onKeyDown={this.onKeyDown}
                onHide={this.closeContactForm}
                id="newCustomerSubmitContact" tabIndex="-1">
                    <Modal.Header closeButton>
                        <Modal.Title>Applicant Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="fst-italic">Your application is ready to be sent.</p>
                        <p>Please provide some contact information so that Customer Service may get in touch with you.</p>
                        <Form id="additionalContactForm">
                            <Row className="mb-3">
                                <Col>
                                    <label htmlFor="submitName">Name&nbsp;<span className='text-danger'>*</span>
                                        <input type="text" className="form-control" id="submitName" name="submitName" required />
                                    </label>
                                </Col>
                                <Col>
                                    <label htmlFor="submitEmail">Email&nbsp;<span className='text-danger'>*</span>
                                        <input type="email" className="form-control" id="submitEmail" name="submitEmail" required />
                                    </label>
                                </Col>
                                <Col>
                                    <label htmlFor="submitPhone">Phone (optional)
                                        <input type="tel" className="form-control" id="submitPhone" name="submitPhone" />
                                    </label>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' disabled={this.props.isSubmitting} onClick={this.closeContactForm}>Cancel</Button>
                        <Button variant='primary' id="saveNewCustomer" onClick={this.onSubmit} disabled={this.state.isSubmitting}>
                        {this.state.isSubmitting ? 'Submitting...' : 'Continue'}
                        </Button>
                    </Modal.Footer>
            </Modal>
        )
    }
}