import React, { Component } from 'react';

import PhoneIcon from '../../assets/images/icons/phoneCircle.svg';
import PillIcon from '../../assets/images/icons/pillCircle.svg';
import AlertIcon from '../../assets/images/icons/alertCircle.svg';

import '../../css/ConsumerInfo.css';

export default class ConsumerInfo extends Component {
  componentDidMount() {
    document.title = 'AvKARE | Drug Safety and Consumer Information';
  }

  render() {
    return (
      <div className="page" id="consumerInfo">
        <div className="width mx-auto">
          <div className="row pt-5">
            <div className="col-12 introText grayText text-center">
              <div className="mx-auto">
                <h1 className="georgiaFont font-italic">Drug Safety and Consumer Information</h1>
                <p>
                  Safety is our highest priority. We are committed to providing products that
                  support the health and security of those that we serve.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <div className="row">
                <div className="col-12 col-md-4 text-center">
                  <img src={PhoneIcon} className="icon" alt="" />
                  <p className="navyText">
                    <strong>
                      For emergencies needing prompt medical attention - please contact{' '}
                      <span className="phone">911</span> immediately.
                    </strong>
                  </p>
                </div>
                <div className="col-12 col-md-4 text-center">
                  <img src={PillIcon} className="icon" alt="" />
                  <p className="navyText">
                    <strong>
                      For prescribing information of pharmaceuticals, please contact your health
                      provider.
                    </strong>
                  </p>
                </div>
                <div className="col-12 col-md-4 text-center">
                  <img src={AlertIcon} className="icon" alt="" />
                  <p className="navyText">
                    <strong>
                      To report Adverse Events, share concerns, or ask general product questions,
                      please call us at{' '}
                      <a href="tel:855-361-3993" className="phone">
                        855.361.3993
                      </a>{' '}
                      or email <a href="mailto:DrugSafety@avkare.com">drugsafety@avkare.com</a>.
                    </strong>
                  </p>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-12">
                  <p className="text-center outroText">
                    <strong>
                      For more information concerning product related negative effects reporting,
                      visit{' '}
                      <a href="https://www.fda.gov/" target="_blank" rel="noopener noreferrer">
                        fda.gov
                      </a>.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 d-none d-lg-block pillStackBg" />
          </div>
        </div>
      </div>
    );
  }
}
