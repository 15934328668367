import React, { Component } from 'react';

export default class Form extends Component {
  render() {
    return (
      <form action="/contact" method="post" id="form">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="name">Name *</label>
              <input
                name="name"
                type="text"
                className="form-control"
                id="name"
                autoComplete="name"
                placeholder="John Doe"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="email">Email Address *</label>
              <input
                name="email"
                type="email"
                className="form-control"
                id="email"
                autoComplete="email"
                placeholder="example@email.com"
                required
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="phone">Phone *</label>
              <input
                name="phone"
                type="tel"
                className="form-control"
                id="phone"
                placeholder="(###) ###-####"
                pattern="^[0-9-+s()]*$"
                autoComplete="tel-national"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="state">State *</label>
              <select
                name="state"
                className="form-control custom-select"
                id="state"
                autoComplete="address-level1"
                required
                defaultValue="default"
              >
                <option value="default" disabled>
                  Select
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="zip">Zip *</label>
              <input
                name="zip"
                type="text"
                className="form-control"
                id="zip"
                pattern="[0-9]{5}"
                autoComplete="postal-code"
                placeholder="#####"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="message">Message *</label>
              <textarea name="message" className="form-control" id="message" rows="4" required />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="form-group">
              {/* <div className="row">
                <div className="col-12 col-lg-9 order-1 order-lg-12">
                  <label htmlFor="interest">
                    Are you interested in receiving contact information for the
                    sales representative in your area? *
                  </label>
                </div>
                <div className="col-12 col-lg-3 order-12 order-lg-1">
                  <select
                    name="interest"
                    id="interest"
                    className="form-control custom-select"
                    required
                    defaultValue="default"
                  >
                    <option value="default" disabled>
                      Select
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center">
            <button className="btn btn-primary float-right">Submit</button>
          </div>
        </div>
      </form>
    );
  }
}
