import React, { Component } from 'react';

//Thin Chevron Icon
import chevron from '../../../assets/images/icons/chevronThin.svg';

//Slide Images
// import waltzdslide from '../../../assets/images/carousel/waltzd-slide.jpg';
// import waltzfreeslide from '../../../assets/images/carousel/waltz-free-slide.jpg';
// import waltzproslide from '../../../assets/images/carousel/waltz-pro-slide.jpg';
import slide3 from '../../../assets/images/carousel/slide3-3.jpg';
import slide4 from '../../../assets/images/carousel/slide4-2.jpg';

export default class HomepageCarousel extends Component {
  render() {
    return (
      <div className="row" id="carousel">
        <div
          id="carouselSlides"
          className="carousel slide carousel-fade"
          data-ride="carousel"
          data-interval="5000"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselSlides"
              data-slide-to="0"
              className="carouselIndicators active"
            >
              <i className="far fa-circle" />
            </li>
            <li
              data-target="#carouselSlides"
              data-slide-to="1"
              className="carouselIndicators"
            >
              <i className="far fa-circle" />
            </li>
            <li
              data-target="#carouselSlides"
              data-slide-to="2"
              className="carouselIndicators"
            >
              <i className="far fa-circle" />
            </li>
            <li
              data-target="#carouselSlides"
              data-slide-to="3"
              className="carouselIndicators"
            >
              <i className="far fa-circle" />
            </li>
            <li
              data-target="#carouselSlides"
              data-slide-to="4"
              className="carouselIndicators"
            >
              <i className="far fa-circle" />
            </li>
          </ol>

          <div className="carousel-inner carouselBox">
            {/* CAROUSEL CONTENT HERE */}

            <div className="carousel-item slideControl active">
              <img className="carouselImage" src={slide3} alt="..." />
              <div className="carouselContent">
                <h4 className="carouselTitle">Pharmaceutical Contracts</h4>
                <p>
                  AvKARE pharmaceutical products are available on FSS and DAPA
                  contracts.
                </p>
                <p>
                  <span className="d-block">FSS Contract - 36F79723D0144</span>
                  <span className="d-block">
                    DAPA Contract - SP0200-08-H-0016
                  </span>
                </p>
                <p className="lastChild">
                  Our Pharmaceuticals are available through all major
                  wholesalers & Group Purchasing Organizations (GPO).
                </p>
              </div>
            </div>

            {/* ///////// */}

            {/* <div className="carousel-item slideControl">
              <img className="carouselImage" src={waltzdslide} alt="..." />
              <div className="carouselContent">
                <h4 className="carouselTitle">
                  Disinfect. Sanitize. Deodorize.
                </h4>
                <p>
                  Waltz D, a hard surface disinfectant, effecitvely eliminates
                  bacteria, viruses, & fungi from surfaces. Waltz D is a
                  non-hazardous, non-corrosive solution designed to disinfect,
                  deodorize & sanitize. To learn more about Waltz D, visit{' '}
                  <a
                    href="http://waltzd.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    waltzd.com
                  </a>
                  .
                </p>
              </div>
            </div> */}

            {/* ///////// */}

            {/* <div className="carousel-item slideControl">
              <img className="carouselImage" src={waltzfreeslide} alt="..." />
              <div className="carouselContent">
                <h4 className="carouselTitle">Waltz Free</h4>
                <p>
                  Waltz Free is an alcohol-free hand sanitizing lotion that
                  lasts up to 4 times longer than alcohol based hand sanitizers.
                  Won’t dry or crack skin. To learn more about Waltz Free, visit{' '}
                  <a
                    href="http://waltzfree.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    waltzfree.com
                  </a>
                  .
                </p>
              </div>
            </div> */}

            {/* ///////// */}

            {/* <div className="carousel-item slideControl">
              <img className="carouselImage" src={waltzproslide} alt="..." />
              <div className="carouselContent">
                <h4 className="carouselTitle">Waltz Pro</h4>
                <p>
                  Waltz Pro is our granular disinfectant product. When mixed
                  with the appropriate amount of water, it creates a
                  Hypochlorous Acid based household disinfectant. To learn more
                  about Waltz Pro, visit{' '}
                  <a
                    href="http://waltzpro.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    waltzpro.com
                  </a>
                  .
                </p>
              </div>
            </div> */}

            {/* ///////// */}

            <div className="carousel-item slideControl">
              <img className="carouselImage" src={slide4} alt="..." />
              <div className="carouselContent">
                <h4 className="carouselTitle">Unit Dose Pharmaceuticals</h4>
                <p>
                  AvPAK, a division of AvKARE, offers a portfolio of
                  pharmaceutical products.
                </p>
                <p className="lastChild">
                  <strong className="font-italic"> Available now: </strong>{' '}
                  Amiodarone HCL Tabs, Chlorpromazine Tabs, Methylphenidate
                  Tabs, Tadalafil Tab 5mg, Terazosin Cap, Trazodone Tab 150mg
                </p>
              </div>
            </div>

            {/* ///////// */}

            <a
              className="carousel-control-prev carouselControls"
              href="#carouselSlides"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true">
                <img className="chevron chevronLeft" src={chevron} alt="<" />
              </span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next carouselControls"
              href="#carouselSlides"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true">
                <img className="chevron" src={chevron} alt=">" />
              </span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
