import React, { Component } from 'react';
import '../../css/Footer.css';

// import SdvosbLogo from '../../assets/images/logos/SDVOSB_gray.svg';
import AvKARELogo from '../../assets/images/logos/avkare-logo-footer.png';
import HDALogo from '../../assets/images/HDA-Member-Pledge-Badge-RGB-600px.png';
// import barcodeCatalog from '../../assets/pdfs/barcodeCatalog-summer2019.pdf';
import SocialMedia from '../layout/SocialMedia';
import { Row, Col } from 'react-bootstrap';

export default class Footer extends Component {
  state = {
    collapse: 'show',
    collapsible: 'collapse',
    collapseIcon: 'fas fa-plus',
    dropdown1: true,
    dropdown2: true,
    dropdown3: true,
    dropdown4: true,
  };

  componentDidMount() {
    window.addEventListener('resize', this.determineCollapse);
    this.determineCollapse();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.determineCollapse);
  }

  determineCollapse = () => {
    let footerwidth = document.getElementById('footer').clientWidth;
    footerwidth > 576
      ? this.setState({ collapse: 'show', collapsible: '' })
      : this.setState({ collapse: '', collapsible: 'collapse' });
  };

  iconToggle = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  render() {
    return (
      <Row id="footer">
        <Col xs="12" sm="3" className="my-auto d-none d-sm-block">
          <img src={AvKARELogo} className="avkareLogo" alt="AvKARE" />
        </Col>
        <Col xs="12" sm="9">
          <Row>
            <Col xs="12" sm="3">
              <Row>
                <Col xs="12">
                  <ul id="productsAccordian">
                    <li>
                      <button
                        id="dropdown1"
                        className={
                          this.state.dropdown1
                            ? 'bold toggleSelect plusSymbol'
                            : 'bold toggleSelect minusSymbol'
                        }
                        data-toggle={this.state.collapsible}
                        data-target="#productsCollapse"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={this.iconToggle}
                      >
                        Products
                      </button>
                    </li>

                    <div
                      id="productsCollapse"
                      className={'collapse ' + this.state.collapse}
                      aria-labelledby="headingOne"
                      data-parent="#productsAccordion"
                    >
                      <li>
                        <a
                          href="https://store.avkare.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Online Store
                        </a>
                      </li>
                      <li>
                        <a href="/products/pharma">Pharma Catalog</a>
                      </li>
                      <li>
                        <a href="/consumer-information">Consumer Information</a>
                      </li>
                    </div>
                  </ul>
                </Col>
                <Col xs="12">
                  <ul id="vendorsAccordion">
                    <li>
                      <button
                        id="dropdown2"
                        className={
                          this.state.dropdown2
                            ? 'bold toggleSelect plusSymbol'
                            : 'bold toggleSelect minusSymbol'
                        }
                        data-toggle={this.state.collapsible}
                        data-target="#vendorsCollapse"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={this.iconToggle}
                      >
                        Vendors
                      </button>
                    </li>

                    <div
                      id="vendorsCollapse"
                      className={'collapse ' + this.state.collapse}
                      aria-labelledby="headingOne"
                      data-parent="#vendorsAccordion"
                    >
                      <li>
                        <a href="vendors#government">Government</a>
                      </li>
                      <li>
                        <a href="vendors#commercial">Commercial</a>
                      </li>
                    </div>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs="12" sm="3">
              <div className="row">
                <div className="col-12">
                  <ul id="supportAccordion">
                    <li>
                      <button
                        id="dropdown3"
                        className={
                          this.state.dropdown3
                            ? 'bold toggleSelect plusSymbol'
                            : 'bold toggleSelect minusSymbol'
                        }
                        data-toggle={this.state.collapsible}
                        data-target="#supportCollapse"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={this.iconToggle}
                      >
                        Support
                      </button>
                    </li>

                    <div
                      id="supportCollapse"
                      className={'collapse ' + this.state.collapse}
                      aria-labelledby="headingOne"
                      data-parent="#supportAccordion"
                    >
                      <li>
                        <a href="/contact">Contact Us</a>
                      </li>
                    </div>
                    <div
                      id="supportCollapse"
                      className={'collapse ' + this.state.collapse}
                      aria-labelledby="headingOne"
                      data-parent="#supportAccordion"
                    >
                      <li>
                        <a href="/recall">Recall Notices</a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="3">
              <div className="row">
                <div className="col-12">
                  <ul id="aboutAccordion">
                    <li>
                      <button
                        id="dropdown4"
                        className={
                          this.state.dropdown4
                            ? 'bold toggleSelect plusSymbol'
                            : 'bold toggleSelect minusSymbol'
                        }
                        data-toggle={this.state.collapsible}
                        data-target="#aboutCollapse"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={this.iconToggle}
                      >
                        About AvKARE
                      </button>
                    </li>

                    <div
                      id="aboutCollapse"
                      className={'collapse ' + this.state.collapse}
                      aria-labelledby="headingOne"
                      data-parent="#aboutAccordion"
                    >
                      <li>
                        <a href="/who-we-are">Who We Are</a>
                      </li>
                      <li>
                        <a href="/news">News</a>
                      </li>
                      <li>
                        <a href="/careers">Careers</a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xs="12" sm="3">
              <div className="row">
                <div className="col-12">
                  <ul>
                    <li className="bold">Follow Us</li>
                    <SocialMedia />
                  </ul>
                  <img src={HDALogo} width="100px" alt="HDA Member Pledge" />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="12">
          <small className="copyright semiBold">
            Copyright © 2020 AvKARE, LLC. All rights reserved.
          </small>
        </Col>
      </Row>
    );
  }
}
