import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap';

export default class SaveTempModal extends Component {
    _tempSaveForm;

    constructor (props) {
        super();
        this.state = {
            isSaving: false
        }
        this.cancelTempSaveForm = this.cancelTempSaveForm.bind(this);
        this.displayToast = this.displayToast.bind(this);
    }

    get tempSaveForm () {
        this.tempSaveForm = document.querySelector('#tempSaveForm');
        return this._tempSaveForm;
    }
    set tempSaveForm(el) {
        this._tempSaveForm = el;
    }

    cancelTempSaveForm = (e) => {
        this.setState({isSaving: false});
        this.props.onClose();
    }

    onSubmit = (e)=>{
        this.setState({isSaving: true});
        this.tempSaveNewCustomer(e)
            .then((result) => {
                console.log(result);
                this.displayToast('Success', 'Customer Application Saved');
                this.props.onSuccess(); // Only close on success to give the user a change to fix an error
            })
            .catch(e => {
                console.error(e);
                this.displayToast('Error', 'There was a problem saving the form info.');
            })
            .finally(() => this.setState({isSaving: false}));
    }

    validateForm = (form) => {
        let isValid = false;
        
        // validate customerform
        if (form.checkValidity()) {
            isValid = true;
        }
        // for Bootstrap visual validation
        form.classList.add('was-validated');
        return isValid;
    }

    displayToast = (title, message) => {
        this.props.displayToast(title, message);
    }

    /**
     * Save and Continue later option
     * Saves all form data, even if invalid or incomplete
     *
     * @return void
     */
    tempSaveNewCustomer = (event) => {
        event.preventDefault();
        event.stopPropagation();
        return new Promise((resolve, reject) => {
            if (!this.props.newCustomerWebForm) {
                reject();
            }
            if (this.validateForm(this.tempSaveForm)) {
                try {
                    if (Object.keys(this.props.newCustomerWebForm).length < 1) {
                        reject('New Customer form data is missing');
                    } else {
                        let tempData = new FormData(this.tempSaveForm);
                        window.localStorage.setItem('CustomerForm-'+tempData.get('tempSaveName')+'-'+tempData.get('tempSaveEmail'), JSON.stringify(this.props.newCustomerWebForm));
                        
                        setTimeout(()=>{
                            resolve('Successfully saved');
                        }, 250);
                    }
                } catch(e) {
                    console.error(e);
                    reject('Something went wrong while saving your form data');
                }
            } else {
                reject('Please Check Name and Email fields');
            }
            
        });
    }

    captureKeyboard = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit(e);
        }
    }
    onEntered = () => {
        let nameField = this.tempSaveForm.querySelector('#tempSaveName');
        if (nameField) {
            nameField.focus();
        }
    }

    render() {
        return (
            <Modal
                centered
                size='lg'
                show={this.props.show}
                onHide={this.cancelTempSaveForm}
                onEntered={this.onEntered}
                backdrop="static"
                keyboard={false}
                className="rounded-0" 
                onKeyDown={this.captureKeyboard}
                id="newCustomerTempSave">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Save Form
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Save form to continue later?</p>
                    <p>This will store all form information except for <b>FILES & ATTACHMENTS</b> that you selected. When you restore the information later, the FILES will not be loaded. This is a limitation of how files are referenced on the web. Once the form has been completed and sent off, this information will be deleted.</p>
                    <p>These values are case sensitive.</p>
                    <form id="tempSaveForm">
                        <label htmlFor="tempSaveName">Your Name&nbsp;<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" id="tempSaveName" name="tempSaveName" required />
                        <label htmlFor="tempSaveEmail">Email&nbsp;<span className='text-danger'>*</span></label>
                        <input type="email" className="form-control" id="tempSaveEmail" name="tempSaveEmail" required />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={this.cancelTempSaveForm} disabled={this.state.isSaving}>Cancel</Button>
                    <Button
                        variant="primary"
                        disabled={this.state.isSaving}
                        onClick={!this.state.isSaving ? this.onSubmit: null}
                        >
                        {this.state.isSaving ? 'Saving...' : 'Save & Continue Later'}
                    </Button>
                </Modal.Footer>  
            </Modal>
        )
    }
}