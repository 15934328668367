import React, { Component } from 'react';

import '../../css/404.css';

export default class Error extends Component {
  componentDidMount() {
    document.title = 'AvKARE | 404';
  }

  render() {
    return (
      <div className="page" id="error">
        <div className="row h-100">
          <div className="col-12 text-center my-auto">
            <h1>Error 404</h1>
            <p className="lead">Page not found!</p>
          </div>
        </div>
      </div>
    );
  }
}
