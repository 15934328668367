import React, {Component} from "react";
import { Modal, Button } from "react-bootstrap";

export default class RestoreFormModal extends Component {
    _restoreForm;

    constructor(props) {
        super();
        this.state = {isRestoring: false};
        this.closeRestoreForm = this.closeRestoreForm.bind(this);
        this.restoreNewCustomerData = this.restoreNewCustomerData.bind(this);
        this.displayToast = this.displayToast.bind(this);
    }

    get restoreForm() {
        this.restoreForm = document.getElementById('restoreForm');

        return this._restoreForm;
    }
    set restoreForm(el) {
        this._restoreForm = el;
    }

    /**
     * Calls props.onClose() event handler
     * @param {Event} e 
     */
    closeRestoreForm = (e) => {
        this.props.onClose();
    }

    /**
     * Get stored data (localStorage) with a given 'key' after validating the name and email fields
     * Calls props.onSuccess(parsedData) after succesfully retrieving and JSON parsing the data
     * Calls displayToast on success and errors
     */
    restoreNewCustomerData = ()=> {
        if (this.validateForm(this.restoreForm)) {
            let formData = new FormData(this.restoreForm);
            let storageKey = 'CustomerForm-'+formData.get('tempSaveName')+'-'+formData.get('tempSaveEmail');
            let storedInfo = window.localStorage.getItem(storageKey);
            if (storedInfo) {
                try {
                    let parsedData = JSON.parse(storedInfo);
                    this.displayToast('Success', 'Successfully retrieved saved form data');
                    this.props.onSuccess(parsedData);
                } catch (error) {
                    console.error(error);
                    this.displayToast('Error', 'There was a problem retrieving saved form data');
                }
            } else {
                this.displayToast('Info', 'Could not find save data with the information you provided');
            }
        } else {
            this.displayToast('Error', 'Please Check Name and Email fields');
        }
    }

    displayToast = (title, message) => {
        this.props.displayToast(title, message);
    }

    /**
     * Run checkValidity on given form and display bootstrap styling
     * @param {HTML} form 
     * @returns 
     */
    validateForm = (form) => {
        let isValid = false;
        
        // validate customerform
        if (form.checkValidity()) {
            isValid = true;
        }
        // for Bootstrap visual validation
        form.classList.add('was-validated');
        return isValid;
    }

    captureKeyboard = (e) => {
        if (e.key === 'Enter') {
            this.restoreNewCustomerData(e);
        }
    }

    onEntered = () => {
        let nameField = this.restoreForm.querySelector('#tempSaveName');
        if (nameField) {
            nameField.focus();
        }
    }

    render() {

        return (
            <Modal 
                centered
                size='lg'
                show={this.props.show}
                onEntered={this.onEntered}
                onHide={this.closeRestoreForm}
                backdrop="static"
                keyboard={false}
                onKeyDown={this.captureKeyboard}
                className="rounded-0" 
                id="restoreFormDataForm">
                <Modal.Header closeButton>
                    <Modal.Title>Restore Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Restore previously saved information?</p>
                    <p>
                        Enter in the same "Name" and "Email" you previously used when saving the form information and we'll try to retrieve it for you. These values are case sensitive.
                    </p>
                    <p>
                        Files you may have uploaded previously will not be stored.
                    </p>
                    <form id="restoreForm">
                        <label htmlFor="tempSaveName">Your Name&nbsp;<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" id="tempSaveName" name="tempSaveName" required />
                        <label htmlFor="tempSaveEmail">Email&nbsp;<span className='text-danger'>*</span></label>
                        <input type="email" className="form-control" id="tempSaveEmail" name="tempSaveEmail" required />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={this.closeRestoreForm} disabled={this.state.isRestoring}>Cancel</Button>
                    <Button variant='primary'
                        disabled={this.state.isRestoring}
                        onClick={this.restoreNewCustomerData}>{this.state.isRestoring ? 'Restoring...' : 'Restore'}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}