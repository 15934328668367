import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {Container}  from 'react-bootstrap';

import Navbar from './Navbar';
import Footer from './Footer';
// import WaltzDFooter from '../routes/Products/WaltzD/layout/footer';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    // this.state = {isWaltz: props.location.pathname && props.location.pathname.indexOf('waltz') >= 0};
    this.state = {isWaltz: false};
  }

  // static getDerivedStateFromProps(props, state) {
  //   // if (props.location.pathname && props.location.pathname.indexOf('waltz') >= 0){
  //   //   return {isWaltz: true};
  //   // } else {
  //   //   return {isWaltz: false};
  //   // }
  // }

  render() {
    return (
      <Container fluid className="bgWhite">
        { !this.state.isWaltz && <Navbar /> }
        {this.props.children}
        {!this.state.isWaltz && <Footer />}
        {/* {this.state.isWaltz && <WaltzDFooter/>} */}
      </Container>
    );
  }
}

export default withRouter(MainLayout);