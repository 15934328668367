import React, { Component } from 'react';

export default class VendorCards extends Component {
  render() {
    return (
      <div className="row" id="vendorCards">
        <div className="card-group m-0 p-0">
          <div className="card square grayPatternBg text-white m-0 padding">
            <div className="card-body cardControl m-0">
              <div className="card-header text-center cardHeaderControl">
                <i className="fas fa-university icon" />
                <div className="card-title cardTitleControl h4 m-1">
                  Government
                </div>
              </div>
              <div className="card-text cardTextControl">
                <p>
                  As a longtime provider to the U.S. Government, AvKARE invests
                  in products that will promote the health and well-being of our
                  esteemed governmental clients. AvKARE is privileged to support
                  a variety of federal, state, and local agencies.
                </p>
              </div>
              <div>
                <a
                  href="/vendors#government"
                  className="btn btn-outline-light text-uppercase transparentBtn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="card square blackPatternBg text-white m-0 padding">
            <div className="card-body cardControl m-0">
              <div className="card-header text-center cardHeaderControl">
                <i className="far fa-building icon" />
                <div className="card-title cardTitleControl h4 m-1">
                  Commercial
                </div>
              </div>
              <div className="card-text cardTextControl">
                <p>
                  Our partnerships with a variety of Group Purchasing
                  Organizations (GPO) and our endless cost-saving opportunities
                  available through Open Market Sourcing ensures the ‘best
                  value’ for our clients.
                </p>
              </div>
              <div>
                <a
                  href="/vendors#commercial"
                  className="btn btn-outline-light text-uppercase transparentBtn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          {/* 
          <div className="card square redPatternBg text-white m-0 padding">
            <div className="card-body cardControl m-0">
              <div className="card-header text-center cardHeaderControl">
                <i className="fas fa-handshake icon" />
                <div className="card-title cardTitleControl h4 m-1">
                  Partners
                </div>
              </div>

              <div className="card-text cardTextControl">
                <p>
                  AvKARE partners with the most sought-after manufacturers in
                  the industry. These partnerships provide the highest quality
                  products at the most competitive prices, allowing AvKARE to
                  share the cost savings with our valued customers.
                </p>
              </div>
              <div>
                <a
                  href="/vendors#partners"
                  className="btn btn-outline-light text-uppercase transparentBtn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
