import React, { Component } from 'react';
import {Row, Col} from 'react-bootstrap';
import '../../../css/ContactUs.css';

import SideBar from './sideBar.js';
import Form from './form.js';
import Thanks from './thanks.js';

export default class ContactUs extends Component {
  componentDidMount() {
    document.title = 'AvKARE | Contact Us';
  }

  render() {
    // Displays form or thank you message
    var showSection;
    if (this.props.location.pathname === '/contact/thanks') {
      showSection = <Thanks />;
    } else {
      showSection = <Form />;
    }

    return (
      <div className="page" id="contactUs">
        <Row>
          <SideBar />
          <Col xs="12" lg="9" className="order-1 order-lg-12 background m-0 p-0">
            <h1 className="text-center georgiaFont font-italic grayText">
              We appreciate your interest in AvKARE!
            </h1>
            <div className="text-center pt-1 pb-3">
              For drug safety and concerns, please visit our{' '}
              <a href="/consumer-information">Consumer Information</a> page.
            </div>
            <div className="mainSection p-3 mx-auto">{showSection}</div>
            
          </Col>
        </Row>
      </div>
    );
  }
}
