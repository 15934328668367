import React, {Component} from 'react';

import '../../css/recall.css';

import AtovaquoneImage from '../../assets/images/Atovaquone.jpg';
import AtovaquoneImageTwo from '../../assets/images/Atovaquone2.jpg';

export default class Recall extends Component{
    componentDidMount() {
        document.title = 'AvKARE | Recall Notices';
    }

    render () {
        return (
            <div className="page" id="recall-notices">
                <h1>AvKARE, LLC. Issues Voluntary Nationwide Recall of Atovaquone Oral Suspension, USP 750 mg/5 mL Due to Potential Bacillus cereus Contamination</h1>
                <div className="row" id="introduction">
                    <div className="col-12  mb-5 mt-5">
                        <div className="width mx-auto">
                            <p>
                                Company Contact:
                                <span className='semiBold non-list'>Name: Drug Safety Department</span>
                                <span className='semiBold'>Phone Number: 1-855-361-3993</span>
                            </p>
                            <p>
                                <span className="h3 textRed semiBold d-block">FOR IMMEDIATE RELEASE – 3/29/2024</span>
                                <span className='semiBold'>AvKARE, LLC.</span> is voluntarily recalling lot # AW0221A of Atovaquone Oral Suspension, USP 
                                750mg/5mL to the Consumer/User level, due to the potential Bacillus cereus contamination 
                                in the product found during stability testing at a 3rd party lab.
                            </p>
                            
                            <p>
                                <span className='semiBold'>Risk Statement:</span> In the population most at risk, immunocompromised population, there is a reasonable probability that 
                                microbial contamination of Atovaquone Oral Suspension can result in disseminated, life threatening infections such as 
                                endocarditis and necrotizing soft tissue infections. <em>To date, AvKARE has not received any reports of adverse events 
                                related to this recall.</em>
                            </p>
                            <p>
                                Atovaquone Oral Suspension, USP is indicated for prevention and treatment of Pneumocystis jiroveci pneumonia (PCP) in adults and children 13 years of age and older who cannot tolerate other medicines, such as trimethoprim-sulfamethoxazole.
                            </p>
                            <p>
                                Atovaquone Oral Suspension, USP was distributed between 03/18/2024 through 03/21/2024 Nationwide to Wholesalers.
                            </p>
                            <p>
                                The product is packaged in a carton. The identified NDC # associated with the product is 50268-086-12, UPC # 5026808612 and the affected lot# is AW0221A with an expiration date of 08/2025.
                            </p>
                            <img className='third-width' src={AtovaquoneImage} alt="Atovaquone information"/>
                            
                            <img className='third-width' src={AtovaquoneImageTwo} alt="Atovaquone infomration 2"/>

                            <p>
                                AvKARE, LLC is notifying its distributors and wholesale customers by mailings and email communications method and is arranging for returns of all recalled Atovaquone Oral Suspension, USP.
                            </p>
                            <p>
                                Wholesalers who have Atovaquone Oral Suspension, USP 750mg/5mL, which are being recalled, should examine their inventory and cease dispensing, return any of the impacted lots to AvKARE, LLC. 
                            </p>
                            <p>
                                Consumers that have product which is being recalled should stop using the product and return it to place of purchase or discard. 
                                Consumers with questions regarding this recall can contact AvKARE by phone at <a href='tel:+1(855)361-3993'>1-855-361-3993</a> or email <a href='mailto:drugsafety@avkare.com'>drugsafety@avkare.com</a>, 
                                Monday – Friday, 9am – 5pm Eastern Time. Consumers should contact their physician or healthcare provider if they have 
                                experienced any problems that may be related to taking or using this drug product.
                            </p>
                            <p>
                                Adverse reactions or quality problems experienced with the use of this product may be reported to the FDA's MedWatch Adverse Event Reporting program either online, by regular mail or by fax.
                            </p>
                            <ul>
                                <li><span className='semiBold'>Complete and submit the report Online:</span> <a href="www.fda.gov/medwatch/report.htm">www.fda.gov/medwatch/report.htm</a></li>
                                <li><span className='semiBold'>Regular Mail or Fax:</span> Download form <a href="www.fda.gov/MedWatch/getforms.htm">www.fda.gov/MedWatch/getforms.htm</a> or call <a href="tel:1-800-332-1088">1-800-332-1088</a> to request a reporting form, then complete and return to the address on the pre-addressed form, or submit by fax to 1-800-FDA-0178</li>
                            </ul>
                            <p>
                                This recall is being conducted with the knowledge of the U.S. Food and Drug Administration.
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}