import React, {Component} from 'react';
import FieldTypes from './new-customer-form-field-types';
import { simpleOutputTypes, UI_TYPES } from './new-customer-form-constants';
import { Accordion, Nav, Tab, TabPane, Tabs, Card, ListGroup, Container, Row } from 'react-bootstrap';

export class CustomTabContainer extends Component {
    constructor (props) {
        super();
        this.tabProps = {};
        this.tabPaneProps = {};
        this.sectionProps = {};

        this.toggleFirstRef = React.createRef();
        this.handleToggleFirstChange = this.handleToggleFirstChange.bind(this);
        this.removeSectionHandler = this.removeSectionHandler.bind(this);
        this.addSectionHandler = this.addSectionHandler.bind(this);

        this.state = {reviewSectionTabs: {}};
    }

    handleToggleFirstChange = (e) => {this.props.changeHandlers.toggleFirstChange(e);}

    removeSectionHandler = (e) => {this.props.changeHandlers.removeSectionHandler(e);}
    addSectionHandler = (e) => {this.props.changeHandlers.addSectionHandler(e);}

    hideFirst(tab, section) {
        if (section.multiple && section.hideFirst) {
            return <div className="form-check form-switch">
                    <input className="form-check-input"
                        name={tab.name + '[' + section.name + '][toggle_first]'} 
                        id={tab.name + '[' + section.name + '][toggle_first]'} 
                        data-rs-toggle-first="true"
                        ref={this.toggleFirstRef}
                        onChange={this.handleToggleFirstChange}
                        type="checkbox" />
                        
                    <label className="form-check-label" htmlFor={tab.name + '[' + section.name + '][toggle_first]'} >
                        {this.hasHelp(section)}
                        Add {section.displayName}
                    </label>
                </div>
        } else {
            return;
        }
    }

    hasHelp(thing) {
        if (thing.help) {
            return <span className="help-cursor" data-bs-toggle="tooltip" data-bs-placement="top" title={thing.help}>
                    <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label={thing.help}><use xlinkHref="#info-fill"/></svg>
                </span>
        } else {
            return;
        }
    }

    onTabSelect = (event) => {
        let clicktabButtonIndex = event.nativeEvent.target.getAttribute('data-rr-ui-event-key');
        let prevTabButton = this.props.methods.getActiveTabButton();
        let prevtabPane = document.getElementById(prevTabButton.getAttribute('aria-controls'));

        this.props.methods.validateTab(prevTabButton, prevtabPane, false);
        this.props.methods.setPrevNextButtons(clicktabButtonIndex);

        if (event.nativeEvent.target.id === 'newCustomerWebForm-Tabs-tab-7') {
            this.props.methods.reviewSubmit()
                .then((results) => {
                    this.setState({reviewSectionTabs: results});
                });
        }
    }

    render() {
        return (
            <Tab.Container id="newCustomerWebForm-Tabs" defaultActiveKey={0} mountOnEnter={true}>
                <Nav variant='tabs'>
                    {this.props.tabs.map((tab, key) => {
                        
                        this.tabProps = {
                            title: tab.help,
                            id: tab.name + '-tab',
                            'data-bs-toggle': "tooltip"
                        };
                
                        if (Object.keys(tab.dependency).length > 0) {
                            this.tabProps['data-rs-dependency'] = JSON.stringify(tab.dependency);
                        }
                        
                        return <Nav.Item {...this.tabProps} key={key.toString()}>
                                <Nav.Link as="button" eventKey={key} type='button' onClick={this.onTabSelect}>
                                    {tab.displayName}&nbsp;
                                    <span className="text-danger tab-incomplete hidden" data-bs-toggle="tooltip"
                                        title="Required fields need to be filled in" id={tab.name + '-tab-incomplete'}>
                                        <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill"/></svg>
                                    </span>
                                    <span className="text-success tab-complete hidden" data-bs-toggle="tooltip"
                                        title="Required fields complete" id={`${tab.name}-tab-complete`}>
                                        <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill"/></svg>
                                    </span>
                                </Nav.Link>
                            </Nav.Item>
                    })}
                    
                </Nav>
                <Tab.Content>
                    {this.props.tabs.map((tab, key) => {
                        
                        this.tabPaneProps = {
                            id: tab.name
                        };
                        if (Object.keys(tab.dependency).length > 0) {
                            this.tabPaneProps['data-rs-dependency'] = JSON.stringify(tab.dependency);
                        }
                        
                        return <TabPane {...this.tabPaneProps} key={key} eventKey={key} mountOnEnter={false}>
                                    <Accordion defaultActiveKey={tab.sections[0].name} id={`accordion-${tab.name}`}>
                                        {tab.sections.map((section, sectionKey) => {
                                            
                                            this.sectionProps = {
                                                className : 'accordion-item',
                                                id: section.name + '-accordion-0',
                                                eventKey: section.name,
                                                'data-rs-accordion-group': section.name + '-accordion'
                                            };

                                            if (Object.keys(section.dependency).length > 0) {
                                                this.sectionProps['data-rs-dependency'] = JSON.stringify(section.dependency);
                                            }
                                            
                                            return <div key={sectionKey}>
                                                    {this.hideFirst(tab, section)}
                                                    <Accordion.Item {...this.sectionProps} key={sectionKey}>
                                                        <Accordion.Header id={tab.name + '-' + section.name +'-heading'}>
                                                            {this.hasHelp(section)}
                                                            {section.displayName}
                                                            &nbsp;
                                                            {section.multiple && <span className="section-count">({sectionKey + 1})</span>}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {section.multiple && 
                                                                <div className="row justify-content-end">
                                                                    <div className="col-md 4 d-flex"></div>
                                                                    <div className="col-md 4 d-flex"></div>
                                                                    <div className="col-md 4 d-flex justify-content-end">
                                                                        <button type="button" className="btn btn-danger rounded-0 hidden" data-bs-toggle="tooltip" title={`"Remove this ${section.displayName}`}
                                                                                
                                                                                data-rs-remove-accordion={section.name + '-accordion-0'}>
                                                                            <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Remove Section"><use xlinkHref="#minus-square"/></svg>
                                                                            Remove
                                                                        </button>
                                                                    </div>
                                                                </div>}
                                                            <div className={section.sectionStyle.rowStyle()}>
                                                                {section.fields.map((field, key) => {
                                                                    return <Field key={key.toString()} 
                                                                            tabName={tab.name}
                                                                            sectionName={section.name}
                                                                            fieldData={field} 
                                                                            fieldKey={key} 
                                                                            methods={this.props.methods}
                                                                            reviewSectionTabs = {this.state.reviewSectionTabs}
                                                                            changeHandlers={this.props.changeHandlers}
                                                                            multipleSectionKey={section.multiple ? `[${sectionKey}]` : ''}/>
                                                                })}
                                                                {section.subsections.map((sub, subkey) => {
                                                                    return <Section key={subkey.toString()} 
                                                                                tabName={tab.name}
                                                                                sectionData={sub} 
                                                                                sectionKey={key} 
                                                                                methods={this.props.methods}
                                                                                reviewSectionTabs = {this.state.reviewSectionTabs}
                                                                                changeHandlers={this.props.changeHandlers}
                                                                                multipleSectionKey={sub.multiple ? `[${subkey}]` : ''}/>
                                                                })}
                                                                {section.multiple && 
                                                                    <div className="row">
                                                                        <div className="col-md 4 d-flex"></div>
                                                                        <div className="col-md 4 d-flex"></div>
                                                                        <div className="col-md 4 d-flex justify-content-end">
                                                                            <button type="button" className="btn btn-primary rounded-0" data-bs-toggle="tooltip" title={`Add Another ${section.displayName}`}
                                                                                    data-rs-add-accordion={section.name + '-accordion'}>
                                                                                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Add section"><use xlinkHref="#plus-square"/></svg>
                                                                                Add {section.displayName}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </div>
                                        })}
                                    </Accordion>
                                </TabPane>
                    })}
                </Tab.Content>
            </Tab.Container>
        )
    }
}


class Field extends Component {
    constructor(props) {
        super();

        this.tabName = props.tabName;
        this.sectionName = props.sectionName;
        this.field = props.fieldData;
        this.fieldKey = props.fieldKey;
        this.multipleSectionKey = props.multipleSectionKey;
        
        this.fieldProps = {
            id: this.tabName + (this.sectionName ? '-' + this.sectionName : '') + (this.multipleSectionKey ? '-' + this.multipleSectionKey : '') + '-' + this.field.field + (this.field.defaultValue ? this.field.defaultValue : '') + '-group-0',
            style: {position: 'relative'},
            'data-rs-field-group': this.field.field + '-group',
            className: this.field.fieldStyle.colStyle(),
            methods: props.methods
        };
    }

    handleChange = (e) => {}
    
    multipleButton() {
        return <button type="button" 
                        className="btn btn-primary rounded-0 dup-button" 
                        title={`Add Another ${this.field.displayName}`}
                        data-rs-add-field={this.field.field + '-group-0'}>
                    +
                </button>
    }

    addSubsections() {
        if (this.field.subsections.length) {
            return this.field.subsections.map((sub, index) => {
                return <Section key={index.toString()} 
                            tabName={this.props.tabName}
                            sectionData={sub} 
                            sectionKey={index} 
                            methods={this.props.methods}
                            changeHandlers={this.props.changeHandlers}
                            reviewSectionTabs = {this.props.reviewSectionTabs}
                            multipleSubsectionKey={sub.multiple ? '[0]': false}
                            multipleSectionKey={this.multipleSectionKey} />
            })
        } else {
            return;
        }
    }

    humanizeString = (string) => {
        let spaced = string.replace('_', ' ');
        let words = spaced.split(' ');
        let cap = words.map(word =>  word[0].toUpperCase() + word.substring(1));

        return cap.join(' ');
    }

    FieldItem () {
        if (simpleOutputTypes.includes(this.field.inputType)) {
            if (this.field.inputType === UI_TYPES.READ_ONLY) {
                return <div id="review-output">
                    <Tabs defaultActiveKey='business_info' id="reivew-tabs" className="mb-3">
                        {Object.keys(this.props.reviewSectionTabs).length > 0 && Object.entries(this.props.reviewSectionTabs).map((tab, key) => {
                            return <Tab key={key} eventKey={tab[0]} title={tab[0]}>
                                <Container>
                                    <Row xs={1} md={3}>
                                    {Object.entries(tab[1]).map((section, key) => {
                                        return <Card key={key} style={{ width: '18rem' }}>
                                                <Card.Header>{this.humanizeString(section[0])}</Card.Header>
                                                <ListGroup variant="flush">
                                                    {Object.entries(section[1]).map((field, key) => {
                                                        return <ListGroup.Item key={key}>
                                                                    {this.humanizeString(field[0])}: {JSON.stringify(field[1])}
                                                                </ListGroup.Item>
                                                    })}
                                                </ListGroup>
                                            </Card>
                                    })}
                                    </Row>
                                </Container>
                                </Tab>
                                
                        })}
                    </Tabs>
                </div>
            } else {
                return FieldTypes.mapFieldType(this.field.inputType, this.props);
            }
            
        } else {
            if (Object.keys(this.field.dependency).length > 0) {
                this.fieldProps['data-rs-dependency'] = JSON.stringify(this.field.dependency);
            }
            
            return <div {...this.fieldProps}>
                        {this.field.multiple && this.multipleButton()}
                        {this.field.inputType !== UI_TYPES.READ_ONLY && FieldTypes.mapFieldType(this.field.inputType, this.props)}
                        {this.addSubsections()}
                    </div>
        }
    }

    render() {
        return this.FieldItem();
    }
}

class Section extends Component {
    constructor(props) {
        super();
        this.section = props.sectionData;
        this.sectionKey = props.sectionKey;
        this.multipleSectionKey = props.multipleSectionKey;

        this.sectionProps = {
            id: `${props.tabName}-${this.section.name}-section${this.section.multiple || this.multipleSectionKey? '-0' : ''}`,
            className: this.section.sectionStyle.rowStyle() + (Object.keys(this.section.dependency).length ? ' hidden ': ''),
            'data-rs-section-group': props.tabName + '-' + this.section.name + "-section"
        };
    }
    

    SectionHeading() {
        if (!this.section.multiple && this.section.displayName !== '') {
            return <h6 style={{textDecoration: 'underline'}}>
                        {this.section.displayName}
                    </h6>
        } else {
            return <h6>
                <button type="button" className="btn btn-success rounded-0" title={`Add Another ${this.section.displayName !== '' ? this.section.displayName: ''}`}
                        data-rs-add-section={this.props.tabName +'-'+ this.section.name + '-section'}>
                    <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Add section"><use xlinkHref="#plus-square"/></svg>
                    Add {this.section.displayName !== '' ? this.section.displayName: ''}
                </button>
                <button type="button" className="btn btn-danger rounded-0 hidden" title={`Remove this ${this.section.displayName !== '' ? this.section.displayName: ''}`}
                        data-rs-remove-section={this.props.tabName +'-'+ this.section.name + '-section-0'}>
                    <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Remove Section"><use xlinkHref="#minus-square"/></svg>
                    Remove {this.section.displayName !== '' ? this.section.displayName: ''}
                </button>
            </h6>
        }
        
    }

    SectionItem() {
        if (Object.keys(this.section.dependency).length > 0) {
            this.sectionProps['data-rs-dependency'] = JSON.stringify(this.section.dependency);
        } 
        return <div {...this.sectionProps}>
                    {this.SectionHeading()}
                    {this.section.fields.map((field, key) => {
                        return <Field key={key.toString()} 
                                    tabName={this.props.tabName}
                                    sectionName={this.section.name}
                                    fieldData={field} 
                                    fieldKey={key} 
                                    methods={this.props.methods}
                                    reviewSectionTabs = {this.props.reviewSectionTabs}
                                    changeHandlers={this.props.changeHandlers}
                                    multipleSubsectionKey= {this.props.multipleSubsectionKey}
                                    multipleSectionKey={this.multipleSectionKey} />
                    })}
                    {this.section.multiple && <hr/>}
                </div>
    }

    render() {
        return (
            this.SectionItem()
        )
    }
}