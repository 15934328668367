import React, { Component } from 'react';

import { Timeline } from 'react-twitter-widgets';

import '../../css/News.css';

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = { loadPending: false };
  }

  handleTwitterLoaded() {
    this.setState({ loadPending: true });
  }

  componentDidMount() {
    document.title = 'AvKARE | News';
  }

  render() {
    return (
      <div className="page colorBg" id="news">
        <div className="pt-5 width mx-auto text-center">
          <div className={this.state.loadPending ? 'visible' : 'hide'}>
            <Timeline
              onLoad={this.handleTwitterLoaded.bind(this)}
              dataSource={{
                sourceType: 'profile',
                screenName: 'AvKARE_LLC',
              }}
              options={{
                username: 'AvKARE, LLC',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
