import React, { Component } from 'react';

import '../../css/ReturnPolicy.css';
import PharmaReturn from '../../assets/pdfs/Return_Policies_and_Procedures_Pharmaceutical.pdf';
import MedReturn from '../../assets/pdfs/Return_Policies_and_Procedures_Medical-Surgical-Lab.pdf';

export default class ReturnPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = { loadPending: false };
  }

  handleTwitterLoaded() {
    this.setState({ loadPending: true });
  }

  componentDidMount() {
    document.title = 'AvKARE | Return Policy';
  }

  render() {
    return (
      <div className="page" id="returnPolicy">
        <div className="row">
          <div className="col-12 mt-2 pt-5">
            <h1 className="text-center font-italic georgiaFont">Return Policy</h1>
          </div>
        </div>
        <div className="pt-5 width mx-auto text-center">
          <a href={PharmaReturn} target="blank" className="btn text-uppercase d-block w-50 mx-auto">
            RETURN POLICIES AND PROCEDURES PHARMACEUTICAL
          </a>
          <a href={MedReturn} target="blank" className="btn text-uppercase d-block w-50 mx-auto">
            RETURN POLICIES AND PROCEDURES MEDICAL/SURGICAL/LAB
          </a>
        </div>
      </div>
    );
  }
}
