import React, { Component } from 'react';
import PharmaCatServices from '../../../services/pharmacat-services';

export default class PharmaDetail extends Component {
  constructor() {
    super();
    this.state = {
      pharmaItem: []
    };
  }

  back = e => {
    e.preventDefault();

    var path = '/products/pharma';

    var a = document.createElement('a');
    a.href = document.referrer;

    var useBrowserBackBtn = false;

    if (a.host === window.location.host) {
      if (a.pathname === path) {
        useBrowserBackBtn = true;
      }
    }

    if (useBrowserBackBtn) {
      console.log('true');
      window.history.go(-1);
    } else {
      window.location.href = path;
    }
  };

  componentDidMount() {
    document.title = 'AvKARE | Pharma Detail';
    var did = this.props.match.params.did;

    PharmaCatServices.detailsGet(did).then(response => {
      console.log(response.data);
      this.setState({ pharmaItem: response.data });
    });
  }

  render() {
    return (
      <div className="page" id="pharmaDetail">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="georgiaFont pt-4">{this.state.pharmaItem.name}</h1>
            <h2 className="d-block grayText">
              {this.state.pharmaItem.strength}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pl-5">
            <button className="back" onClick={e => this.back(e)}>
              Back to Products
            </button>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-12 col-md-8 mb-1">
            <div className="card">
              <h3 className="card-header georgiaFont">Product Details</h3>
              <div className="card-body">
                <table className="table table-striped table-nonce table-headerless table-responsive">
                  <tbody>
                    <tr>
                      <th scope="row">Product Name:</th>
                      <td>{this.state.pharmaItem.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Strength:</th>
                      <td>{this.state.pharmaItem.strength}</td>
                    </tr>
                    <tr>
                      <th scope="row">Count:</th>
                      <td>{this.state.pharmaItem.unitsize}</td>
                    </tr>
                    <tr>
                      <th scope="row">NDC:</th>
                      <td>{this.state.pharmaItem.ndc}</td>
                    </tr>
                    <tr>
                      <th scope="row">Class:</th>
                      <td>{this.state.pharmaItem.drugclass}</td>
                    </tr>
                    <tr>
                      <th scope="row">Brand Name(s):</th>
                      <td>{this.state.pharmaItem.brandref}</td>
                    </tr>
                    <tr>
                      <th scope="row">Imprint Code:</th>
                      <td>{this.state.pharmaItem.imprint}</td>
                    </tr>
                    <tr>
                      <th scope="row">Color(s):</th>
                      <td>{this.state.pharmaItem.color}</td>
                    </tr>
                    <tr>
                      <th scope="row">Shape:</th>
                      <td>{this.state.pharmaItem.shape}</td>
                    </tr>
                    <tr>
                      <th scope="row">Score:</th>
                      <td>{this.state.pharmaItem.score}</td>
                    </tr>
                    <tr>
                      <th scope="row">Case Count:</th>
                      <td>{this.state.pharmaItem.casecount}</td>
                    </tr>
                    <tr>
                      <th scope="row">Inactive Ingredients:</th>
                      <td>{this.state.pharmaItem.inactivedesc}</td>
                    </tr>
                    <tr>
                      <th scope="row">Indications and Usage:</th>
                      <td>{this.state.pharmaItem.indicationsdesc}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-12 col-sm-4 col-md-12 mb-1">
                <div className="card">
                  <h3 className="card-header georgiaFont">Pill Image</h3>
                  <div className="card-body">
                    <img
                      className="drugImage"
                      src={`/assets/ph/${this.props.match.params.did}/1`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-12 mb-1">
                <div className="card">
                  <h3 className="card-header georgiaFont">Label</h3>
                  <div className="card-body">
                    <img
                      className="drugImage"
                      src={`/assets/ph/${this.props.match.params.did}/2`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-12 mb-1">
                <div className="card">
                  <h3 className="card-header georgiaFont">Files</h3>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      {this.state.pharmaItem.files &&
                        this.state.pharmaItem.files.map((file, i) => {
                          return (
                            <li key={i} className="list-group-item">
                              <a
                                href={`../../assets/ph/${file.did}/${
                                  file.element
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.pharmafilekey}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
